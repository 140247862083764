@import 'src/theme/colors.scss'

.root
  width: 100%

.container
  display: grid
  grid-template-columns: 1fr
  gap: 16px
  padding-right: 8px
