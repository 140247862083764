@import 'src/theme/colors.scss'

.root
  width: min(840px, calc(100vw - 144px))
  gap: 24px

.filter
  display: grid
  grid-template-columns: 1fr auto
  gap: 16px
  padding-inline: 5px
  margin-bottom: 24px
