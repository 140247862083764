@import 'src/theme/colors.scss'

.root
  max-width: 450px

.container
  display: flex
  flex-direction: column
  align-items: stretch

.footer
  margin-top: 24px
