@import 'src/theme/colors.scss'

.root
  display: grid
  grid-template-columns: auto
  grid-template-rows: 1fr
  gap: 10px
  text-align: center
  text-wrap: balance
  h2
    font-size: 22px
    line-height: 27px
    margin: 0
  h4
    color: $mainText50Color
    margin: 0
