
@import 'src/theme/colors.scss'

@mixin sizing($size)
  min-width: $size
  width: $size
  min-height: $size
  height: $size
  font-size: calc($size * 1.1)

.root
  position: relative
  border: 1px solid transparent

  .iconChecked
    @include sizing(50%)
    display: none
    color: $primaryContrast100Color
    margin: 0

  input
    @include sizing(100%)
    cursor: pointer
    position: absolute
    opacity: 0
    display: block

    .fakeCheckbox
      border: 1px solid transparent
      outline: none

    &:is(&:hover, &:focus) + .fakeCheckbox
      border-color: $primary100Color

    &:checked + .fakeCheckbox
      border-color: $primary100Color
      background: $primary100Color

      .iconChecked
        display: flex

    &:disabled
      cursor: not-allowed
    &:disabled + .fakeCheckbox
      border-color: $primary10Color
      background: $secondary30_75Color
      color: $text15Color

    &:is(&:hover, &:focus):disabled + .fakeCheckbox
      border-color: $primary10Color

  &.tiny
    @include sizing(18px)

  &.medium
    @include sizing(24px)

  &.big
    @include sizing(30px)

  .fakeCheckbox
    @include sizing(100%)
    display: flex
    align-items: center
    justify-content: center
    border: 1px solid $primaryContrast80Color
    border-radius: 6px
    pointer-events: none
