@import 'src/theme/constants.scss'

.root
  display: flex
  flex-direction: column
  width: 100%
  align-items: flex-start
  padding: 30px 0
  margin: 0 60px
  overflow: auto
  max-height: 100vh
  min-height: 450px

.header
  width: 100%
  margin-bottom: 45px

.body
  display: grid
  grid-template-columns: 460px 1fr
  overflow: auto
  width: 100%
  gap: 24px

.left,
.right
  display: flex
  flex-direction: column
  overflow: auto

.left
  flex-basis: 460px

.questionListContainer
  overflow-y: scroll
