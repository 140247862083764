@import "@root/theme/constants.scss"

.root
  display: flex
  align-items: stretch
  margin-bottom: 12px
  border-block: 1px solid $secondary100Color
  height: 32px

.address
  width: 100px
  padding-inline: 8px
  padding-block: 3px
  border-right: 1px solid $secondary100Color
  background: $primary70_20Color
  letter-spacing: 1px

.status
  display: flex
  align-items: center
  padding-left: 8px

.input
  display: flex
  align-items: center
  overflow-y: hidden
  flex: 1

.textarea
  border: none
  resize: none
  width: 100%
  outline: none
  caret-color: $text100Color !important
  padding: 0
  padding-left: 10px

@media (max-width: $tablet-max-height)
  .root
    flex-direction: column
    min-height: 50px
    height: fit-content
    margin-bottom: 0

  .input
    flex: 0 1 50px
    padding-block: 4px

  .address
    text-align: center !important
    width: 100%
    border: none
    border-bottom: 1px solid $secondary100Color
