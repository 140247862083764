@import 'src/theme/colors.scss'

.root
  width: 100%

.container
  overflow: hidden
  flex: 1 1 auto

.cell
  display: flex
  padding: 4px

.cell>*
  flex: 1 1 auto
  height: 100%
