@import 'src/theme/colors.scss'
@import 'src/theme/constants.scss'

.root
  display: flex
  flex-direction: column
  width: 100%

.body
  display: flex
  flex-direction: row
  gap: 24px
  flex: 1
  width: 100%
  max-height: 350px
  min-height: 350px
  align-items: stretch
  margin: 16px 0px
  padding: 16px
  border-radius: 16px
  background: $primaryCardBGColor

.sidebarContainer
  flex: 1 0 40%
  display: flex
  justify-content: center
  flex-direction: column
  align-items: center

.filtersContainer
  flex: 1 1 100%
  overflow: auto

.header
  display: flex
  justify-content: space-between
  align-items: center

@media (max-width: $tablet-max-height)
  .body
    gap: 12px

  .sidebarContainer
    flex: 1 0 50%

@media (max-width: $mobile-min-width)
  .body
    flex-direction: column-reverse
    padding: 0
    border-radius: 0
    background: transparent
    max-height: none
  // .container
  //   padding: 25px 20px 0px

  .filtersContainer
    width: 100%
    overflow: auto
