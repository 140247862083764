
@import 'src/theme/colors.scss'

.root
  --border-width: 2px
  --height: 20px
  --width: 20px

  display: flex
  justify-content: center
  align-items: center
  height: var(--height, 20px)
  width: var(--width, 20px)
  min-height: var(--height, 20px)
  min-width: var(--width, 20px)
  border: var(--border-width, 1px) solid $primary100Color
  padding: calc(var(--border-width, 1px) * 1.5)
  border-radius: 50%

  .checkmark
    display: none
    width: 100%
    height: 100%
    background: $primary100Color
    border-radius: 50%
    margin: auto

  input
    position: absolute
    opacity: 0
    cursor: pointer
    height: 0
    width: 0

  input:checked + .checkmark
    display: block

.size-big
  --height: 30px
  --width: 30px

.size-medium
  --height: 20px
  --width: 20px

.size-tiny
  --height: 10px
  --width: 10px
