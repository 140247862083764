@import 'src/theme/colors.scss'

.root
  display: grid
  grid-template-columns: auto 1fr
  padding: 16px 24px
  position: relative
  user-select: none
  cursor: pointer
  background: transparent
  border: none
  outline: none
  gap: 16px

.checkMark
  font-size: 16px
  color: transparent

.header
  display: grid
  grid-template-columns: 1fr auto
  align-items: flex-start
  margin-bottom: 8px
  gap: 12px

.description
  margin-bottom: 16px

.footer
  display: flex
  gap: 24px

.root:hover, .root:focus-visible
  background-color: $itemPrimaryHoverBgColor

.root:active
  background: $itemPrimaryActiveBgColor

.root.active
  background: $itemPrimaryActiveBgColor
  .checkMark
    color: $primary100Color
