@import 'src/theme/colors.scss'
@import 'src/theme/mixins.sass'
@import 'src/theme/flex.scss'
@import 'src/theme/modifiers.scss'

.root
  max-height: calc(100vh - 220px)

.row
  grid-template-columns: 200px 1fr 220px
  color: $mainText100Color

.boxMargin
  margin: auto
  margin-top: 10px

.fullHeight
  height: 100%

.mainWordColumn
  vertical-align: middle !important

.table
  flex: 1 1 100%
  overflow: auto

.fadeDown
  opacity: .4 !important

.embeddedCell
  padding-block: 7px !important

.text
  margin: auto

.addButton
  margin-top: 24px

.switch
  margin-bottom: 24px

.row:not(:hover) .showOnHover
  visibility: hidden
