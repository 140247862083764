@import 'src/theme/colors.scss'

.body
  margin-top: 42px

.icon
  margin-right: 8px

.company
  display: flex
  gap: 8px
  align-items: center
  i
    font-size: 22px
    background: $secondary50Color
    height: 42px
    width: 42px
    border-radius: 8px
  div
    display: flex
    flex-direction: column
    gap: 4px

.notFound
  display: flex
  flex-direction: column
  justify-content: center
  h2
    margin: 20px 0px

tbody
  tr:nth-of-type(3n + 1)
    .icon
      color: $green100Color
  tr:nth-of-type(3n + 2)
    .icon
      color: $gold100Color
  tr:nth-of-type(3n + 3)
    .icon
      color: $primary100Color
