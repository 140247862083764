@import 'src/theme/colors.scss'
@import 'src/theme/constants.scss'

.productsSection
  display: flex
  flex-direction: column
  gap: 16px
  flex: 1 1 auto
  padding: 8px
  border-radius: 8px
  background: $primaryContrast10OpacityColor
  width: 100%

.container
  display: flex
  flex-direction: column
  height: 300px
.scrollable
  overflow: scroll

.filterTypes
  padding-left: 12px
  display: flex
  flex-direction: column
  gap: 8px
  width: 100%

.categories
  padding-left: 8px
  display: flex
  flex-direction: column
  gap: 8px
  width: 100%
  overflow: auto
  &::-webkit-scrollbar
    width: 4px
  &::-webkit-scrollbar-thumb
    background-color: darkgrey
    border-radius: 2px

.loaderList
  flex: 1 1 auto

.category
  width: 100%
  display: flex
  justify-content: space-between
  font-size: 16px

.count
  color: $primary105Color

.name
  display: flex

.categoryHeader
  padding: 8px 4px
.categoryHeader:nth-of-type(2)
  margin-top: 8px

.filtersHeader,
.categoryHeader
  border-bottom: 1px solid $mainText50Color
  color: $mainText50Color
  margin-bottom: 8px

.filtersHeader
  display: flex
  padding: 4px 0px
  align-items: center
  span
    margin-left: 0px
  button
    font-size: 14px
    color: $mainText50Color

.checkboxFilterName
  white-space: nowrap
  font-size: 12px
  background: transparent
  padding: 4px
  border-radius: 4px
  padding-left: 0px

.checkboxWrapper
  display: flex
  justify-content: space-between
  align-items: center
  button
    font-size: 16px

.loaderWrapper
  display: flex
  justify-content: center
  padding: 8px 0

.companyHeading
  display: flex
  align-items: center
  width: max-content

.breadCrumps
  padding-bottom: 8px

.filtersLoader
  display: flex
  width: 100%
  justify-content: center

@media (max-width: $mobile-min-width)
  .productsSection
    max-width: 100%
    width: 100%
