@import 'src/theme/constants.scss'

.root
  display: flex
  height: 25px
  min-height: 25px
  min-width: 25px
  justify-content: center
  align-items: center
  padding: 5px
  border-radius: 6px
  font-size: 14px
  border: 1px solid
  border-color: transparent
  width: fit-content
  cursor: default
  max-width: 100%

.title
  flex: 0 1 100%
  white-space: nowrap
  text-overflow: ellipsis
  overflow: hidden

.subTitle
  padding: 2px 4px
  background: red
  border-radius: $medium-border-radius
  font-weight: 500
  font-size: 8px
  line-height: 10px
  letter-spacing: 0.02em
  text-transform: uppercase
  background: #0000004e

.variant-rounded
  padding: 5px
  border-radius: 15px
  font-size: 12px

.variant-rounded .title
  margin: 0 8px

.uppercase
  text-transform: uppercase

.clickable
  cursor: pointer

.closeButton
  height: 19px
  min-height: 19px
  min-width: 19px
  font-size: 10px
  padding: 0
  margin: -2px
  margin-left: 8px

.root *:global(.IUAvatar-root)
  font-size: 6px
