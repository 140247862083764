@font-face {
  font-family: "icomoon";
  src: url("./icomoon.eot?3b370m");
  src: url("./icomoon.eot?3b370m#iefix") format("embedded-opentype"),
    url("./icomoon.ttf?3b370m") format("truetype"),
    url("./icomoon.woff?3b370m") format("woff"),
    url("./icomoon.svg?3b370m#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
