@import 'src/theme/colors.scss'

.root
	display: flex
	justify-content: space-between
	width: 100%
	align-items: center
	gap: 8px

	.remove
		color: $mainText100Color

	.body
		display: flex
		align-items: center
		gap: 8px

.root.clickable
	cursor: pointer
