@import 'src/theme/constants.scss'
@import '@root/theme/mixins.sass'

.root
  display: flex
  flex-direction: column
  align-items: stretch
  padding: 16px
  border-radius: $medium-border-radius
  background: $primaryCardBGColor
  border: 1px solid transparent
  min-width: 240px
  cursor: pointer
  &.active
    border: 1px solid $primary100Color

.header
  display: flex
  text-align: center
  gap: 8px
  margin-bottom: 8px

.title
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis

.body
  display: flex
  flex-direction: column
  gap: 12px

  &:not(:empty)
    margin-top: 8px
    padding-top: 12px
    border-top: 1px solid $dividerLineLightColor

.row
  display: flex
  gap: 8px

  .content
    display: flex
    flex-direction: column
    flex: 0 1 auto
    gap: 2px
