@import 'src/theme/colors.scss'
@import 'src/theme/constants.scss'

.root
  padding: 16px
  background: $primaryContrast10OpacityColor
  border-radius: 16px
  >span
    display: flex
    align-items: center
    gap: 8px
    margin-bottom: 16px
    font-size: 14px
    font-weight: 600
    i
      font-size: 16px

@media(max-width: $tablet-min-height)
  .root
    min-width: 300px
