@import 'src/theme/constants.scss'

.root
  position: fixed
  height: 100vh
  width: 260px
  max-width: 260px
  top: 0
  transition: left .3s
  left: -100%
  background: $primaryToolbarBgColor
  z-index: 1

.container
  display: flex
  flex-direction: column
  height: 100%
  width: 100%

.opened
  left: 0

.logoOpen
  padding: 24px 14px
  display: flex
  justify-content: space-between
  align-items: center
  a
    display: flex
    align-items: center
    gap: 8px
    color: $text100Color
    text-decoration: none
    white-space: nowrap
  a:visited
    color: $text100Color
    text-decoration: none
  button
    font-size: 16px

.menu
  flex: 1
  overflow-y: auto
  padding: 20px 10px 10px
  display: flex
  flex-direction: column
  border-bottom: 1px solid hsl(0deg 5.95% 69.33% / 24%)
  &::-webkit-scrollbar
    width: 2px
  &::-webkit-scrollbar-thumb
    border-radius: 2px

.expandableMenu
  display: flex
  flex-direction: column
  gap: 10px

.footer
  padding: 16px
  display: flex
  flex-direction: column
  gap: 16px
  width: 100%
  a, a:visited
    text-decoration: none
    color: $text70Color

.support
  display: flex
  align-items: center
  margin-left: 4px
  gap: 10px
  color: $mainText100Color
  :visited
    color: $mainText100Color

.helpButton
  height: 42px
  width: 42px
  min-width: 42px
  background: $green100Color
  border-radius: 50%
  color: $primaryContrast100Color

.body
  flex: 1 1 auto
  font-size: 18px
  padding: 20px

.logout
  font-size: 20px
  color: $red100Color
  width: min-content