@import 'src/theme/constants.scss'

.root
  display: flex
  flex: 1 1 auto

.alert
  display: grid
  grid-template-columns: auto 1fr auto
  align-items: center
  position: relative
  gap: 20px
  width: 420px
  margin: 0 60px
  padding: 20px
  border-radius: 6px
  background: $text10Color
  backdrop-filter: blur(45px)

.closeButton
  align-self: center
  display: flex
  justify-content: center
  align-items: center
  width: 28px
  height: 28px
  border-radius: 50%
  background: $text10Color

.doneIcon
  width: 60px
  height: 60px

.message
  font-weight: 500
  font-size: 16px
  line-height: 26px
  overflow: hidden
  text-overflow: ellipsis
  display: -webkit-box
  -webkit-line-clamp: 6
  -webkit-box-orient: vertical

@media (max-width: $tablet-min-width)
  .alert
    margin: 0 20px

@media (max-width: $tablet-min-height)
  .alert
    padding: 14px
    padding-right: 20px
    width: calc(100vw - 40px)
