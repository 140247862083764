@import 'src/theme/colors.scss'

.root
  min-height: 90%
  max-width: 100%

.header
  display: flex
  align-items: center
  gap: 16px

.answer
  padding: 24px
  border-radius: 8px
  height: 100%
  max-width: 980px
  overflow-x: hidden
  overflow-y: auto
  background: $primaryContrast10OpacityColor
  line-height: 28px

.avatar
  font-size: 12px
