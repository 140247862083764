@import 'src/theme/constants.scss'

.root
  display: flex
  flex-direction: column
  gap: 16px

.solutionCardContainer
  display: grid
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr))
  gap: 24px

@media (max-width: $tablet-min-width)
  .solutionCardContainer
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr))
    gap: 16px

@media (max-width: $tablet-min-height)
  .solutionCardContainer
    display: flex
    flex-direction: column

@media (min-width: $tablet-min-width)
  .solutionCardContainer
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr))
