@import 'src/theme/constants.scss'

.root
  display: flex
  flex-direction: column
  width: 100%
  align-items: flex-start
  padding: 30px 0
  margin: 0 60px
  gap: 30px

.header
  display: flex
  justify-content: space-between
  align-items: center
  width: 100%
  h2
    font-size: 28px
    margin: 0

.headerButton
  max-height: 42px
  text-transform: uppercase

.body
  display: flex
  flex-direction: column
  flex: 1 1
  width: 100%
  gap: 10px

.grid
  display: grid
  gap: 30px
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr))

@media (max-width: $tablet-min-width)
  .root
    padding: 0

  .grid
    gap: 20px

@media (max-width: $mobile-min-width)
  .header
    flex-direction: column
    align-items: flex-start
    gap: 20px

  .headerButton
    width: 100%

  .grid
    grid-template-columns: repeat(auto-fill, 100%)
