@import "@root/theme/constants.scss"
@import "@root/theme/mixins.sass"

.root
  display: flex
  border-top: 1px solid $secondary100Color
  padding-block: 8px
  row-gap: 4px
  overflow: auto
  @include thinScroll('&')

.section
  display: flex
  flex-direction: row
  align-items: center
  padding: 0 4px
  gap: 2px

.root>*.section:not(:last-child)
  border-right: 1px solid $secondary100Color

.root>*.section:first-child
  border-left: none

.root>*.section:last-child
  border-right: none

@media (max-width: $tablet-max-height)
  .root
    padding-block: 4px
