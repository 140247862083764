@import 'src/theme/colors.scss'

.root
  display: grid
  grid-template-columns: 1fr 1fr
  word-wrap: wrap
  width: 100%
  gap: 16px

  & > *:first-child
    justify-self: flex-start
    text-align: start
    color: $mainText50Color

  & > *:last-child
    justify-self: flex-end
    text-align: end
