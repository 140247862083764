@import 'src/theme/constants.scss'

.root
  display: grid
  grid-template-rows: auto auto 1fr
  width: 100%
  max-width: 1700px
  align-items: flex-start
  margin-inline: auto

.header
  margin-bottom: 20px

.body
  display: grid
  width: 100%
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr))
  gap: 30px
