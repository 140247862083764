@import 'src/theme/constants.scss'

.root
  display: flex
  align-items: stretch
  position: relative
  min-width: 152px
  height: 156px
  border-radius: 6px
  background: $primaryCardBGColor
  gap: 10px
  user-select: none
  h4
    text-align: center
    color: $mainText100Color
    line-height: 22px
    margin: 0

.clickable
  cursor: pointer

.container
  display: flex
  flex-direction: column
  align-items: center
  flex: 1 1 100%
  padding: 15px
  max-width: 100%

.titleContainer
  width: 100%

.title
  text-align: start
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap
  width: 100%

.nameTooltip
  font-size: 12px
  padding: 7px 10px
  width: max-content
  max-width: 250px
  white-space: pre-wrap
  word-break: break-all

.icon
  display: flex
  align-items: center
  justify-content: center
  fill: red
  width: 45px
  height: 64px
  img
    max-height: 45px

.control
  position: absolute
  top: 9px
  right: 11px

.footer
  display: flex
  flex: 1 1
  width: 100%
  align-items: center
  justify-content: center

.lastUpdate
  font-style: normal
  font-weight: normal
  font-size: 10px
  line-height: 20px
  color: $mainText50Color
