@import 'src/theme/colors.scss'

.root
  font-size: 12px

.header
  display: flex
  width: 100%
  margin-bottom: 5px
  font-weight: 500
  font-size: 1em
  line-height: 15px
  margin-bottom: 5px
  justify-content: space-between

.label
  display: flex
  gap: 8px
  justify-self: flex-start
  align-items: center
  margin-inline: 12px

.error
  justify-self: flex-end
  color: $red100Color
