@import '@root/theme/constants.scss'
@import "@root/theme/mixins.sass"

.root
  margin: 7px 0
  overflow: auto
  margin: 0
  @include thinScroll('&')

.list
  display: grid
  gap: inherit
  min-height: 100%
  grid-template-columns: 1fr
  padding: 0
  margin: 0
