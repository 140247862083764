@import 'src/theme/constants.scss'
@import '@root/theme/mixins.sass'

.root
  display: flex
  justify-content: center
  position: relative
  grid-area: view
  overflow: hidden
  user-select: none
  height: 100%
  width: 100%

.imageScroll
  overflow: scroll

.content
  display: flex
  flex-direction: column
  width: 100%
  height: 100%
  min-height: 300px
  position: relative
  overflow: hidden
  img
    display: block
    margin: auto

.imageContainer
  display: flex
  overflow: auto
  height: 100%

.control
  position: absolute
  top: 10px
  left: 10px
  display: flex
  flex-direction: column
  gap: 10px

.controlButton
  cursor: pointer
  font-size: 20px
  padding: 10px
  background: $secondary30_75Color
  border-radius: 4px
  opacity: .7

.controlButton:hover
  box-shadow: 0 0 2px 1px $primary80Color
  opacity: 1

.pdfContainer
  height: 100%
  display: flex
  width: 100%
  justify-content: center
  iframe
    height: 100%
    width: 100%
    border: none

.extractedTextHeading
  margin: 10px 0px !important

.textContent
  overflow: auto
  transition: width 0.3s ease-in-out
  width: 100%
  flex: 1
