@import 'src/theme/constants.scss'

.root
  display: flex
  align-items: flex-start
  flex: 1 1 auto
  border-radius: $medium-border-radius
  background: $primaryMainBGColor
  width: 100%
  padding: 8px 10px
  gap: 12px
  position: relative
  z-index: 0

  &::after
    content: ''
    position: absolute
    inset: 0
    z-index: -1
    border-radius: inherit
    border: 1px solid transparent

.icon
  font-size: 24px
  margin-block: 2px

.content
  display: flex
  flex-direction: column

.message
  overflow: hidden
  margin-block: 4px

.root.colorWarning
  color: $gold100Color
  &::after
    background: $gold10Color
    border-color: $gold100Color

.root.colorInfo
  color: $blue100Color
  &::after
    background: $blue10Color
    border-color: $blue100Color

.root.colorSuccess
  color: $green100Color
  &::after
    background: $green10Color
    border-color: $green100Color

.root.colorError
  color: $red100Color
  &::after
    background: $red10Color
    border-color: $red100Color
