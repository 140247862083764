@import 'src/theme/colors.scss'

.root
  color: $mainText100Color
  width: 100%
  border-spacing: 0
  padding: .215px
  border-collapse: collapse
  border-radius: 10px
  margin-block: 20px

  td, th
    padding: .715em
    font-weight: normal
    font-size: 1em
    line-height: 17px
    white-space: normal

  tbody
    td
      border-block: 1px solid $dividerLineLightColor
      padding-block: 8px

    tr:first-child td
      text-align: start
      border-bottom: 2px solid $dividerLineLightColor
      border-top: none
      text-transform: uppercase
      font-weight: 500
      font-size: 0.85em
      line-height: 2em
      color: $tableHeaderColor
