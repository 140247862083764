@import "@root/theme/constants.scss"

.root
  display: flex
  align-items: center
  gap: 16px
  width: 100%
  max-width: 100%
  box-sizing: border-box

.left,
.right,
.main
  display: flex
  flex-wrap: wrap
  flex: 1 1 auto
  align-items: center
  gap: 10px

.main
  flex: 1 1 100%

.left
  justify-content: flex-start

.right
  justify-content: flex-end

.root.align-center
  align-items: center
.root.align-start
  align-items: flex-start
.root.align-end
  align-items: flex-end
