@import "src/theme/colors.scss"
@import 'src/theme/constants.scss'

.root
  display: flex
  background: $inputMainBgColor
  align-items: center
  border-radius: 25px
  height: 50px
  border: 1px solid transparent
  overflow: hidden
  box-sizing: border-box
  cursor: pointer
  text-align: start
  min-width: 250px
  font-size: 16px 
  color: $mainText100Color
  outline: none

.label
  line-height: 1rem
  margin: auto
  padding-inline: 8px
  user-select: none
  border: none
  flex: 1 1 100%

.name
  overflow: hidden
  display: -webkit-box
  -webkit-box-orient: vertical
  -webkit-line-clamp: 1
  text-overflow: ellipsis

@media (max-width: $mobile-min-width)
  .root
    min-width: 150px