@import 'src/theme/colors.scss'

.root
    padding: 10px 14px
    border-radius: 6px
    color: $mainText100Color
    height: fit-content
    border: 2px solid transparent
    font-size: 16px
    margin: 3px
    display: flex
    justify-content: space-between
    align-items: center
    cursor: default

    &:hover
        background-color: $itemPrimaryBgColor

    .remove
        color: $mainText100Color

    .body
        display: flex
        align-items: center
        gap: 8px

.root.clickable
    cursor: pointer
