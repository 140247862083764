@import 'src/theme/colors.scss'

.root
  display: flex
  justify-content: center
  gap: 15px
  padding-top: 30px

.form
  width: 100%
