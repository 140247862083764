@import "src/theme/constants.scss"

.root
  display: flex
  align-items: center
  justify-content: space-between
  gap: 5px
  width: fit-content
  height: fit-content
  user-select: none

.withPrefix
  padding: 5px
  padding-left: 12px
  border-radius: 50px
  background: red
  background: $inputMainBgColor
  h4
    margin: 0

.container
  display: flex
  flex-direction: row-reverse
  padding-right: 16px
  font-size: 12px
  &.size
    &-small
      height: 24px
    &-medium
      height: 32px

.avatar
  font-size: 11px
  margin-right: -16px
  border: 2px solid $secondary80Color

.defAvatar
  font-style: normal
  font-weight: normal
  background: $primary10Color !important

.clickable
  cursor: pointer
