@import 'src/theme/colors.scss'
@import 'src/theme/constants.scss'

.root
  width: min(700px, calc(100vw - 80px))

.checkbox
  margin: 0px

.container
  flex: 1
  overflow: hidden

.alertIcon
  align-self: flex-start
  line-height: 140%

.card
  height: 100%
  user-select: none

.fileName
  display: -webkit-box
  overflow: hidden
  text-overflow: ellipsis
  -webkit-line-clamp: 2
  -webkit-box-orient: vertical
  flex: 1 1 auto

.checkmark
  font-size: 1.8em

.dataTypeBadge
  max-width: 300px
