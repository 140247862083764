@import "src/theme/colors.scss";

.item {
  display: flex;
  gap: 12px;
  width: 100%;
  max-width: min(100vw, 500px);
}

.text {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.arrow,
.icon {
  flex: 0 1 18px;
  font-size: 16px;
  padding: 1px;
}

.divider {
  border: none;
  border-top: 1px solid $dividerLineLightColor;
  margin: 4px 8px;
}