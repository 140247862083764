@import 'src/theme/constants.scss'

.root
  display: flex
  flex-direction: column
  justify-content: center
  width: 400px

.body
  display: flex
  flex-direction: column
  flex: 1 1 auto
  overflow-y: auto
  gap: 20px

.listTitle
  padding-block: 5px

.userCard
  margin-bottom: 16px

.listContainer
  height: 300px

.footer
  margin-top: 24px
