@import 'src/theme/constants.scss'

.root
  display: flex
  flex-direction: column
  gap: 3px

.textBox
  border-radius: $medium-border-radius
  padding: 6px 10px
  background: $text05Color
  min-height: 50px
