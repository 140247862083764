@import "@root/theme/colors.scss"
@import "@root/theme/mixins.sass"

.marker_func-name
  color: #d880f2

.marker_ref
  color: #e1a53c

.marker_argument
  color: #5c83b5

.marker_symbol
  color: $mainText70Color

.marker_const
  color: #34a0ad

.marker_unknown
  color: $mainText50Color
