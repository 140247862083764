@import 'src/theme/constants.scss'
@import '@root/theme/mixins.sass'

$anumdationDuration: .2s

@keyframes blur-down
  0%
    opacity: 1
    visibility: visible
  100%
    opacity: 0
    visibility: hidden

@keyframes blur-up
  0%
    opacity: 0
    visibility: visible
  100%
    opacity: 1
    visibility: hidden

.root
  overflow: hidden
  min-width: 100%
  height: 100%
  max-height: 100%
  position: fixed
  top: 0
  bottom: 0
  left: 0
  right: 0
  opacity: 0
  visibility: hidden
  background: $secondary30_75Color
  backdrop-filter: blur(15px)
  z-index: 99
  margin: 0 !important
  @include thinScroll

.hideBackgroundBlur
  backdrop-filter: none
  background: transparent

.root.hidden
  opacity: 0
  animation: blur-down $anumdationDuration

.root.blured
  opacity: 1
  visibility: visible
  animation: blur-up $anumdationDuration
