@import 'src/theme/colors.scss'

.root
  display: flex
  flex-direction: column
  width: 100%
  max-height: 100%
  flex: 1 1 100%

  & a, & a:visited
    color: $mainText100Color
    text-decoration: none

  & mark
    background: transparent
    color: $mainText100Color

.text
  position: relative
  flex: 1 1 auto

.image
  display: flex
  justify-content: center
  overflow: hidden
  flex: 1 1 auto
  width: 100%
  min-height: 155px
  height: 100%
  border-radius: 6px
  background: $secondary30_75Color

  .img
    width: 100%

.imagePlaceholder
  position: relative
  margin: auto
  font-size: 100px

.table
  max-width: 100%
  overflow: hidden

.title
  display: inline
  user-select: none
  text-overflow: ellipsis
  overflow: hidden
  white-space: nowrap
  overflow-wrap: break-word
  cursor: default
  margin: 0
  margin-block: 5px
  & > *
    display: inline-block

.title:is(h4)
  color: $mainText100Color

.title:is(h5)
  color: $mainText50Color

.title.clickable
  cursor: pointer
  text-decoration: underline

.title.clickable:hover
  text-decoration: underline

.header
  display: flex
  justify-content: space-between
  margin-bottom: 7px
