@import "~normalize.css/normalize.css";
@import "./fonts.scss";
@import "./colors.scss";
@import "./constants.scss";

* {
  box-sizing: border-box;
}

:root {
  font-size: 14px;
  overflow-y: scroll;
  overflow-x: auto;
  background-color: $contrastText100Color;
}

body {
  color: $mainText100Color;
  font-family: $main-font-family;
  overflow: overlay;
}

h1 {
  font-size: 42px;
  font-weight: bold;
  line-height: 52px;
  margin: 0;
  margin-bottom: 10px;
}

h2 {
  font-size: 26px;
  font-weight: 500;
  line-height: 31px;
  margin: 0;
  margin-bottom: 10px;
}

h3 {
  font-size: 18px;
  font-weight: 500;
  line-height: 17px;
  margin: 0;
  margin-bottom: 10px;
}

h4 {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  margin: 0;
  margin-bottom: 10px;
}

h5 {
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  margin: 0;
}

h6 {
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  margin: 0;
  margin-bottom: 7px;
}

p {
  font-size: 1rem;
  font-weight: normal;
  line-height: 22px;
  margin: 0;
}

a {
  color: $primary100Color;
}

a:visited {
  color: $primary80Color;
}

a:focus,
a:active {
  color: $mainText100Color;
}

*::-webkit-scrollbar {
  width: 0.8em;
  height: 0.8em;
}

*::-webkit-scrollbar-track, 
*::-webkit-scrollbar-corner {
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  background: $mainScrollColor;
  border-radius: 10px;
}

::selection {
  color: $contrastText100Color;
  background: $primary100Color;
}

ul, ol {
  margin-block: 4px;
  padding-left: 2em;
}
