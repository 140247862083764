@import 'src/theme/colors.scss'
@import 'src/theme/constants.scss'
@import '@root/theme/mixins.sass'

.root
  display: flex
  flex-direction: column
  align-items: center
  padding: 24px
  width: 800px
  max-width: 100%

.content
  display: grid
  grid-template-columns: repeat(3, 1fr)
  gap: 16px
  margin-bottom: 24px

.footer
  display: flex
  justify-content: center
  gap: 16px
  width: 100%
  button
    min-width: 120px

.container
  width: 100%

.dataSourceCard
  display: flex
  flex-direction: column
  align-items: center
  background: $primaryCardBGColor
  padding: 16px
  border-radius: 8px
  cursor: pointer
  aspect-ratio: 1 / 1
  justify-content: center
  border: 1px solid transparent
  transition: border-color .3s, background .2s ease-out
  img, i
    transition: transform .3s ease-out
  &:hover
    border-color: $itemPrimaryActiveColor
    box-shadow: 0px 0px 2px 3px $primary70_20Color
    background: $itemPrimaryActiveBgColor
    outline: none
    img, i
      filter: drop-shadow(0 0 8px $primary50Color)
      transform: scale(1.07) translateY(-3px)
  p
    font-size: 12px
    text-align: center

.active
  background: $primary20Color
  h5, p
    color: $text100Color

.grid
  display: grid
  width: 100%
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr))
  gap: 16px
  padding: 10px
  max-height: 60vh
  overflow: auto
  padding-top: 24px
  margin-bottom: 16px
  @include thinScroll('&', 2px)

.divider
  width: 100%
  padding: 0 10px
  div
    background-color: $mainText50Color
    height: 1px
    width: 100%

@media (max-width: $tablet-min-height)
  .container
    padding: 0px

  .grid
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr))
  .footer
    padding-top: 6px
    button
      flex: 1
