@import 'src/theme/colors.scss'

.root
  display: flex
  flex-direction: column
  gap: 24px
  width: 100%
  height: min(500px, 100vh - 325px)

.form
  display: flex
  flex-direction: column
  flex: 1 1 100%
  gap: 20px
  width: 100%
  margin: 0 auto
  user-select: none
  overflow: auto

.after
  display: flex
  gap: 10px
  margin-right: 10px

.avatarIcon
  width: 32px
  height: 32px
