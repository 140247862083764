@import 'src/theme/colors.scss'
@import '@root/theme/mixins.sass'

.root
  user-select: none

  .hoverVisible
    margin-inline: 8px
    visibility: hidden
    color: var(--active-border-color)

.root:hover
  .hoverVisible
    visibility: visible
