@import '@root/theme/constants.scss'
@import '@root/theme/mixins.sass'

.root
  display: flex
  flex-direction: column
  padding: 0
  width: 100%
  @include thinScroll

.body
  display: flex
  flex: 1 1 100%
  align-items: center
  margin: 0 auto

.clickable
  cursor: pointer

.container
  display: flex
  flex-direction: column
  margin: 0 auto
  width: 100%
  gap: 45px
  width: 570px
  max-width: 100%
  border-radius: $big-border-radius
  background-color: $primaryCardBGColor
  gap: 20px
  padding: 40px
  cursor: default

@media (max-width: $tablet-min-width)
  .container
    padding: 30px

@media (max-width: $tablet-min-height)
  .root
    padding: 60px 0 0

  .body
    align-items: stretch

  .container
    flex: 1 1 100%
    padding: 20px
    border-radius: 30px 30px 0 0

@media (max-width: 570px)
  .container
    min-width: 100%
