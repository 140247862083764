@import 'src/theme/colors.scss'
@import '@root/theme/mixins.sass'

.root
  width: 600px
  max-width: 600px

.bodyContainer
  display: flex
  flex-direction: column
  gap: 24px

.listContainer
  display: flex
  flex-direction: column
  gap: 16px

.searchWrapper
  display: flex
  gap: 16px
  align-items: flex-end
  width: 100%

.searchInput
  min-width: auto
  flex: 1 0 auto

.notation
  display: flex
  align-items: flex-start
  gap: 8px
  padding: 12px
  background: $blue10Color
  color: $blue100Color
  border-radius: 8px
  margin-bottom: 20px
