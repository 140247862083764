@import "@root/theme/constants.scss"

@keyframes appearance
  0%
    opacity: 0
    transform: translateY(5px)

  100%
    opacity: 1
    transform: translateY(0px)

.root
  min-width: 250px
  margin-block: 2px
  padding: 4px 8px

  animation: appearance 200ms ease-out

.header
  display: flex
  justify-content: space-between
  align-items: center
  min-height: 24px

.title
  display: flex
  align-items: center
  gap: 10px

.inline
  display: inline

.message
  display: flex
  overflow: hidden
  text-overflow: ellipsis
  min-width: 100px
  flex: 1

.control
  display: flex
  justify-content: flex-end
  gap: 8px

.section
  border-top: 1px solid grey
  padding-block: 8px

.list
  display: flex
  flex-direction: column
  gap: 8px

@media (max-width: $mobile-min-width)
  .root
    box-shadow: none
    border-radius: 0 !important
    margin-block: 1px
    padding: 10px 16px
