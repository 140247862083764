@import 'src/theme/constants.scss'

.suggestBannerBox
    padding: 1rem
    display: flex
    flex-direction: column
    gap: 1rem
    position: relative
    border-radius: 16px

.heading
    margin-bottom: 10px

.caption
    font-weight: 400
    margin-bottom: 15px !important

.subCaption
    display: flex
    align-items: center

.suggestedBannerCloseBtn
    position: absolute
    top: 10px
    right: 10px

.suggestionCount
    margin-right: 5px
