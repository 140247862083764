@import 'src/theme/colors.scss'

.root
  text-overflow: ellipsis
  overflow: hidden
  word-break: break-all
  &.clamp
    display: -webkit-box
    -webkit-box-orient: vertical
  .link
    display: inline-block

.readMore
  align-self: flex-start
  margin-top: 7px
