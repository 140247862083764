@import 'src/theme/constants.scss'

.root
  text-align: center
  color: $mainText100Color
  border: 1px solid $itemPrimaryActiveColor
  border-radius: 16px
  min-width: 100%
  font-size: 12px
  line-height: 150%
  position: relative
  z-index: 0
  margin-top: 16px
  overflow: hidden
  span
    color: $mainText50Color

  &::before
    content: ''
    position: absolute
    top: 0
    bottom: 0
    left: 0
    width: var(--progress-value)
    background: $itemPrimaryActiveBgColor
    z-index: -1
    border-radius: inherit
