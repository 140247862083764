@import 'src/theme/colors.scss'

a.citation,
a.citation:visited
  // color: $primaryContrast100Color !important
  // background: $primary100Color
  // border-radius: 15px
  display: inline-block
  // text-align: center
  // font-size: 0.9em
  // height: 16px
  // width: 16px
  margin: 2px
  // vertical-align: middle
  // line-height: 16px
  margin-bottom: 4px
  text-decoration: none
  &:before
    content: attr(data-content)
