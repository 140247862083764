@import "src/theme/colors.scss"
@import "src/theme/constants.scss"

.root
  display: flex
  flex-direction: column
  position: relative

.input::-webkit-calendar-picker-indicator
  display: none

.input[type="date"]::-webkit-input-placeholder
  visibility: hidden !important

.input
  color: $mainText100Color
  font-family: $main-font-family
  font-size: 14px
  line-height: 17px
  flex: 1 1
  padding: 13px 50px 12px 50px
  border-radius: 25px
  border: 1px solid transparent
  background-color: $inputMainBgColor
  outline: none

  &::placeholder
    color: $mainText50Color

  &:disabled
    color: $mainText50Color

  &.invalid
    border-color: $red100Color
    color: $red100Color

.tooltip
  display: flex
  justify-content: center
  align-items: center
  position: absolute
  right: 0px
  top: 0px
  width: 50px
  height: 100%
  justify-content: flex-end

.arrow
  font-size: 12px
  pointer-events: all
  overflow: hidden
  color: $primary100Color
  i
    color: $mainText100Color
    padding: 20px 15px

.tooltipContainer
  display: flex

.body
  display: flex

.calendarContainer
  flex: 1 0 300px
  margin: 12px
  margin-top: 0

.calendarIcon
  position: absolute
  font-size: 16px
  top: 0
  left: 0
  padding: 14px 19px

.calendar
  margin: 0 auto

.presetContainer
  display: flex
  flex-direction: column
  gap: 8px
  margin-block: 12px
  padding: 8px 16px
  border-left: 1px solid $dividerLineLightColor
  min-width: 100px
