@import 'src/theme/colors.scss'

.root
  width: min(1100px, calc(100vw - 144px))
  gap: 24px

.body
  flex: 1 1 auto
  display: grid
  grid-template-columns: 340px 1fr
  overflow: auto
  gap: 10px
