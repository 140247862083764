@import 'src/theme/colors.scss'

.root
  max-width: 550px
  width: 500px
  padding-left: 20px
  padding-right: 20px
  padding-bottom: 40px

.container
  display: flex
  flex-direction: column
  align-items: stretch
  gap: 24px

.body
  display: flex
  flex-direction: column
  gap: 16px
  margin-top: 16px
  justify-content: center
  align-items: center
  i
    font-size: 28px
  p
    text-align: center !important
    padding-bottom: 8px

.footer
  display: flex
  justify-content: center
  gap: 16px
  button
    width: 160px
