@import 'src/theme/colors.scss'

.root
  display: flex
  flex-direction: column
  width: auto
  align-items: center
  justify-content: center
  max-height: 100vh
  margin: 0 auto
  text-align: center
  width: 100%
  position: relative

.container
  max-width: 510px
  p
    color: #ffffffcc

.image
  margin-bottom: 40px

.input
  flex: 1 1
  margin-top: 30px

.inputButton
  height: 42px
