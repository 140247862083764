@import 'src/theme/colors.scss'

.root
  width: 100%
  max-width: 1700px
  align-items: flex-start
  margin-inline: auto

.header
  align-items: center

.body
  min-height: 620px
  padding: 16px
  width: 100%
  max-width: 100%
