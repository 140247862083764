@import 'src/theme/colors.scss'
@import '@root/theme/mixins.sass'
  
.root
  width: 100%

.headerButton
  width: 160px

.mainContent
  margin-top: 42px
  display: flex
  flex-direction: column
  gap: 16px

.inputContainer
  display: flex
  flex-direction: column
  gap: 8px

.inputGrid
  display: grid
  gap: 16px
  grid-template-columns: 1fr 1fr
  column-gap: 32px
  
.attributes
  margin-top: 16px
  h2
    display: flex
    align-items: center
    gap: 8px
    margin-bottom: 16px

.footerButtonWrapper
  display: flex
  justify-content: center
  padding: 16px

.container
  width: 100%
