@import 'src/theme/constants.scss'

.root
  padding-block: 24px
  background-color: $primaryCardBGColor
  border-radius: $small-border-radius
  overflow: auto
  flex: 1

.header
  display: grid
  grid-template-columns: 1fr auto 1fr
  align-items: flex-start
  padding-inline: 24px
  padding-block: 4px 8px
  gap: 24px

.chartTitles
  padding-inline: 24px

.body
  overflow: hidden
  height: 440px
  width: 100%
  padding-inline: 24px

.legend

.legend::before
  content: '• '
  font-size: 2.2em
  vertical-align: sub
  color: var(--marker-color)
