@import 'src/theme/colors.scss'

.root
  min-width: min(600px, 100vw)

.icon
  font-size: 32px
  margin-bottom: 8px

.body
  height: max(300px, 50vh)
  overflow-y: scroll
  padding-right: 8px

.list
  padding-left: 24px
  margin-block: 8px

.item
  margin-block: 8px
  list-style-type: square
  &::marker
    color: $primary100Color
