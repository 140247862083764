@import 'src/theme/constants.scss'

.root
  display: grid
  grid-template-rows: auto auto 1fr
  width: 100%
  max-width: 1700px
  align-items: flex-start
  margin-inline: auto

.header
  margin-bottom: 30px

.switchContainer
  width: min(300px, 100%)
  margin-bottom: 20px

@media (max-width: $tablet-max-height)
  .header
    margin-bottom: 15px
