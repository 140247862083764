@import 'src/theme/constants.scss'

.root
  max-width: min(1200px, calc(100vw - 144px))

.body
  display: inline-flex
  width: fit-content
  overflow-x: auto
  flex: 1 1 100%
  gap: 12px

.sidebarContainer
  min-width: auto
  width: 400px
  flex: 0 1 400px

.contentContainer
  width: 700px
  background: $primaryCardBGColor
  border-radius: $medium-border-radius
  padding: 12px
  margin-right: 24px

.block
  padding-inline: 24px
