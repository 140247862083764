@import 'src/theme/constants.scss'

.root
  padding-inline: 22px 16px
  padding-block: 16px
  border-radius: 1px 6px 6px 1px
  cursor: pointer
  border-left: 2px solid transparent
  marker: none

.root::marker
  content: none

.root:focus,
.root:focus-visible,
.root:hover
  background-color: $itemPrimaryHoverBgColor
  // border-color: $mainText70Color
  outline: none

.root.active
  background-color: $primaryCardL2BGColor
  border-color: $mainText100Color
