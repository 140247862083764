@import 'src/theme/colors.scss'

.root
  padding: 24px
  padding-top: 30px
  height: auto
  width: min(600px, 100vw)
  height: min(900px, 100vh - 150px)
  max-width: 100vw

.form
  display: flex
  overflow: auto
  flex: 1 1 100%
  width: 100%

.control
  display: grid
  grid-template-columns: 1fr auto
  gap: 12px
  width: 100%

.container
  display: flex
  justify-content: center
  text-align: center
  align-items: center
  flex-direction: column
  margin: 0 auto
  max-width: 100%
  overflow: auto
  flex: 1 1 100%
  width: 100%

.list
  display: flex
  flex-direction: column
  padding: 5px
