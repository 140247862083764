@import 'src/theme/colors.scss'

.root
  display: flex
  flex-direction: column
  padding-right: 16px
  padding-bottom: 16px
  border-radius: 8px
  gap: 10px

  .infoContent
    padding-left: 20px
    margin-left: 15px

    p
      font-size: inherit

.thread .infoContent
  border-left: 1px solid $text15Color
