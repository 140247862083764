@import 'src/theme/colors.scss'
$animationDuration: .2s

.rootOpen, .rootClose
  display: flex
  flex-direction: column
  position: fixed
  height: 100vh
  min-width: 70px
  align-items: stretch
  z-index: 50
  color: $primaryContrast100Color
  transition: width $animationDuration ease-in-out
  background: $primaryToolbarBgColor

.rootOpen
  width: 300px
  overflow: hidden

.rootClose
  width: 70px

.logo,
.logo:visited
  font-size: 14px
  font-weight: 600
  color: $primaryContrast100Color
  text-decoration: none
  padding: 10px
  margin: -10px
  user-select: none

.header
  margin-inline: auto
  margin-block: 20px 36px
  display: flex
  width: 100%
  padding: 8px
  margin-bottom: 36px

.logoClose
  display: flex
  justify-content: flex-start
  width: 100%
  cursor: pointer
  i
    position: absolute
    right: 0
    font-size: 16px
    top: 35px

.paddingLeft
  padding-left: 14px

.logoOpen
  display: flex
  justify-content: space-between
  width: 100%
  min-width: 150px
  padding: 0px 14px
  align-items: center
  a
    display: flex
    align-items: center
    gap: 8px
    color: $text100Color
    text-decoration: none
    white-space: nowrap
    transition: opacity $animationDuration ease-in-out
  a:visited
    color: $text100Color
    text-decoration: none
  button
    font-size: 16px

.menu
  display: flex
  flex-direction: column
  align-items: flex-start
  margin: 0px 10px
  gap: 10px
  font-size: 40px
  flex: 1 1

.footer
  display: flex
  flex-direction: column
  gap: 24px
  width: 100%
  padding-block: 20px 30px
  padding-inline: 10px
  a
    text-decoration: none
    margin: 0
  .support
    margin-left: 4px

.navItem
  display: grid
  grid-template-columns: auto
  transition: opacity $animationDuration ease-in-out
  white-space: nowrap

.navItemClose
  opacity: 0

.navItemOpen
  opacity: 1

.label
  padding: 10px
  white-space: nowrap
  transition: opacity $animationDuration ease-in-out

.helpButton
  height: 42px
  width: 42px
  min-width: 42px
  background: $green100Color
  border-radius: 50%
  color: $primaryContrast100Color

.expandableMenu
  display: flex
  flex-direction: column
  gap: 10px
  padding: 0px 10px
  flex: 1 1
  overflow-x: hidden
  overflow-y: auto
  border-bottom: 1px solid hsl(0deg 5.95% 69.33% / 24%)
  &::-webkit-scrollbar
    width: 2px
  &::-webkit-scrollbar-thumb
    border-radius: 2px
  span
    width: 100%
    text-overflow: ellipsis
    overflow: hidden
    white-space: pre

.closeBtn
  padding: 4px !important
  height: 22px !important
  font-size: 14px
  min-height: 14px !important

.headerLogo
  padding-left: 30px
  color: $text100Color

.support
  display: flex
  align-items: center
  gap: 10px
  color: $mainText100Color
  :visited
    color: $mainText100Color

.name
  color: $text70Color
  white-space: nowrap
  transition: opacity $animationDuration ease-in-out

.logoText
  color: $text100Color
  white-space: nowrap
  transition: opacity $animationDuration ease-in-out

.menuWrapper
  display: flex
  flex: 1

.hoverStrip
  width: 10px
  height: 100%
  position: absolute

.expandArrow
  position: fixed
  padding: 0px
  top: 60px
  left: 55px
  animation: fadeIn 5s
  -webkit-animation: fadeIn 0.5s
  -moz-animation: fadeIn 0.5s
  -o-animation: fadeIn 0.5s
  -ms-animation: fadeIn 0.5s

@keyframes fadeIn 
  0% 
    opacity: 0
  100%
     opacity: 1
