@import 'src/theme/constants.scss'

.root
  display: flex
  flex-direction: column
  justify-content: flex-start
  max-height: 100vh

.root h2
  text-align: center
  margin-bottom: 20px

.container
  display: flex
  flex-direction: column
  background: $primaryCardBGColor
  border-radius: $big-border-radius
  padding: 40px 12px
  width: 100%
  max-width: $tablet-min-height
  min-height: 100px
  max-height: 100%
  margin: 0 auto
  p
    line-height: normal
  h3
    margin-top: 20px
    margin-bottom: 4px
  h2, h3
    font-family: sans-serif
    font-weight: bold

  @media(max-width: $tablet-min-height)
    padding: 20px 10px
    border-radius: 30px 30px 0 0

.list
  padding: 0 25px

  @media(max-width: $tablet-min-height)
    padding: 0 10px
