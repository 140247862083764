@import 'src/theme/constants.scss'

.root
  display: flex
  flex-direction: column
  height: 100%
  overflow: auto
  gap: 24px

.container
  display: flex
  justify-content: center
  align-items: stretch
  text-align: center
  flex-direction: column
  flex: 1 1 100%
  overflow: auto
  gap: 28px

.body
  display: flex
  flex-direction: column
  overflow: auto
  flex: 1 1 100%

.formContainer
  display: flex
  flex-direction: column
  padding: 30px 20px
  border: 2px solid $primaryContrast100Color
  border-radius: 0 0 20px 20px
  min-height: 250px
  flex: 1 1 100%
  overflow: auto
  gap: 20px

.list
  display: flex
  flex-direction: column
  overflow: auto
  padding-right: 5px
  gap: 20px

.form
  display: flex
  flex-direction: column
  overflow: auto
  flex: 1 1 auto
  gap: 20px

.addButton
  align-self: flex-end
  width: fit-content

.closeBtn
  font-size: 12px
  width: 25px
  height: 25px
  border-radius: 50%

.after
  display: flex
  gap: 5px
  align-self: center
  margin-right: 10px

.errorMessage
  color: $red100Color

.radioList
  display: flex
  flex-direction: column
  gap: 10px

.label
  display: flex
  align-items: center
  gap: 10px
  padding-block: 5px
  cursor: pointer

@media (max-width: $tablet-min-width)
  .root
    max-height: calc(100vh - 130px)
