@import 'src/theme/constants.scss'

.loader
  background: linear-gradient(60deg, $mainText100Color 0%, $mainText100Color 10%, $primary105Color 45%, $primary105Color 55%, $mainText100Color 90%, $mainText100Color 100%)
  background-size: 200% 200%
  background-clip: text
  -webkit-text-fill-color: transparent
  white-space: nowrap
  text-overflow: ellipsis
  overflow: hidden
  animation: gradient-shift 1.3s linear infinite

@keyframes gradient-shift
  0%
    background-position: 200% 50%

  100%
    background-position: 0% 0%
