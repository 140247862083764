@import 'src/theme/constants.scss'

.root
  padding: 24px
  background-color: $primaryCardBGColor
  border-radius: $small-border-radius

.header

.body
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  padding-block: 24px
  min-height: 114px
  gap: 4px
