@import 'src/theme/constants.scss'

$animationFirstColor: transparent
$animationSecondColor: $primary70_20Color

.row
  display: flex
  align-items: center
  text-align: start
  border: none
  width: 100%
  padding: 0

.row.loading
  &:after
    content: ''
    position: absolute
    height: 3px
    left: 5px
    right: 5px
    bottom: 0
    background-image: linear-gradient(-45deg, $animationFirstColor 25%, $animationSecondColor 25%, $animationSecondColor 50%, $animationFirstColor 50%, $animationFirstColor 75%, $animationSecondColor 75%, $animationSecondColor)
    background-size: 30px 30px
    animation: loading 1s infinite linear

.headerRow
  color: $tableHeaderColor
  font-size: 12px
  text-transform: uppercase

.bodyRow
  color: $mainText100Color
  border-radius: $small-border-radius
  background: $primaryCardBGColor
  position: relative
  outline: none
  transition: background .1s

.bodyRow.clickable
  cursor: pointer

.bodyRow.clickable
  &:focus-visible
    background: $itemPrimaryHoverBgColor

.row *.col
  overflow: hidden
  text-overflow: ellipsis
  padding-inline: 10px

.headerRow *.col
  padding-block: 10px

@keyframes loading
  0%
    background-position: 0 0

  100%
    background-position: 30px 30px
