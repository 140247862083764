@import 'src/theme/constants.scss'

.root
  display: flex
  flex-direction: column

.searchInput
  padding-left: 0px
  border-radius: 0px
  background: transparent
  border: none
  border-bottom: 1px solid $mainText50Color
  padding-bottom: 0px
  i
    color: $mainText100Color
  i:first-child
    margin-inline: 0px 10px
  i:last-child
    margin-inline: 10px 0px
  &:focus
    outline: none
    border: none
    border-bottom: 1px solid $mainText50Color

.filters
  padding-block: 8px
  padding-left: 8px
  gap: 4px
  width: 100%
  min-height: 115px

.filtersContainer
  display: flex
  flex-direction: column
  transition: max-height .1s ease-out
  max-height: 115px
  overflow: auto
  border-bottom: 1px solid $mainText50Color

.filtersContainer.hidden
  max-height: 0
  border: none

.checkboxFilterName
  white-space: nowrap
  font-size: 12px
  background: transparent
  padding: 4px
  border-radius: 4px
