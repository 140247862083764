@import "@root/theme/colors.scss"

.root
  display: flex
  flex-direction: column
  align-items: center
  gap: 12px
  margin: auto

.grid
  display: flex
  width: fit-content
  gap: 4px

.column
  display: flex
  flex-direction: column
  gap: 4px

.colorButton
  cursor: pointer
  border-radius: 4px
  height: 24px
  width: 24px
  border: 1.5px solid $contrastText100Color

  &.active
    outline: 1.5px solid currentColor
