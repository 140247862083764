@import 'src/theme/constants.scss'

.alertTemplateContainer
  padding-top: 26px

@media (max-width: $tablet-min-width)
  .alertTemplateContainer
    padding-top: 16px

@media (max-width: $tablet-min-height)
  .alertTemplateContainer
    padding-top: 6px
