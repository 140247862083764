@import 'src/theme/constants.scss'

$border-color: var(--card-inner-border-color, white)
$card-backbgound-color: var(--card-bg-border-color, transparent)

$inner-border-style: 2px solid $border-color

.root
  --card-inner-border-color: #{$dividerLineDarkColor}
  --card-bg-border-color: #ffffff0f
  display: flex
  font-size: 14px
  background: $card-backbgound-color
  border-radius: 6px
  border: 1px solid transparent
  // cursor: pointer
  color: $mainText100Color
  padding: 0
  text-align: start
  align-items: initial

.variant-horizontal
  flex-direction: row
  @media (max-width: $tablet-min-width)
    flex-direction: column

.variant-vertical
  flex-direction: column

.root:hover
  // border-color: $primary100Color
  // box-shadow: 0px 0px 0px 3px rgba(149, 109, 239, 0.25)
  // background: $secondary80Color

.result
  display: flex
  flex-direction: column
  flex: 1 1 auto
  overflow: auto

.body
  display: flex
  flex: 1 1 auto
  max-width: 100%
  overflow: auto

.variant-horizontal .body
  flex-direction: row
  @media (max-width: $tablet-min-width)
    flex-direction: column

.variant-vertical .body
  flex-direction: column

.answerContainer
  display: flex
  flex: 1 1 auto
  padding: 15px 20px
  position: relative
  flex-direction: column

.answerContainer
  max-width: 100%
  overflow: auto
  gap: 10px

.footer
  display: flex
  justify-content: space-between
  align-items: center
  border-top: $inner-border-style
  padding: 10px 20px
  gap: 10px

.chips,
.options
  display: flex
  font-size: 1.4em
  gap: 10px

.chips
  flex-wrap: wrap

.options
  color: $mainText50Color

.control
  display: flex
  align-items: center
  justify-content: center
  font-size: 1.4em

.variant-horizontal .control
  min-width: 135px
  border-left: $inner-border-style
  @media (max-width: $tablet-min-width)
    width: auto
    flex-direction: column
    align-items: flex-start
    padding: 0 15px
    min-height: 56px
    border-left: none
    border-top: $inner-border-style

.variant-vertical .control
  padding: 0 15px
  min-height: 56px
  justify-content: flex-start
  border-top: $inner-border-style

.wrapRow
  display: flex
  flex-wrap: wrap
  gap: 3px

.color-primary
  --card-inner-border-color: #{$dividerLineDarkColor}
  --card-bg-border-color: #{$primaryCardBGColor}

.color-transparent
  --card-inner-border-color: transparent
  --card-bg-border-color: transparent

  .answerContainer
    padding: 0

  .body
    padding: 5px 0

  .footer
    padding: 5px 0

  .control
    padding: 7px 0
    min-height: 0
