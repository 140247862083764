@import "@root/theme/colors.scss"
@import "@root/theme/mixins.sass"

.root
  display: flex
  user-select: none
  outline: 4px solid $primary50Color
  background: $primaryMainBGColor

.textarea
  width: 100%
  caret-color: white !important
  border: 2px solid $primary110Color
  border-radius: 2px
  padding: 3px

.dropdown
  height: fit-content
  max-height: min(250px, 50vh)
  overflow: auto
  margin-top: 2px
  @include thinScroll('&')
