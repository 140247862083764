@import 'src/theme/constants.scss'

.root
  width: 600px
  max-width: 600px

.form
  display: flex
  flex-direction: column
  gap: 20px
  

.inputContainer
  display: flex
  flex-direction: column
  gap: 8px
   
.radioGroup
  display: flex
  flex-direction: column
  gap: 10px
  div
    display: flex
    align-items: center
    gap: 8px
 
.footer
  display: flex
  justify-content: center
  gap: 16px
  margin-top: 16px
  button
    width: 150px

.header
  font-weight: 700 !important


@media (min-width: $tablet-max-height)
  .root
    width: 800px
    max-width: 800px
    padding: 50px