@import 'src/theme/colors.scss'
@import "src/theme/constants.scss"

.modalRoot
  min-height: min(calc(100vh - 150px), 550px)

.root
  width: 450px
  height: 100%

.formContainer
  height: 100%

.input
  height: 150px
  flex: 1 1 100%

.after
  display: flex
  gap: 5px
  align-self: center

.closeBtn
  font-size: 12px
  width: 25px
  height: 25px
  border-radius: 50%

.divider
  width: 100%
  height: 0
  border: none
  border-top: 1px solid $mainText20Color

@media (max-width: $mobile-min-width)
  .root
    width: auto
