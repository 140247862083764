
@import 'src/theme/colors.scss'

.root
  display: flex
  align-items: center
  justify-content: flex-start
  cursor: pointer
  font-size: 16px
  column-gap: 1em

.label
  flex: 1 1 auto
  overflow: hidden
  text-overflow: ellipsis
