@import 'src/theme/constants.scss'

.container
  display: grid
  align-items: center
  overflow: hidden
  transition: height .3s ease-out

.blink
  display: block
  overflow: auto

.container.withAnimation
  .blink
    animation: appearance .6s ease-in-out

@keyframes appearance
  0%
    opacity: .35
  100%
    opacity: 1
