@import 'src/theme/constants.scss'

.root
  display: flex
  flex-direction: column

.body
  display: flex
  flex-wrap: wrap
  gap: 12px
