@import 'src/theme/constants.scss'

.root
  display: flex
  border-radius: $small-border-radius
  background: $text10Color
  padding: 14px
  margin: 0
  gap: 16px

.preview
  flex: 1 1 100%

.list
  padding-block: 4px
  margin-block: auto
  overflow: auto
  max-height: 250px
  flex: 1 1 100%
