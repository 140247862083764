.filterHeader
	margin-top: 2rem

.root
	padding: 8px

.lineItem
	justify-content: start

.container
	height: 300px
	overflow: hidden