@import 'src/theme/colors.scss'

.root
  padding: 24px
  padding-top: 30px
  height: auto
  max-height: fit-content
  width: min(600px, 100vw)
  height: min(900px, 100vh - 150px)

.container
  display: flex
  justify-content: center
  text-align: center
  align-items: center
  flex-direction: column
  margin: 0 auto
  width: 100%
  overflow: auto

.form
  display: flex
  overflow: auto
  flex: 1 1 100%
  width: 100%

.control
  display: grid
  grid-template-columns: 1fr auto
  gap: 12px
  width: 100%

.loader
  margin: auto
