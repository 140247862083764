@import 'src/theme/colors.scss'

.root
  display: flex
  flex-direction: row
  gap: 8px
  background: $gold10Color
  padding: 8px 16px
  justify-content: space-between
  margin-bottom: 16px
  border-radius: 8px
  
.headings
  h4
    display: flex
    gap: 8px
      