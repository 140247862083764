@import 'src/theme/constants.scss'

.root
  position: relative

.cover
  content: ''
  position: absolute
  inset: 0
  border-radius: $medium-border-radius
  overflow: hidden
  display: flex
  justify-content: center
  align-items: center
  pointer-events: none
  transition: opacity .3s
  .loader
    position: absolute
    opacity: 0
    transition: inherit
  .glass
    box-shadow: inset 1px 1px 1px 1px rgba(166, 143, 255, 0.05), inset -1px -1px 0 1px rgba(15, 4, 46, 0.4)
    border-radius: $medium-border-radius
    position: absolute
    inset: 0
    opacity: 0
    background: rgba(255, 255, 255, 0.01)
    backdrop-filter: blur(25px)
    transition: inherit

.cover.active
  pointer-events: all
  transition: opacity .1s
  .loader
    opacity: 1
  .glass
    opacity: 1