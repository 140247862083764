@import './Button-root.module'

.large
  --btn-min-height: 50px
  --btn-text-font-size: 40px
  --btn-padding: 24px
  font-size: 16px

.big
  --btn-min-height: 42px
  --btn-text-font-size: 32px
  --btn-padding: 18px
  font-size: 15px

.medium
  --btn-min-height: 36px
  --btn-text-font-size: 26px
  --btn-padding: 16px
  font-size: 15px

.small
  --btn-min-height: 30px
  --btn-text-font-size: 20px
  --btn-padding: 16px
  font-size: 14px

.tiny
  --btn-min-height: 25px
  --btn-text-font-size: 15px
  --btn-padding: 15px
  font-size: 14px
