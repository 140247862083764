@import 'src/theme/constants.scss'

.root
  display: flex
  flex-direction: column
  width: 100%
  align-items: flex-start
  padding: 30px 60px
  margin: 0 60px
  gap: 30px
  max-width: 1700px
  margin-inline: auto

  @media (max-width: $tablet-min-width)
    padding: 0

.body
  display: flex
  flex-direction: column
  flex: 1 1
  width: 100%
  gap: 32px

  .row
    display: grid
    grid-template-columns: 1fr 1fr
    gap: 32px

    @media (max-width: $tablet-min-width)
      grid-template-columns: 1fr
