@import 'src/theme/constants.scss'

.root
  display: flex
  justify-content: space-between
  position: fixed
  width: 100%
  top: 0
  left: 0
  height: 60px
  align-items: center
  z-index: 10
  padding: 0 20px
  border-bottom: 1px solid $dividerLineLightColor
  background-color: $primaryToolbarBgColor

.logo,
.logo:visited
  margin: 20px auto
  font-weight: 600
  font-size: 14px
  color: $primaryContrast100Color
  text-decoration: none

.closeBurger
  visibility: hidden
