@import 'src/theme/colors.scss'

.root
  overflow: hidden
  position: relative
  width: 100%
  max-width: 100%
  min-height: 38px

.underlined::before
  content: ''
  position: absolute
  bottom: 0px
  height: 1px
  width: 100%
  background: $mainText100Color
  opacity: .2
  margin: 0 5px

.scrollableContainer
  font-size: 14px
  max-width: 100%
  overflow: auto
  -ms-overflow-style: none
  scrollbar-width: none
  &::-webkit-scrollbar
    display: none

.items
  display: flex

.itemContainer
  min-width: fit-content
  flex: 1 auto
  justify-content: center
  display: flex
  align-items: center

.item
  width: 100%
  color: $mainText100Color
  padding: 10px 0
  background: transparent
  border: none
  border-bottom: 1px solid $mainText50Color
  font-style: normal
  font-weight: 500
  font-size: 14px
  line-height: 17px
  cursor: pointer
  white-space: nowrap
  outline: none
  user-select: none
  text-transform: capitalize
  margin: 0
  display: flex
  justify-content: center
  align-items: center
  gap: 8px

.item:hover,
.item:focus
  color: $mainText50Color

.item.active
  border-bottom: 2px solid $primary100Color
  color: $mainText100Color
  color: $primary100Color

.leftArrow,
.rightArrow
  top: 4px
  position: absolute
  margin: auto 0

.leftArrow
  left: 0
.rightArrow
  right: 0

.disabled
  opacity: .8
  pointer-events: none
