@import 'src/theme/constants.scss'

.root
  display: flex
  flex-direction: column
  width: 100%
  gap: 12px

.withControl
  display: flex
  align-items: flex-start
  gap: 12px

  &>*
    flex: 1 1 auto

  &>*:first-child
    flex: 1 1 100%

.control
  display: flex
  align-items: center
  gap: 12px

.header
  display: flex
  flex-direction: column
  gap: 6px

.title
  align-self: center

.meta
  display: flex
  align-items: center
  flex-wrap: wrap
  gap: 8px

.arrow
  align-self: center

.description
  display: -webkit-box
  -webkit-line-clamp: 2
  -webkit-box-orient: vertical
  text-overflow: ellipsis
  overflow: hidden
