@import 'src/theme/constants.scss'
@import 'src/theme/mixins.sass'

.root
  display: flex
  flex-direction: column
  min-height: 100%
  overflow: hidden
  width: 100%
  padding: 30px 60px
  margin: 0 auto
  max-width: $container-max-width

.header
  margin-bottom: 40px

.distribution
  display: flex
  justify-content: flex-start

.control
  margin-bottom: 24px

.body
  display: flex
  flex-direction: column
  flex: 1 1 100%
  width: 100%

.tableBody
  display: flex
  flex-direction: column
  flex: 1 1 100%
  width: 100%

  @include thinScroll('*')

@media (max-width: $tablet-min-width)
  .root
    padding: 0
