@import 'src/theme/constants.scss'

.root
  padding: 40px
  padding-top: 30px
  width: 570px

.container
  display: flex
  gap: 10px
  justify-content: center
  align-items: stretch
  flex-direction: column
  max-height: min(700px, calc(100vh - 250px))

.expertsSummary
  width: 100%

.listContainer
  min-height: 350px
  max-height: 350px

.divider
  display: flex
  align-items: center
  margin-block: 8px
  &:before,
  &:after
    content: ''
    background: grey
    height: 1px
    flex: 1 1 100%
    margin: 8px

.fullWidth
  width: 100%

.header
  display: flex
  justify-content: space-between
  align-items: center
