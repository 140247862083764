@import 'src/theme/constants.scss'

.root
  display: flex
  flex-direction: column
  width: 100%
  align-items: flex-start
  padding: 30px 0
  margin: 0 60px
  overflow: auto
  max-height: 100vh

.header
  width: 100%
  margin-bottom: 45px

.container
  padding-block: 20px

.block
  margin-inline: 20px

.left
  display: flex
  flex: 1 0 500px

.right
  display: flex
  flex: 1 1 100%
  border-left: 1px solid $primaryCardL2BGColor

.body
  display: flex
  overflow: auto
  width: 100%
  flex: 1 1 100%

.box
  background-color: $primaryCardBGColor
  border-radius: $big-border-radius
  border: none

.sidebarList
  overflow-y: scroll
  padding-right: 5px
  margin-right: 10px

.mainList
  overflow-y: scroll
  padding-right: 5px
  margin-inline: 20px 10px

.divider
  height: 1px
  background: $dividerLineLightColor
  border: none
  margin: 0

.listItem.showControlOnHover
  .listItemControl
    visibility: hidden

.listItem.showControlOnHover:hover
  .listItemControl
    visibility: visible
