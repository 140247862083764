@import 'src/theme/colors.scss'

.root
  padding: 30px
  width: 600px
  max-height: calc(100vh - 150px)

.container
  display: flex
  justify-content: center
  align-items: stretch
  flex-direction: column
  flex: 1 1 auto
  overflow-y: auto
  gap: 32px

.cards
  display: grid
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr))
  gap: 24px
