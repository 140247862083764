@import "@root/theme/colors.scss"
@import '@root/theme/mixins.sass'

.root
  width: 100%

.container
  display: flex
  width: 100%
  margin-top: 42px
  gap: 32px
  .mainContent
    flex: 1

  .linkedContent
    width: 30%
    display: flex
    flex-direction: column
    padding-left: 16px
    gap: 24px
    border-left: 1px solid $dividerLineLightColor

.header
  margin-top: 24px
  margin-bottom: 8px

.mainHeading
  margin-bottom: 8px
  margin-top: 8px

.identifiers
  display: flex
  gap: 8px
  flex-wrap: wrap

.attributes, .identifiers
  margin-top: 16px

.card
  padding: 16px
  border-radius: 16px
  background: $primaryCardBGColor

table
  > thead > tr > td
    border-bottom: 2px solid $dividerLineLightColor !important
  td, th
    padding: 1.5em !important

  a,
  a:visited
    color: $mainText100Color

  a:focus,
  a:active
    color: $mainText70Color

.linkedContentList
  display: flex
  overflow-x: hidden
  flex-direction: column
  gap: 16px
  max-height: 900px
  overflow-y: auto
  @include thinScroll('&', 2px)

.contentCard
  display: flex
  flex-direction: column
  gap: 8px
  background: $secondaryButtonBgColor
  border-radius: 16px
  font-size: 14px
  padding: 16px
  border: 1px solid transparent
  span
    word-break: break-all

.loader
  display: flex
  width: 100%
  justify-content: center

.nameSkeleton
  width: 100px

.categoriesWithChild
  display: flex
  gap: 24px
  width: 100%
  flex-wrap: wrap
  margin-top: 24px

.childParentWrapper
  display: flex
  flex-direction: column
  gap: 8px

.tabsContainer
  margin-top: 32px

.contentHeader
  display: flex
  justify-content: space-between
  align-items: center
  width: 100%
  gap: 8px
  div
    i
      border-radius: 50%
      background: $secondary100Color
      height: 28px
      width: 35px
      max-height: 28px
      max-width: 28px
      color: $primary100Color
      display: flex
      gap: 4px
      align-items: center
      white-space: nowrap
      overflow-x: hidden
      text-overflow: ellipsis

  .contentButton
    background: $primary100Color
    i
      color: $text100Color
    &:hover
      color: $mainText70Color

.contentTitleText
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
