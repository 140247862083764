@import 'src/theme/constants.scss'

.root
  padding: 12px
  background: red
  border-radius: 40px
  background: $primaryCardBGColor
  border: 1px solid $secondary100Color

.searchInput
  min-width: min(300px, 100%)
