@import 'src/theme/colors.scss'

.iconContainer
  display: flex
  align-items: center
  gap: 10px

.defaultIcon
  max-width: 100%
  &.primary
    color: $primary100Color

.neshIcon
  border-radius: 20%

  &.primary
    color: $primaryContrast100Color
    background: $primary100Color
