@import 'src/theme/constants.scss'

.root
  display: flex
  justify-content: space-between
  background: $primaryCardBGColor
  border-radius: $small-border-radius
  gap: 16px
  overflow: hidden

  &:hover
    background: $primaryCardBGHoverColor

  .avatar
    margin: 8px 0px 8px 8px

  .expertDetails
    display: flex
    justify-content: space-between
    padding: 8px 0px
    flex: 1
    align-items: center
    padding-right: 16px
    gap: 24px
