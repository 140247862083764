@import "@root/theme/colors.scss"

.resizer
  background: transparent
  background: $blue10Color
  &.vertical
    cursor: row-resize
  &.horizontal
    cursor: column-resize

.handle
  position: absolute
  z-index: 3

  &::after
    content: ''
    position: absolute
    border-radius: 5px
    border: 1px solid $primary70_20Color
    transition: border 100ms ease-in-out

  &.active:after,
  &:hover::after
    border: 2px solid $primary105Color

  &.vertical
    bottom: -7px
    height: 14px
    left: 0
    right: 0
    cursor: row-resize
    &::after
      top: 50%
      left: 3px
      right: 3px
      transform: translateY(-50%)

  &.horizontal
    right: -7px
    width: 14px
    top: 0
    bottom: 0
    cursor: col-resize
    &::after
      left: 50%
      top: 3px
      bottom: 3px
      transform: translateX(-50%)
