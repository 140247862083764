@import 'src/theme/constants.scss'

.root
  display: grid
  grid-template-columns: auto 1fr auto
  width: 100%
  column-gap: 8px
  row-gap: 4px
  align-items: center
  .avatar
    font-size: 10px

  .title
    margin-bottom: 4px
