@import 'src/theme/constants.scss'
.root
  display: flex
  flex-direction: column
  align-items: flex-start
  gap: 16px
  margin: 8px 0

.body
  display: grid
  gap: 10px
  grid-template-columns: auto 1fr
  user-select: none

.content
  display: grid
  gap: 8px

.header
  display: flex
  justify-content: space-between
  width: 100%

.footer

.alignCenter
  display: flex
  align-items: center
  gap: 4px
