@import 'src/theme/colors.scss'

.checkbox
    padding: 10px
    border-radius: 6px
    color: $mainText100Color
    padding: 10px 20px
    margin: 0 8px
    &:last-child
        margin-bottom: 8px
    &:first-child
        margin-top: 8px

.selected
    background: $primary70_20Color
