@import 'src/theme/colors.scss'

.root
  min-width: min(600px, 100vw)

.body
  min-height: 200px
  max-height: min(700px, calc(100vh - 350px))
  height: inherit
  overflow-y: scroll
  overflow-x: auto
  padding-right: 8px
