@import 'src/theme/colors.scss'

.root
  padding-block: 16px

.date
  position: sticky
  top: 0
  z-index: 1
  margin-inline: auto
