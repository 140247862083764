@import 'src/theme/constants.scss'

.root
  padding: 30px
  min-width: 100%
  background: $primaryToolbarBgColor
  color: $primaryContrast100Color
  border-top: 1px solid $primaryCardL3BorderColor

  .copyright
    padding: 30px 15px 0

  .links
    display: inline-block
    width: 100%
    margin: 0
    padding: 0 15px

  .link
    display: inline
    min-width: 30px
    padding-inline: 8px
    border-inline: 1px solid currentColor
    text-align: center
    line-height: 200%

  .link:first-child
    padding-left: 0
    border-left: none

  .link:last-child
    padding-right: 0
    border-right: none

  .link a, .link a:active
    text-decoration: none
    color: currentColor

  .link a:focus, .link a:hover
    text-decoration: underline

  @media (max-width: $mobile-min-width)
    .link
      display: block
      border-inline: none
      text-align: center
      padding-block: 10px
      line-height: initial
