@import 'src/theme/constants.scss'

.root
  display: flex
  flex-direction: column
  align-items: stretch
  min-height: 100vh
  background: $primaryMainBGColor

.body
  display: flex
  align-items: center
  justify-content: center
  position: relative
  flex: 1 1 100%
  width: 100%
  overflow: hidden

.formContainer
  display: flex
  flex-direction: column
  align-items: center
  width: min(600px, 100vw)
  padding: 32px 48px
  border-radius: 16px
  margin-block: 24px
  backdrop-filter: blur(5px)
  border: 1px solid $primary70_20Color
  background: $primary80_10Color
  background: linear-gradient(126.85deg, var(--primary-80-10-color, #956def33) 0%, rgba(0, 0, 0, 0) 56.19%), linear-gradient(110.71deg, var(--primary-80-10-color, #956def33) -9.63%, rgba(0, 0, 0, 0) 69.72%)
  z-index: 0

  @media (max-width: $tablet-max-width)
    width: min(450px, 100vw)
    padding: 32px

  @media (max-width: $mobile-min-width)
    flex: 0
    justify-content: center
    border-radius: 0
    border: none
    margin: 0
    width: 100%
    min-width: 100%
    padding: 60px 30px
    min-height: calc(100vh - 30px)

.topLeftFragment
  position: absolute
  top: 0
  left: 0
  height: 240%
  width: auto
  transform: translate(-50%, -60%)

.bottomRightFragment
  position: absolute
  bottom: 0
  right: 0
  height: 100%
  width: auto
  transform: translate(35%, 45%)
