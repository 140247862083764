@import 'src/theme/colors.scss'
@import '@root/theme/mixins.sass'

.root
  display: flex
  flex-direction: column
  gap: 24px

.content
  border-radius: 16px
  padding: 8px 0px
  overflow: hidden
  height: 450px
  background: $primaryContrast10OpacityColor

.listContainer
  display: flex
  flex-direction: column
  gap: 16px

.searchInput
  min-width: auto
  flex: 1 0 auto

.list
  display: flex
  flex-direction: column
  overflow-y: auto
  padding: 0px 8px
  max-height: 100%
  gap: 4px
