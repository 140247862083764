@import "@root/theme/colors.scss";

.root {
  display: flex;
  flex-wrap: wrap;
  user-select: none;
  align-items: center;
  font-size: 14px;
  line-height: 140%;
  color: $mainText50Color
}

.item {
  display: flex;
  align-items: center;
  gap: 4px
}

.item:not(:last-child) {
  color: $mainText100Color;
}

.item.clickable {
  cursor: pointer;
}

.home {
  font-size: 18px;
}