@import 'src/theme/constants.scss'

.root
  display: grid
  grid-template-rows: auto 1fr
  width: 100%
  align-items: flex-start
  gap: 40px
  padding: 30px 60px
  margin: 0 auto
  max-width: $container-max-width

.body
  display: flex
  flex-direction: column
  gap: 16px
  min-height: min(500px, 50vh)

  &>section
    display: block

.grid
  display: grid
  width: 100%
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr))
  gap: 20px
  padding-block: 10px 20px

.card
  *:global(.hiddenTrigger)
    padding: 5px
    margin: -5px
    background: transparent
    border: none
    font-size: 20px
    color: $mainText50Color
    opacity: 0
    cursor: pointer

  *:global(.hiddenTrigger):focus,
  *:global(.hiddenTrigger):focus-visible
    opacity: 1

  *:global(.hiddenTrigger):global(.active)
    color: $primary100Color

  *:global(.hiddenTrigger):hover
    color: $mainText100Color

.card:hover
  *:global(.hiddenTrigger)
    opacity: 1

@media ( max-width: $tablet-min-width)
  .root
    gap: 20px
    padding: 0
