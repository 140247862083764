@import "@root/theme/colors.scss"

.root
  display: flex
  align-items: center
  justify-content: center
  width: 100%
  height: 100%
  background: $primary70_20Color
  border: 1px solid $primary80_10Color

  // &.y
  //   border-right-width: 3px

  // &.x
  //   border-bottom-width: 3px

.selected
  color: $primaryContrast100Color
  background: $primary50Color

.handle
  position: absolute
