@import 'src/theme/colors.scss'

$animationDuration: 50s

.root
  position: relative
  display: flex
  flex-direction: column
  padding: 16px 20px
  border-radius: 12px
  gap: 32px
  align-items: stretch
  justify-content: space-between
  color: $primaryContrast100Color
  cursor: pointer
  border: none
  text-align: start
  min-height: 150px
  border: 2px solid $primary50Color
  background: $primary50Color
  z-index: 0
  user-select: none

.root:not(:disabled):hover
  opacity: 0.95
  border-color: $primary105Color

.header
  display: flex
  align-items: center
  gap: 8px
  z-index: 1

.body
  display: flex
  align-items: center
  gap: 32px
  z-index: 1

.message
  flex: 1

.titleIcon
  font-size: 32px
  padding: 2px
  border-radius: 50%

.runIcon
  border: 1px solid $primaryContrast100Color
  border-radius: 50%
  font-size: 28px
  padding: 4px

.root:disabled
  opacity: 0.7
  cursor: default

.bubblesContainer
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  overflow: hidden
  border-radius: inherit
  z-index: 0

  &>*
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0

.bubbles
  filter: url(#liquid)
  &>*
    position: absolute
    top: -100%
    left: -100%
    background: radial-gradient(farthest-corner at 30px 60px, #{$primary80Color} 0%, #{$primary50Color} 100%)
    height: 150px
    width: 150px
    transform: translate(-50%, -50%)
    border-radius: 50%
    filter: blur(10px)

  &>*:nth-child(1)
    animation: chaoticMove1 calc(#{$animationDuration} * 0.5) 5s infinite linear

  &>*:nth-child(2)
    animation: chaoticMove2 calc(#{$animationDuration} * 1.2) infinite linear reverse

  &>*:nth-child(3)
    animation: chaoticMove3 calc(#{$animationDuration} * 0.5) infinite linear

  &>*:nth-child(4)
    animation: chaoticMove3 calc(#{$animationDuration} * 1) 3s infinite linear reverse

@keyframes chaoticMove1
  0%
    top: 130%
    left: -10%
    transform: scale(1) translate(-50%, -50%)
  50%
    top: 5%
    left: 10%
    transform: scale(1.4) translate(-50%, -50%)
  100%
    top: 120%
    left: 110%
    transform: scale(1) translate(-50%, -50%)

@keyframes chaoticMove2
  0%
    transform: scale(1.6) translate(-50%, -50%)
    top: -30%
    left: -20%
  25%
    top: 90%
  50%
    transform: scale(0.8) translate(-50%, -50%)
    top: 10%
  75%
    top: 80%
  100%
    top: 120%
    left: 130%
    transform: scale(1.6) translate(-50%, -50%)

@keyframes chaoticMove3
  0%
    transform: scale(1) translate(-50%, -50%)
    top: -20%
    left: -20%
  25%
    top: 90%
  50%
    transform: scale(1.2) translate(-50%, -50%)
    top: 20%
  75%
    top: 90%
  100%
    top: -40%
    left: 110%
    transform: scale(1) translate(-50%, -50%)
