@import 'src/theme/colors.scss'
  
.root
  padding: 16px
  border-radius: 8px
  background-color: $primaryContrast10OpacityColor
  border-bottom: 1px solid transparent
  display: flex
  flex-direction: column
  gap: 16px

.question
  cursor: pointer

.footer
  display: flex
  justify-content: space-between
  align-items: center

.footerButtons
  display: flex
  gap: 8px
  button
    font-size: 10px
    padding: 4px 8px