@import 'src/theme/constants.scss'
@import '@root/theme/mixins.sass'

.titleContainer
  display: flex
  flex-direction: column
  align-items: stretch
  margin-bottom: 16px
  gap: 4px
  font-size: 16px

.title
  display: flex
  align-items: center
  gap: 8px
  width: 100%
  i
    font-size: 1.25em

  .expertBadge
    display: flex
    align-items: center
    gap: 4px

  .prefix
    flex: 0 1 auto
    white-space: nowrap

  .titleText
    flex: 1 1 auto
    overflow: hidden
    overflow-wrap: break-word
    text-overflow: ellipsis
    white-space: nowrap

.source
  flex: 1 0 auto
  font-size: inherit
  &:hover
    background: $primaryContrast80Color
    color: $text100Color

@media (max-width: $tablet-max-height)
  .title
    font-size: 12px
