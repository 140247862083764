.root
  max-width: 700px

.form
  display: flex
  flex-direction: column
  width: 600px

  &.tall
    height: 450px

.messageText
  display: flex
  flex-wrap: wrap
  margin-block: 11px

.errorMessage
  margin-top: 4px
  margin-inline: 20px

.placeholder
  max-width: 450px

.inlineFlow
  display: inline-flex
  column-gap: 8px
  row-gap: 12px
  flex-wrap: wrap

.input
  flex: 1 1 220px
  overflow: auto
  &>*
    max-width: 100% !important
