@import 'src/theme/constants.scss'

.button
  display: flex
  flex-direction: column
  justify-content: space-around
  align-items: stretch
  width: 25px
  height: 25px
  position: relative
  transform: rotate(0deg)
  transition: .5s ease-in-out
  cursor: pointer
  padding: 0
  margin: 0
  background: none
  border: none
  z-index: 1

.button>span
  width: 100%
  border: 1px solid $primaryContrast100Color
  border-radius: 2px
  opacity: 1
  transform: rotate(0deg)
  transition: .25s ease-in-out
  --translate: 0
  --rotate-angle: 0

.button.open>span
  --translate: 8.5px
  --rotate-angle: 45deg

.button.open>span:nth-child(1)
  transform: translateY(var(--translate)) rotate(var(--rotate-angle))

.button.open>span:nth-child(2)
  opacity: 0

.button.open>span:nth-child(3)
  transform: translateY(calc(-1 * var(--translate))) rotate(calc(-1 * var(--rotate-angle)))
