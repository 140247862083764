@import "@root/theme/colors.scss";
@import "@root/theme/mixins.sass";

.root {
  outline: none;
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
}

.input {
  flex-wrap: wrap;
  font-size: 14px;
  line-height: 21px;
}

.valueContainer {
  display: flex;
  position: relative;
  justify-content: space-between;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
  outline: none;
  border: 1px solid red;
  border-radius: 10px;
  background-color: white;
  padding: 7px 45px 7px 14px;
  height: 36px;
  
  .after {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.dropdownContainer {
  padding-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 100%;
  z-index: 1;
}

.list {
  max-height: 350px;
  min-width: 150px;
  overflow: auto;
  padding: 0;
  margin: 0;
  overflow-anchor: none;
  @include thinScroll("&");
}

.arrow {
  right: 4px;
  top: 2px;
  position: absolute;
}


.valueContainer.active {
  border-color: $primary10Color;
}


.root.disabled {
  pointer-events: none;
  .input {
    background: hsla(0, 0%, 92%, 0.5);
  }
}

.pos-x {
  padding-left: 4px;
  top: 0;
  left: 100%;
}

.pos-y {
  padding-top: 4px;
  top: 100%;
  left: 0;
}