@import 'src/theme/colors.scss'

.root
  display: flex
  padding: 15px 20px
  border-radius: 6px
  color: $mainText100Color
  height: fit-content
  border: 2px solid transparent
  background-color: $itemPrimaryBgColor
  filter: grayscale(.7)
  font-size: 16px
  margin: 3px
  user-select: none

.body
  display: flex
  flex: 1 1 auto
  align-items: center
  gap: 16px
  i
    font-size: 14px

.root:hover
  filter: grayscale(0.3)
  border-color: $primary100Color
  background: $itemPrimaryHoverBgColor

.root.checked
  filter: grayscale(0)
  border-color: $primary100Color
  box-shadow: 0px 0px 2px 3px $itemPrimaryActiveBgColor
  background: $itemPrimaryActiveBgColor

.label
  text-align: start
  flex: 1 1 auto

.size-small
  padding: 6px 10px

.size-medium
  padding: 11px 16px

.size-big
  padding: 15px 20px
