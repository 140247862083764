@import 'src/theme/colors.scss'

.root
    display: flex
    flex-direction: column
    width: 100%

.header
    position: relative
    display: flex
    padding: 20px
    background: transparent
    justify-content: flex-start
    align-items: center
    border: none
    color: $mainText100Color
    cursor: pointer
    gap: 1em
    border-bottom: 1px solid $dividerLineLightColor

.container
    display: flex
    flex-direction: column
    gap: 8px
    background: #ffffff0d
    border-bottom: 1px solid $dividerLineLightColor

.arrow
    position: absolute
    right: 20px
