@import "src/theme/constants.scss"

.root
  display: flex
  font-size: .9em
  font-weight: 600
  font-style: normal
  min-width: 3em
  width: 3em
  min-height: 3em
  height: 3em
  color: white
  align-items: center
  justify-content: center
  position: relative
  cursor: inherit
  border: none
  padding: 0
  background: transparent
  border-radius: 50%
  background-color: $inputMainBgColor
  box-shadow: inset 5px -5px 0 1px #00000010

.active::before
  content: ""
  position: absolute
  top: -4px
  right: -4px
  height: calc(100% + 4px)
  width: calc(100% + 4px)
  border: 2px solid $navItemActiveBgColor
  border-radius: 50%

.image
  height: 100%
  width: 100%
  border-radius: inherit

.clickable
  cursor: pointer

.icon
  font-size: 1.5em
