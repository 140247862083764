@import 'src/theme/constants.scss'

.root
  display: flex
  justify-content: space-between
  gap: 16px
  overflow: hidden
  padding-inline: 24px
  cursor: default
  padding-block: 8px

.rank
  min-width: 2ch
  align-self: center

.details
  display: flex
  justify-content: space-between
  flex: 1
  align-items: center
  gap: 24px
