@import 'src/theme/constants.scss'

.root
  display: grid
  grid-template-columns: 1fr
  gap: 30px
  max-width: 490px
  width: 100%
  margin: 0 auto

.input
  font-size: 24px
  margin: auto

@media (max-width: $mobile-min-height)
  .input
    font-size: 20px

  .input>*
    margin: 3px
