@import 'src/theme/colors.scss'

@mixin flex($gap)
  display: flex
  flex-direction: column
  justify-content: center
  gap: $gap

.root
  @include flex(16px)
  margin: 0 auto 27px

.controls
  @include flex(16px)
