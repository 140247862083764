@import 'src/theme/colors.scss'

.root
  display: flex
  flex-direction: column
  align-items: center
  max-width: 350px
  font-style: normal
  font-weight: normal
  font-size: 16px
  line-height: 26px
  text-align: center
  justify-content: center
  height: 100%
  margin: 0 auto
  gap: 12px

.fluid
  align-self: center
  justify-self: center
