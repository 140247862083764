@import 'src/theme/colors.scss'

.root
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  font-size: 14px
  max-width: 100%
  width: 100%
  height: 100%

.header
  display: flex
  align-items: center
  width: 100%
  margin-bottom: 5px
  min-height: 21px
  gap: 10px

.label
  text-align: start
  overflow: hidden
  text-overflow: ellipsis
  flex: 1 1

.gutter
  position: relative
  height: 4px
  width: 100%
  background: #ffffff4d

  border-radius: 2px
  overflow: hidden

.value
  color: $mainText50Color
  font-size: 12px
  line-height: 21px
  margin: 0 auto

.doneIcon
  font-size: 12px
  min-height: 20px
  max-height: 20px
  min-width: 20px
  max-width: 20px
  border-radius: 50%
  margin: 0 auto
  background: $primary100Color

.bar
  position: absolute
  height: 4px
  top: 0
  left: 0
  background: #ffffff

.color-default
  .bar
    background: $mainText100Color
    background-image: linear-gradient(-45deg, $mainText100Color 25%, transparent 25%, transparent 50%,$mainText100Color 50%,$mainText100Color 75%, transparent 75%, transparent)
    background-size: 50px 50px

  .doneIcon
    background: $primary100Color

.color-primary
  .bar
    background: $primary100Color

  .doneIcon
    background: $green100Color

.variant-candy
  .bar
    animation: loading .7s infinite linear
    background-image: linear-gradient(-45deg,rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%,rgba(255, 255, 255, .2) 50%,rgba(255, 255, 255, .2) 75%, transparent 75%, transparent)
    background-size: 25px 25px

@keyframes loading
  0%
    background-position: 0 0

  100%
    background-position: 50px 50px
