@import 'src/theme/colors.scss'

@keyframes rotating
  0%
    -ms-transform: rotate(0deg)
    -moz-transform: rotate(0deg)
    -webkit-transform: rotate(0deg)
    -o-transform: rotate(0deg)
    transform: rotate(0deg)

  100%
    -ms-transform: rotate(360deg)
    -moz-transform: rotate(360deg)
    -webkit-transform: rotate(360deg)
    -o-transform: rotate(360deg)
    transform: rotate(360deg)

.barLoader
  display: block
  height: 1.7em
  width: 1.7em
  fill: currentColor

.size-related
  font-size: 1em

.size-small
  font-size: 14px

.size-medium
  font-size: 24px

.size-large
  font-size: 34px

.size-huge
  font-size: 60px

.color-primary
  color: $primary100Color

.fluid
  height: 100%
  margin: auto
