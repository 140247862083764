@import '@root/theme/constants.scss'

.root
  display: flex
  flex-direction: column
  text-align: center
  width: 100%
  height: 100vh
  justify-content: center
  align-items: center
  color: $mainText100Color

.content
  display: flex
  flex-direction: column
  align-items: center

.title
  display: flex
  flex-direction: column
  align-items: center
  animation: reveal 1.2s linear infinite
  font-style: normal
  font-weight: 600
  letter-spacing: -2px
  margin: 0
  gap: 8px

@keyframes reveal
  0%
    opacity: .6
  50%
    opacity: 1
  100%
    opacity: .6
