@import "@root/theme/constants.scss";

.root {
  position: relative;
  color: $mainText50Color;
  min-width: 100px;
  max-width: 80%;
}

.bubble {
  width: 100%;
  border-radius: inherit;
  border-radius: $big-border-radius;
  background: currentColor;
  border: none;
  padding: 12px 16px;
  min-height: 40px;
  font-size: 14px;
  line-height: 27px;
  p {
    margin-block-end: 1em;
  }
}

.arrow {
  position: absolute;
  height: 19px;
  width: 19px;
  bottom: 0;
}

.from-another {
  align-self: flex-start;
  color: $mainText100Color;
  padding-left: 3px;
  .content {
    color: $primary10Color
  }
  .footer {
    color: $mainText50Color;
  }
  .arrow {
    left: 0;
  }
}

.from-me {
  align-self: flex-end;
  color: $primary100Color;
  padding-right: 3px;
  .content {
    color: $mainText100Color 
  }
  .footer {
    color: $text70Color;
  }
  .arrow {
    transform: rotateY(180deg);
    right: 0;
  }
}
