@import 'src/theme/colors.scss'

.root
  display: flex
  min-height: 42px
  border-radius: 50px
  padding: 3px
  border: 1px solid transparent
  background: $inputMainBgColor
  min-width: 100%

  .input
    outline: none
    flex: 1 1 auto
    color: $mainText100Color
    background: none
    border: none
    padding: 0
    padding-inline: 12px
    overflow: hidden
    text-overflow: ellipsis
    border-radius: 5em

  .input::placeholder
    color: $mainText50Color !important

  .withBefore
    padding-left: 0
    border-top-left-radius: 2rem
    border-bottom-left-radius: 2rem

  .withAfter
    padding-right: 0
    border-top-right-radius: 2rem
    border-bottom-right-radius: 2rem

  i
    margin-inline: 10px

.area
  border-radius: 22.5px
  textarea
    resize: none
  .input
    overflow: auto scroll
    margin: 10px 0
    padding-right: 0
    border-radius: 6px

.area:disabled, .input:disabled
  opacity: 0.5

.box
  overflow: hidden

.focused
  border-color: $mainText100Color
  background-color: $inputMainBgFocusedColor

.withError
  border-color: $red100Color
