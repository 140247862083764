@import 'src/theme/constants.scss'

.root
  background: $primaryCardBGColor
  border-radius: $small-border-radius
  padding: 16px

.header
  width: 100%
  padding-bottom: 16px
  border-bottom: 1px solid $text15Color
