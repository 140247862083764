@import 'src/theme/constants.scss'

.root
  display: flex
  flex-direction: column
  width: 100%
  align-items: flex-start
  padding: 30px 60px
  margin: 0 60px
  gap: 30px
  max-width: 1700px
  margin-inline: auto

.body
  display: flex
  flex-direction: column
  flex: 1 1
  width: 100%
  gap: 32px

@media (max-width: $tablet-min-width)
  .root
    padding: 0
