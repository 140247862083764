@import 'src/theme/colors.scss'

.root
  width: 600px
  height: min(900px, calc(100vh - 150px))

.container
  display: flex

.body
  display: flex
  flex-direction: column
  overflow: auto
  flex: 1 1 100%
  gap: 34px
  margin-bottom: 24px

.header
  display: flex
  flex-direction: column
  border-bottom: 1px solid $mainText50Color
  width: 100%

.checkbox
  padding-block: 12px
  padding-inline: 16px

.footer
  padding-top: 24px

.divider
  width: auto
  margin-block: 0
  margin-inline: 16px
  border-top: 1px solid $mainText50Color
