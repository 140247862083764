@import 'src/theme/constants.scss'

.root
  display: flex
  flex-direction: column
  border-radius: $medium-border-radius
  background: $itemSecondaryBgColor
  text-align: center
  text-decoration: none
  padding: 16px
  border: 2px solid $itemPrimaryHoverBgColor
  align-items: stretch
  width: auto
  gap: 16px
  margin: 3px

.root:hover
  background: $itemPrimaryActiveBgColor
  border-color: $itemPrimaryActiveColor

.root:focus-visible, .root:active
  box-shadow: 0px 0px 2px 3px $primary70_20Color
  border-color: $itemPrimaryActiveColor
  outline: none

.root.active
  background: $itemPrimaryActiveBgColor
  border-color: $itemPrimaryActiveColor

.root[aria-disabled="true"]:hover, .root:disabled:hover
  border-color: transparent
  box-shadow: none
  background: $secondaryCardBGColor
  cursor: not-allowed

.root[aria-disabled="true"], .root:disabled
  pointer-events: none
  opacity: .6

.root.clickable
  cursor: pointer

.icon
  height: 28px
  width: 28px
  font-size: 28px
  margin-inline: auto
  color: $primary100Color
