@import 'src/theme/constants.scss'

.root
  display: flex
  align-items: flex-start
  padding: 8px 16px
  border: none
  border-left: 2px solid transparent
  border-radius: $small-border-radius
  cursor: pointer
  background: transparent
  color: $mainText100Color
  outline: none

  &>*:first-child
    flex: 1 1 auto

.root.primary
  &:hover,
  &:focus-visible
    background: $secondary70Color

  &.active
    border-left-color: $primary100Color
    background: $primaryCardBGColor

.root.secondary
  background: $primaryCardL3BGColor

  &:hover,
  &:focus-visible
    background: $itemPrimaryActiveBgColor
    border-left-color: $text20Color

  &.active
    background: $primaryCardBGColor
    border-left-color: $mainText100Color
