@import 'src/theme/colors.scss'
@import 'src/theme/constants.scss'

.root
  display: flex
  padding: 8px 16px
  border-radius: 16px
  background: $primaryCardBGColor
  gap: 8px
  margin-bottom: 16px

.content
  flex: 1
  width: 0%

.header
  display: flex
  justify-content: space-between
  align-items: center
  border-bottom: 1px solid $mainText10Color
  padding-bottom: 8px
  margin: 8px 0px

.headerAction,
.headerTitle
  display: flex
  align-items: center
  gap: 8px
  p
    white-space: nowrap

.expander
  padding: 8px 16px
  border-left: 1px solid $mainText10Color
  cursor: pointer

.documentId
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap
  max-width: 500px

.compact
  .documentId
    max-width: 400px

.webpageUrl
  color: $mainText100Color !important
  text-decoration: none

.contentDocumentLinksContainer
  display: flex
  flex-direction: column
  a
    color: $primary105Color

@media (max-width: $tablet-max-height)
  .root
    padding: 8px
  .documentId
    max-width: 150px
  .headerTitle
    p, div
      font-size: 10px !important
      line-height: 12px !important  
  .expander
    padding: 8px 0px 8px 8px
   
@media (max-width: $mobile-min-width)
  .documentId
    max-width: 80px 

@media (max-width: $mobile-min-height)
  .documentId
    max-width: 50px 
