@import 'src/theme/constants.scss'

.root
  width: 100%
  color: $mainText70Color
  padding: 32px 60px
  height: 100vh

.box
  display: flex
  flex-direction: column
  border-radius: 24px
  background: $primaryCardBGColor
  height: fit-content
  min-height: calc(100vh - 64px)
  height: 100%
  overflow: auto

.hiddenDiv
  position: fixed,
  z-index: -1,
  top: -10000px,
  left: -10000px,
  background: $primaryCardBGColor
  width: 1080px,
  height: fit-content,
  overflow-y: auto,

.header
  padding: 16px 32px
  border-bottom: 1px solid $dividerLineLightColor

.body
  display: flex
  padding-inline: 16px
  flex: 1 1 100%
  background: $primaryCardBGColor
  margin-block: 16px
  overflow: auto

@media (max-width: $tablet-max-height)
  .root
    padding: 0

  .box
    border-radius: 0
    min-height: calc(100vh - 40px)

  .header
    padding: 16px

  .body
    padding: 0
