@import "@root/theme/colors.scss";
@import "@root/theme/mixins.sass";

.root {
  white-space: nowrap;
  padding: 6px;
  background: var(--bg-color);
  border-radius: 6px;
  box-shadow: $elevationL2Shadow;
  border: 1px solid var(--border-color);
  color: var(--text-color);
  position: relative;
  font-size: 14px;
  pointer-events: all;
  min-width: 70px;
}

.root.color-default {
  --bg-color: #{$mainText100Color};
  --border-color: #{$dividerLineLightColor};
  --text-color: #{$contrastText100Color};
}

.root.color-primary {
  --bg-color: #{$primaryTooltipBgColor};
  --border-color: #{$dividerLineLightColor};
  --text-color: #{$mainText100Color};
}