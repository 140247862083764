@import 'src/theme/constants.scss'

.root
  background: $primaryCardL2BGColor
  border-radius: $medium-border-radius

.header
  display: flex
  background: transparent
  border: none
  outline: none
  width: 100%
  padding: 16px
  cursor: pointer

.headerContent
  display: block

.body
  margin-inline: 16px
  padding-bottom: 12px
