@import 'src/theme/colors.scss'
@import 'src/theme/constants.scss'

.linkDivider
  display: flex
  align-items: center
  justify-content: center
  position: relative
  width: auto
  background: linear-gradient(90deg, transparent 0%, $mainText40Color 20%, $mainText40Color 42%, transparent 48%, transparent 52%, $mainText40Color 58%, $mainText40Color 80%, transparent 100%)
  margin-block: 25px

  height: 1px
  &>*
    color: $mainText70Color
    font-size: 2rem
    position: absolute
