@import 'src/theme/constants.scss'

.root
  position: absolute
  bottom: 50%
  left: 0
  right: 0
  width: 100%
  transform: translateY(50%)

  .body
    display: flex
    text-align: center
    padding-inline: 40px
    width: 100%
    justify-content: center
    flex-direction: column
    align-items: center
    gap: 8px
    pointer-events: none
    &>*
      pointer-events: all

  .header
    display: flex
    text-align: center
    flex-direction: column
    position: absolute
    padding-inline: 70px
    bottom: calc(100% + 16px)
    left: 0
    right: 0
    gap: 16px

  .footer
    position: absolute
    padding-inline: 70px
    top: calc(100% + 16px)
    left: 0
    right: 0

.root.withAnimation
  transition: transform, bottom .7s ease-out

.root.down
  bottom: 12px
  transform: translateY(0)
  .body
    padding-inline: 30px

@media (max-width: $mobile-min-width)
  .root.down
    .body
      padding-inline: 20px
