@import 'src/theme/colors.scss'
@import 'src/theme/constants.scss'

.root
  display: flex
  flex-direction: column
  padding: 24px
  border-radius: 8px
  width: 800px
  max-width: 100%
  // background: $primaryContrast10OpacityColor

.body
  display: flex
  flex-direction: column
  overflow: auto

.formContainer
  width: 100%

@media (max-width: $tablet-max-height)
  .rootContainer
    padding: 0

  .container
    padding: 10px
