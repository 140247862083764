@import 'src/theme/constants.scss'
@import 'src/theme/colors.scss'

.root
  display: flex
  flex-direction: column
  overflow: auto
  flex-grow: 1

.after
  display: flex
  gap: 5px
  align-self: center
  margin-right: 10px

.body
  padding: 16px

.categories
  display: grid
  grid-template-columns: repeat(4, 1fr)
  width: 100%
  justify-content: center
  gap: 10px
  flex-wrap: wrap
  button
    width: 100%

.betaContainer
  display: flex
  width: 100%
  gap: 8px
  padding: 8px
  border: 1px solid $primary100Color
  border-radius: 8px

.betaHeadings
  flex: 1
  display: flex
  flex-direction: column
  align-items: flex-start
  h3
    display: flex
    gap: 8px
    text-align: left
    justify-content: center

@media (max-width: $mobile-min-height)
  .categories
    grid-template-columns: repeat(2, 1fr)
