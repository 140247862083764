@import 'src/theme/colors.scss'

.root
  display: flex
  padding: 8px 12px
  border-radius: 12px
  gap: 12px
  align-items: center
  background: $primaryContrast10OpacityColor
  color: $mainText100Color
  cursor: pointer
  border: 1px solid transparent
  text-align: start
  user-select: none

  &:not(:disabled):hover
    border: 1px solid $primary80Color
    background: $primary80_10Color

.body
  flex: 1

.titleIcon
  padding: 8px
  font-size: 24px
  color: $primaryContrast100Color
  background: linear-gradient(177.23deg, #{$primary100Color} -13.49%, #{$primary50Color} 109.75%)
  border-radius: 50%

.runIcon
  border: 1px solid $mainText100Color
  border-radius: 50%
  padding: 4px

.root:disabled
  opacity: 0.7
  cursor: default
