@import 'src/theme/constants.scss'
@import '@root/theme/mixins.sass'

.root
  display: flex
  align-items: center
  flex-direction: column
  align-self: start
  min-height: 100%
  position: fixed
  z-index: 5
  height: 100%
  overflow: auto
  top: 0
  left: 0
  width: 100%
  padding-block: 24px
  padding-inline: 106px 36px
  background: $primaryMainBGColor
  cursor: pointer
  -webkit-tap-highlight-color: transparent
  @include thinScroll

.header
  width: 100%

.body
  margin: 20px 80px
  background-color: $primaryCardBGColor
  border-radius: 16px
  padding: 32px
  max-width: 960px
  width: 100%
  cursor: default

@media (max-width: $tablet-max-height)
  .root
    align-self: auto
    padding: 20px
    padding-block: 80px 40px

  .header
    position: relative
    margin-bottom: 16px

  .body
    margin: 0
    padding: 0
    min-height: 100%
    background: transparent
