@import 'src/theme/constants.scss'

.root
  display: flex
  align-items: center
  font-size: 10px
  gap: 8px

.content
  display: flex
  flex-direction: column
  gap: 4px
