@import 'src/theme/colors.scss'

.root
  padding: 40px
  padding-top: 30px
  width: 570px

.container
  display: flex
  flex-direction: column

.search
  display: flex
  flex-direction: column
  gap: 8px
  padding-top: 16px
  margin-bottom: 16px
  p
    font-weight: bold

.list
  max-height: 400px
  min-height: 400px
  user-select: none

.footer
  margin-top: 16px
  display: flex
  justify-content: center
  gap: 16px
