@import 'src/theme/constants.scss'

.articleContainer
  display: flex
  flex-direction: column
  border-radius: 6px
  background: $primaryCardL2BGColor
  height: fit-content
  overflow: auto
  width: 100%

.alert
  width: 100%
  text-align: center
  color: $green100Color
  background: $green10Color
  padding: 8px 20px
  font-size: 12px

.body
  display: flex
  position: relative
  flex-direction: column
  align-items: center
  overflow: auto
  flex: 1 2 auto
  padding: 24px 30px
  gap: 16px

.threadContainer
  display: flex
  flex-direction: column
  overflow: auto
  width: 100%
  padding-inline: 12px
  padding-top: 15px
  position: relative
  border-top: 1px solid $mainText40Color

.insightForm
  display: flex
  flex-direction: column
  flex: 1 100 100%
  gap: 16px

  .reachTextEditor
    display: flex
    flex-direction: column
    flex: 1 1 100%

.inputPlaceholderCard
  display: flex
  align-items: center
  gap: 12px
  border: none
  border-radius: $medium-border-radius
  background: $inputMainBgColor
  padding: 12px 16px
  cursor: pointer

.inputPlaceholderCard:hover
  background: $inputMainBgFocusedColor
