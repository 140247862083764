@import 'src/theme/colors.scss'

.root
  height: auto
  max-height: auto
  background: $primaryContrast10OpacityColor
  border-radius: 8px
  display: flex
  flex-direction: column
  gap: 16px
  padding: 16px

.status
  display: flex
  align-items: center
  gap: 4px

.expertInfo
  display: flex
  align-items: center
  gap: 12px
