.root
  width: 700px

.form 
  display: flex
  flex-direction: column
  gap: 16px
  
.formInput
  width: 100%
  display: flex
  flex-direction: column
  gap: 12px

.footer
  margin-top: 16px
  display: flex
  justify-content: center
  gap: 16px
  width: 100%
  button
    min-width: 120px