@import "@root/theme/mixins.sass"

$gutterSizes: 0 , 8 , 16 , 24 , 32

.root
  display: flex
  flex-direction: column
  align-items: stretch
  width: fit-content
  min-width: 100%
  flex: 1 1 100%
  overflow-y: auto
  @include thinScroll()

.header,
.footer
  display: flex
  flex-wrap: wrap
  flex: 1 1 auto
  align-items: center
  height: fit-content

.body
  display: flex
  flex-direction: column
  min-width: 100%
  flex: 1 1 100%
  width: 100%
  overflow-y: auto

@each $gutter in $gutterSizes
  .gutter-#{$gutter}
    gap: #{$gutter}px
