@import 'src/theme/constants.scss'

.root
  display: flex
  flex-direction: column
  justify-content: flex-start
  padding: 40px 60px
  width: 100%
  flex: 1 1 100%
  max-height: calc(100vh - 20px)

  h2
    text-align: center
    margin-bottom: 20px

  h3
    text-align: center
    text-transform: uppercase
    color: $mainText50Color

.container
  display: flex
  flex-direction: column
  background: $primaryCardBGColor
  border-radius: 6px
  padding: 40px 12px
  flex: 1 1 auto
  width: 100%
  max-width: 860px
  margin: 0 auto
  overflow: auto

.list
  padding: 0 25px
  gap: 30px

.loader
  margin: 10px auto

@media (max-width: $tablet-min-width)
  .root
    max-height: calc(100vh - 40px)
    padding: 0 30px

@media (max-width: $tablet-min-height)
  .root
    padding: 0

  .list
    padding: 10px
