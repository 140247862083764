@import 'src/theme/colors.scss'
    
.checkboxWrapper
  display: flex
  justify-content: space-between
  align-items: center
  padding-left: 8px
  button
    font-size: 16px

.checkboxFilterName
  white-space: nowrap
  font-size: 12px
  background: transparent
  padding: 4px
  border-radius: 4px
  padding-left: 0px

.name
  display: flex

.root
  display: flex
  flex-direction: column
  padding: 8px
  border-radius: 8px
  background: $primaryContrast10OpacityColor
  width: auto
  height: 100%
  width: 100%

.companiesList
  overflow-y: scroll
  flex: 1 1 auto
  gap: 8px
  display: flex
  flex-direction: column

.companyHeader
  border-bottom: 1px solid $mainText50Color
  color: $mainText50Color
  margin-bottom: 8px
  padding: 8px 12px

