
.root
  width: 850px

.inputsContainer
  display: flex
  flex-direction: column
  gap: 16px
  margin-bottom: 32px
  min-height: 500px

.inputWrapper
  display: flex
  flex-direction: column
  gap: 8px
  width: 100%

.footer
  display: flex
  justify-content: center
  gap: 16px
  width: 100%
  margin-bottom: 12px
  button
    min-width: 120px