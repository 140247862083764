@import 'src/theme/colors.scss'

.root
    display: flex
    flex-direction: column
    overflow: auto
    flex-grow: 1

.after
    display: flex
    gap: 5px
    align-self: center
    margin-right: 10px

.header
    margin: 0 auto
