@import 'src/theme/colors.scss'

.root
    display: flex
    margin: 0 auto
    font-size: 24px

    .star
        padding: 0 0.15em

    .candidate
        color: $gold80Color
