@import '@root/theme/colors.scss'
@import '@root/theme/mixins.sass'

.root
  --table-border: 1px solid transparent
  font-size: 14px
  color: $mainText100Color
  // border-radius: 6px
  border: 1px solid transparent
  width: 100%
  overflow: auto
  flex: 1 1 auto

.container
  overflow: auto
  width: 100%
  padding-bottom: 5px
  table
    width: 100%
    padding: .215px
    border-spacing: 0

  td, th
    padding: .715em
    vertical-align: top
    border: var(--table-border, none)
    font-weight: normal
    font-size: 1em
    line-height: 17px
    white-space: normal
    overflow-wrap: break-word

  td:first-child, th:first-child
    border-left: none

  td:last-child, th:last-child
    border-right: none

  tr:last-child
    td, th
      border-bottom: none

  th
    text-align: start
    padding-top: 0
    padding-bottom: 7px !important
    border: var(--table-border, none)
    text-transform: uppercase
    font-weight: 500
    font-size: 0.85em
    line-height: 1.01em
    color: $tableHeaderColor
    width: 20%

.layout-fixed table
  table-layout: fixed

.layout-auto table
  table-layout: auto

.withBorder
  --table-border: 1px solid #{$dividerLineLightColor}

.column
  color: $mainText50Color
  p
    margin-bottom: 5px

.container::-webkit-scrollbar
  width: 12px
  height: 12px

.container::-webkit-scrollbar-thumb
  background: $mainText100Color
  height: 4px
  border: 4px solid transparent
  border-radius: 6px
  background-clip: content-box
