@import "@root/theme/colors.scss"

.axis
  overflow: hidden !important
  outline: none

.cell
  width: fit-content
  height: fit-content
  max-width: fit-content
  max-height: fit-content
  user-select: none
