.body
	min-height: 650px
	display: flex
	align-items: flex-start
	justify-content: center
	.notFound
		align-self: center

.pagination
	padding-block: 10px

.searchInput
	min-width: 250px
