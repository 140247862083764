@import 'src/theme/colors.scss'

.row
  td, th
    padding-block: 3px

.nameColumn
  padding-inline: 5px !important

.valueColumn
  color: $mainText100Color
