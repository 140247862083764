/* Flex Container */
.flex {
  display: flex;
}

/* Flex Directions */
.flex.row {
  flex-direction: row;
}
.flex.row-reverse {
  flex-direction: row-reverse;
}
.flex.column {
  flex-direction: column;
}
.flex.column-reverse {
  flex-direction: column-reverse;
}

/* Justify Content */
.flex.justify-start {
  justify-content: flex-start;
}
.flex.justify-end {
  justify-content: flex-end;
}
.flex.justify-center {
  justify-content: center;
}
.flex.justify-between {
  justify-content: space-between;
}
.flex.justify-around {
  justify-content: space-around;
}
.flex.justify-evenly {
  justify-content: space-evenly;
}

/* Align Items */
.flex.items-start {
  align-items: flex-start;
}
.flex.items-end {
  align-items: flex-end;
}
.flex.items-center {
  align-items: center;
}
.flex.items-stretch {
  align-items: stretch;
}
.flex.items-baseline {
  align-items: baseline;
}

/* Align Content */
.flex.content-start {
  align-content: flex-start;
}
.flex.content-end {
  align-content: flex-end;
}
.flex.content-center {
  align-content: center;
}
.flex.content-space-between {
  align-content: space-between;
}
.flex.content-space-around {
  align-content: space-around;
}
.flex.content-stretch {
  align-content: stretch;
}

/* Align Self */
.flex.self-start {
  align-self: flex-start;
}
.flex.self-end {
  align-self: flex-end;
}
.flex.self-center {
  align-self: center;
}
.flex.self-stretch {
  align-self: stretch;
}
.flex.self-baseline {
  align-self: baseline;
}

/* Flex Wrap */
.flex.wrap {
  flex-wrap: wrap;
}
.flex.nowrap {
  flex-wrap: nowrap;
}
.flex.wrap-reverse {
  flex-wrap: wrap-reverse;
}

/* Flex Grow and Shrink */
.flex.grow {
  flex-grow: 1;
}
.flex.shrink {
  flex-shrink: 1;
}
.flex.no-grow {
  flex-grow: 0;
}
.flex.no-shrink {
  flex-shrink: 0;
}
