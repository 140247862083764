@import 'src/theme/colors.scss'

.root
  display: flex
  flex-direction: column

.header
  display: flex
  justify-content: space-between
  align-items: center
  padding-top: 14px
  margin-bottom: 12px
  border-top: 1px solid $dividerLineLightColor

.title
  display: flex
  gap: 5px
  margin: 0

.body
  display: flex
  justify-content: space-between

.issuesList
  display: flex
  flex-direction: column
  padding-left: 17px
  gap: 8px
  margin: 0

  li
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 24px

  li::marker
    color: $red100Color

.uptime
  color: $mainText50Color

.issue
  color: $mainText50Color
