@import 'src/theme/colors.scss'

.root
  display: grid
  grid-template-columns: 1fr
  gap: 30px
  width: 100%

.form
  display: grid
  grid-template-columns: 1fr
  gap: 20px
  width: 100%
  margin: 0 auto

.after
  display: flex
  gap: 10px
  margin-right: 10px
