@import 'src/theme/colors.scss'
@import 'src/theme/constants.scss'
@import '@root/theme/mixins.sass'

.root
  display: flex
  padding: 16px 8px
  position: relative
  user-select: none
  background: transparent
  border: none
  outline: none
  gap: 16px
  flex-direction: column

.header
  display: flex
  width: 100%
  justify-content: space-between
  span
    display: flex
    gap: 12px
    align-items: center

.container
  display: flex
  flex-direction: column
  justify-content: space-between
  width: 100%

.info
  display: flex
  gap: 4px
  align-items: center
  span
    color: $mainText70Color
    text-wrap: nowrap

.userAvatar
  display: flex
  align-items: center
  gap: 4px
  color: $mainText100Color

.footer
  display: flex
  justify-content: space-between
  width: 100%

.errorMessage
  border-radius: 4px
  background: $red10Color
  padding: 4px
  margin-top: 4px

@media (max-width: $tablet-min-height)
  .header
    div
      display: none

  .root
    gap: 8px

