@import 'src/theme/constants.scss'
@import 'src/theme/colors.scss'

.root
  display: flex
  justify-content: space-between
  border-radius: 8px
  border: 1px solid $primary80Color
  padding: 16px
  margin-bottom: 16px

.info
  flex: 1
  display: flex
  gap: 16px
  align-items: center
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out

.headings
  flex: 1
  display: flex
  flex-direction: column
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out

.infoIcon
  font-size: 38px

.iconWrapper
  height: 36px
  width: 36px
  border-radius: 50%
  background: linear-gradient(90deg, #00C0FF 0%, #5558FF 100%)
  padding: 8px
  display: flex
  justify-content: center
  align-items: center
  i
    font-size: 24px
    color: $text100Color

.statusInfos
  display: flex
  align-items: center
  gap: 16px
  margin-top: 16px
  span
    display: flex
    align-items: center
    gap: 8px

