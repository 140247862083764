@import 'src/theme/colors.scss'
@import '@root/theme/mixins.sass'

.root
  border-radius: 16px
  padding: 8px 0px
  overflow: hidden
  height: 350px
  background: $primaryContrast10OpacityColor

.list
  display: flex
  flex-direction: column
  overflow-y: auto
  padding: 0px 8px
  max-height: 100%
  gap: 4px
