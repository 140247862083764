@import 'src/theme/constants.scss'

.root
  display: flex
  justify-content: center
  align-items: center
  text-align: center
  flex-direction: column
  padding-bottom: 20px
  min-width: 400px
  gap: 20px

.doneIcon
  height: 100px
  width: 100px

@media (max-width: $tablet-min-height)
  .root
    min-width: 100%
