@import 'src/theme/colors.scss'

@keyframes rotating
  0%
    -ms-transform: rotate(0deg)
    -moz-transform: rotate(0deg)
    -webkit-transform: rotate(0deg)
    -o-transform: rotate(0deg)
    transform: rotate(0deg)

  100%
    -ms-transform: rotate(-360deg)
    -moz-transform: rotate(-360deg)
    -webkit-transform: rotate(-360deg)
    -o-transform: rotate(-360deg)
    transform: rotate(-360deg)

.root
  position: relative
  height: min-content
  width: min-content

.loader
  display: block
  pointer-events: none
  padding: 4px
  font-size: 13px
  border-radius: 50%
  color: white
  background: linear-gradient(180deg, #FFAD9E 0%, #FE6283 100%)
  i
    animation: rotating 1s ease-in-out infinite

.notification
  pointer-events: none
  background: $green100Color
  color: $mainText100Color
  font-style: normal
  font-weight: 500
  font-size: 9px
  line-height: 10px
  text-align: center
  padding: 3px
  border-radius: 6px
