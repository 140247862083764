@import 'src/theme/constants.scss'

.root
  display: grid
  grid-template-columns: 1fr
  grid-auto-rows: auto
  gap: 24px
  align-items: stretch
  width: 100%

.root h2
  font-size: 32px
  line-height: 42px
  font-weight: bold
  text-align: center
  margin: 0

.inputLink,
.inputLink:visited
  align-self: center
  color: $mainText100Color
  font-size: 12px

.after
  display: flex
  gap: 10px
  margin-left: 10px
  margin-right: 15px

@media (max-width: $tablet-min-height)
  .root h2
    font-size: 24px
    line-height: 28px
