@import 'src/theme/constants.scss'

.root
  display: flex
  flex-direction: column
  width: 100%
  max-width: 1700px
  align-items: stretch
  margin-inline: auto

.header
  margin-bottom: 45px

.body
  min-height: 620px

.footer
  padding-block: 20px

.switchContainer
  width: fit-content
  margin-bottom: 10px

@media (max-width: $tablet-max-height)
  .backButton
    font-size: 18px

@media (max-width: $mobile-min-width)
  .inviteButton
    width: 100%
