@import 'src/theme/constants.scss'

.root
  display: flex
  flex-direction: column
  border-radius: 6px
  background: $primaryCardBGColor
  text-align: center
  text-decoration: none
  padding: 16px
  border: 1px solid transparent
  transition: border-color .3s, background .2s ease-out
  box-shadow: $elevationL1Shadow
  user-select: none
  .icon
    transition: transform .3s ease-out

.header
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  width: 100%

.title
  display: grid
  grid-template-columns: 1fr auto 1fr
  width: 100%

  .icon
    padding: 10px

  .before
    display: flex
    justify-content: flex-start
    align-items: flex-start

  .after
    display: flex
    justify-content: flex-end
    align-items: flex-start

.description
  color: $mainText50Color
  font-size: 12px
  line-height: 25px
  padding-block: 8px

.root:hover, .root:focus
  border-color: $itemPrimaryActiveColor
  box-shadow: 0px 0px 2px 3px $primary70_20Color
  background: $itemPrimaryActiveBgColor
  outline: none
  .icon
    filter: drop-shadow(0 0 8px $primary50Color)
    transform: scale(1.07) translateY(-3px)

.root[aria-disabled="true"]:hover, .root:disabled:hover
  border-color: transparent
  box-shadow: none
  background: $secondaryCardBGColor
  cursor: not-allowed

.root[aria-disabled="true"], .root:disabled
  pointer-events: none
  opacity: .6
