@import 'src/theme/constants.scss'

.root
  display: flex
  align-items: stretch
  position: relative
  width: 100%
  min-height: 165px
  border-radius: 6px
  background: $primaryCardBGColor
  padding-inline: 20px
  user-select: none
  height: -webkit-fill-available
  box-shadow: $elevationL1Shadow

.clickable
  cursor: pointer

// CardBox
.menu
  position: absolute
  right: 20px
  top: 16px

.section
  position: relative
  width: 100%
  padding-block: 20px
  flex: 1 1 auto

.section:first-child
  flex-basis: 100%

.section:not(:last-child)
  border-bottom: 1px solid $dividerLineLightColor

// ContextMenu
.menuContainer
  padding: 0 20px
  min-width: 150px
  ul
    padding: 0
    margin: 0

  li
    display: flex
    text-align: start
    line-height: 50px
    text-decoration: none
    gap: 8px
    cursor: pointer
    user-select: none
    i
      font-size: 18px

  li:last-child
    color: $red100Color

  li:not(:last-child)
    border-bottom: 1px solid $dividerLineLightColor

  li:hover
    opacity: .7

.menuItem
  display: flex
  padding: 7px 10px
  text-decoration: none
  color: $red100Color
  i
    font-size: 18px

// BusinessCard
.cardContainer
  flex: 1 1 100%
  .status
    margin-bottom: 10px
  p
    font-weight: normal
    line-height: 22px

.title
  overflow: hidden
  text-overflow: ellipsis
  -webkit-line-clamp: 2
  display: -webkit-box
  -webkit-box-orient: vertical
  margin-bottom: 4px
  margin-right: 40px

.description
  overflow-wrap: break-word

.body
  display: flex
  gap: 16px
  &>*
    flex-shrink: 0

.inline
  align-items: center

.content
  flex: 1 1 auto
  overflow: initial

// UserRow 
.userRow
  display: flex
  justify-content: space-between
  align-items: center
  width: 100%

// ChipList
.list
  display: flex
  flex-wrap: wrap
  gap: 8px
