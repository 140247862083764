@import 'src/theme/colors.scss'

.link
  border: none
  background: transparent
  width: 100%
  cursor: pointer
  text-decoration: none
  display: flex
  gap: 10px
  padding: 6px 10px
  font-weight: 500
  &:not(:last-child)
    border-bottom: 1px solid #00000010

.link:hover
  color: $primary105Color

.color-dark
  color: $contrastText100Color

.color-light
  color: $mainText100Color

.color-primary
  color: $primary100Color

.color-secondary
  color: $mainText50Color

.color-red
  color: red

.profileWrapper
  display: flex
  justify-content: space-between
  align-items: center
  width: 100%

.profileBtnWrapper
  display: flex

.label
  padding: 0px
  color: $text70Color
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

.themeButtonWrapper
.profileInfo
  display: flex
  align-items: center
  gap: 10px
  color: $text100Color
  cursor: pointer
  margin-left: 4px
  a:visited
    color: $text100Color
  h3
    color: $text100Color

.logoutBtnBig
  font-size: 18px
  color: $text100Color

.avatar
  margin-left: 5px
