@import 'src/theme/constants.scss'

.root
  border-radius: $small-border-radius
  border: 1px solid $dividerLineLightColor
  padding: 10px

.root.active
  border-color: $primary80Color
.root:hover
  border-color: $primary80Color
