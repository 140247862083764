@import 'src/theme/constants.scss'

.root
  min-width: 550px

.container
  display: flex
  flex-direction: column
  align-items: center
  gap: 32px
  margin-bottom: 48px

.input
  width: 100%
  display: flex
  flex-direction: column
  gap: 8px

.solutionsContainer
  display: flex
  gap: 16px
  justify-content: space-between
  max-height: 150px
  overflow-y: scroll

.solutionList
  display: flex
  flex-direction: column
  gap: 8px

.divider
  width: 100%
  height: 0
  border: none
  margin: 0
  border-top: 1px solid $mainText20Color

.checkbox
  font-size: 14px

.after
  display: flex
  gap: 10px
  margin-right: 10px

.loader
  align-self: center

.error
  color: $red100Color
  font-size: 12px
