@import 'src/theme/colors.scss'

@keyframes carette-blank
  0%
    opacity: 0
  50%
    opacity: 1
  100%
    opacity: 0

.root
  position: relative
  display: flex
  min-height: 42px
  width: fit-content
  padding: 3px
  border: 1px solid transparent
  color: $mainText100Color

  .input
    position: absolute
    user-select: none
    top: 0
    bottom: 0
    left: 0
    right: 0
    width: 0
    height: 0
    outline: none
    background: none
    border: none
    color: transparent

.input
  border-radius: 50em

.cell
  font-size: 1em
  text-align: center
  border-radius: 6px
  background: $primaryCardBGColor
  padding: .5em
  line-height: 1em
  height: 1em
  width: 1em
  cursor: pointer
  user-select: none
  position: relative
  border: 1px solid transparent
  box-sizing: content-box
  margin: 5px
  &:after
    position: absolute
    content: ''
    bottom: .4em
    left: 0
    margin: 0 .5em
    width: 1em
    height: 2px
    background: $mainText100Color
    opacity: 0

.active
  border-color: $primary100Color
  &::after
    animation: carette-blank .7s ease-in-out infinite

.withError
  .cell
    border-color: $red100Color
