@import 'src/theme/constants.scss'

.root
  display: flex
  gap: 30px
  width: 100%
  flex-wrap: wrap
  &>*
    min-width: 250px
    flex: 1 1 250px

.card
  display: flex
  flex-direction: column
  min-width: calc(50% - 15px)
  margin-top: 3px
  gap: 8px

.infoCard
  background: $primaryCardL2BGColor
  margin: 0
  padding: 10px 15px
  border-radius: 6px
  font-size: 14px

.label
  display: flex
  flex-wrap: wrap
  gap: 10px
  margin-top: 14px

@media (max-width: $mobile-min-width)
  .root
    gap: 20px
    &>*
      min-width: 100%
