@import "@root/theme/colors.scss"

.root
  display: flex
  margin-bottom: 10px
  flex-direction: column
  align-items: stretch
  overflow: hidden auto
  width: 100%
  gap: 16px

  hr
    border: 0
    border-top: 1px solid $dividerLineLightColor
    margin-bottom: 12px

.container
  display: flex
  flex-direction: column
  border: 1px solid transparent
  overflow: hidden auto
  flex: 1

  &.noneSelectable
    user-select: none

  &.focused
    border-color: $mainText100Color

.editor
  flex: 1
  outline: none
  margin-block: 8px
  overflow: hidden auto

.root.withError
  .container
    border-color: $red100Color

.root.editable
  .container
    padding: 12px 20px
    border-radius: 24px
    background: var(--input-main-bg-color)
