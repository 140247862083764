@import 'src/theme/constants.scss'

@keyframes blur-down
  0%
    opacity: 1
    visibility: visible
  100%
    opacity: 0
    visibility: hidden

@keyframes blur-up
  0%
    opacity: 0
    visibility: visible
  100%
    opacity: 1
    visibility: hidden

.root
  display: flex
  flex-direction: column
  align-items: center
  padding-block: 60px
  overflow: overlay
