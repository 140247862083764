@import "@root/theme/constants.scss"
@import "@root/theme/mixins.sass"

.root
  display: flex
  flex-direction: column
  height: auto
  width: 100%
  outline: none

.body
  display: grid
  grid-template-columns: 40px var(--static-body-width, 0px) 1fr auto
  grid-template-rows: 40px var(--static-body-height, 0px) 1fr auto
  width: 100%
  flex: 1
  overflow: hidden

.grid
  outline: none

.selectedRange
  pointer-events: none
  position: absolute
  background: $primary70_20Color
  border-width: 1px
  border-style: solid
  border-color: $primary105Color
  z-index: 10

  .knob
    position: absolute
    background: $primary105Color
    right: 0
    bottom: 0
    width: 10px
    height: 10px
    transform: translate(1px, 1px)
    border-top-left-radius: 3px
    cursor: crosshair
    z-index: 1
    pointer-events: all
    transition: width .3s, height .3s

  .knob:hover
    height: 14px
    width: 14px

.selected
  pointer-events: none
  position: absolute
  outline: none
  border-width: 2px
  border-style: solid
  border-color: $primary105Color
  border-radius: 2px
  z-index: 2

.gridShard
  position: relative

  &.staticHeader
    grid-row: 1 / 2
    grid-column: 2 / 3
    border-width: 0 var(--divider-border-width, 0) 0 0

  &.dynamicHeader
    grid-row: 1 / 2
    grid-column: 3 / 4

  &.staticSidebar
    grid-row: 2 / 3
    grid-column: 1 / 2
    border-width: 0 0 var(--divider-border-width, 0) 0

  &.dynamicSidebar
    grid-row: 3 / 4
    grid-column: 1 / 2

  &.bodyTop
    grid-row: 2 / 3
    grid-column: 3 / 4
    border-width: 0 0 var(--divider-border-width, 0) 0

  &.bodyLeft
    grid-row: 3 / 4
    grid-column: 2 / 3
    border-width: 0 var(--divider-border-width, 0) 0 0

  &.staticIntersection
    grid-row: 2 / 3
    grid-column: 2 / 3
    border-width: 0 var(--divider-border-width, 0) var(--divider-border-width, 0) 0

  &.dynamicIntersection
    grid-row: 3 / 5
    grid-column: 3 / 5

  &.headerBumper
    grid-row: 1 / 3
    grid-column: 4 / 5

  &.sidebarBumper
    grid-row: 4 / 5
    grid-column: 1 / 3

  &.staticHeader,
  &.staticSidebar,
  &.staticIntersection,
  &.bodyTop,
  &.bodyLeft
    border-style: solid
    border-color: $secondary50Color

.hiddenScroll
  overflow: hidden !important
  scrollbar-width: none

@media (max-width: $tablet-max-height)
  .root
    @include thinScroll()
