@import 'src/theme/colors.scss'

.root
  border: none
  background: transparent
  padding: var(--btn-padding, 7px)
  border-radius: 6px
  cursor: pointer
  color: $mainText100Color
  user-select: none
  width: max-content

  &.active,
  &:hover
    background: #ffffff14
    color: $primary100Color

  &:disabled,
  &:disabled:hover
    pointer-events: none
    color: $mainText50Color
    background: transparent
    opacity: .7

  &.rounded
    border-radius: 50%
