.big
  --btn-padding: 7px
  font-size: 22px

.medium
  --btn-padding: 7px
  font-size: 18px

.small
  --btn-padding: 7px
  font-size: 14px
