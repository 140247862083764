@import 'src/theme/colors.scss'

.root
  display: flex
  flex-direction: column
  align-items: flex-start
  gap: 20px
  min-width: 1200px

.row
  color: $mainText100Color

  .boxMargin
    margin: auto
    margin-top: 10px

  .fullHeight
    height: 100%
