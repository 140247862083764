@import 'src/theme/constants.scss'

.root
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  text-align: center
  user-select: none
  padding: 30px
  min-width: 250px
  height: 203px
  border-radius: $medium-border-radius
  border: 1px dashed $mainText100Color
  background: $inputMainBgColor
  cursor: pointer
  margin: 3px
  position: relative

  &.disabled
    cursor: default

  &.active, &:focus
    outline: none
    border-color: $primary100Color
    box-shadow: 0px 0px 0px 3px $primary70_20Color

.title
  display: flex
  flex-direction: column
  align-items: center
  line-height: 33px

  .description
    margin-top: 16px

  .icon
    margin: 8px auto

.illustration
  position: absolute
  border-radius: $medium-border-radius
  inset: 0
  width: 100%
  height: 100%
  object-fit: cover

.withError
  border-color: $red100Color
