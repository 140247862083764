@import 'src/theme/constants.scss'

.root
  display: flex
  flex-direction: column
  align-items: stretch
  padding-bottom: 90px
  width: 100%
  padding-inline: 60px

.header
  margin-top: 40px
  margin-bottom: 24px

.body
  display: flex
  flex: 1
  width: 100%
  overflow: hidden

@media (max-width: $tablet-max-height)
  .root
    padding-inline: 20px
    padding-bottom: 20px

  .header
    margin-top: 0px
    margin-bottom: 12px
