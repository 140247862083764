.body
    min-height: 750px
    display: flex
    align-items: flex-start
    justify-content: center
    .notFound
        align-self: center

.pagination
    padding-block: 10px
