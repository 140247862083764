@import 'src/theme/colors.scss'
@import 'src/theme/constants.scss'

.root
  padding: 40px
  padding-top: 30px
  max-height: clamp(500px, calc(100vh - 150px), 720px)
  width: 570px

.container
  display: flex
  justify-content: center
  align-items: stretch
  text-align: center
  flex-direction: column
  overflow: auto
  gap: 30px

@media (max-width: $tablet-min-height)
  .root
    max-width: 100%
