
@import 'src/theme/colors.scss'
@import 'src/theme/constants.scss'
@import '@root/theme/mixins.sass'

.root
  display: flex
  width: 100%
  max-width: 100%
  align-items: center
  gap: 8px
  padding: 8px
  margin-bottom: 8px

.title
  width: 40%
  color: $mainText50Color
  display: flex
  justify-content: space-between

.info
  display: flex
  align-items: center
  gap: 8px
  width: 100%
  overflow: scroll
  @include thinScroll('&', 2px)
  i
    font-size: 24px
  button,
  img
    height: 24px
    min-height: 24px
    min-width: 24px
    width: 24px
  div
    padding: 0px !important

.striped
  background-color: $primaryContrast100_03Color

@media (max-width: $tablet-max-height)
  .title
    flex: 1 1 40%
    width: auto

  .root
    p, div
      font-size: 10px !important
      line-height: 12px !important

  .info
    i
      font-size: 16px
    img, button
      height: 16px
      min-height: 16px
      min-width: 16px
      width: 16px
