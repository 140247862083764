@import 'src/theme/colors.scss'

.root
  display: flex
  flex-direction: column
  align-items: center
  width: 100%
  gap: 12px

.chips
  display: flex
  gap: 8px
