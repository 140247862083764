@import 'src/theme/constants.scss'

.root
  display: grid
  grid-template-rows: 0fr
  transition: grid-template-rows 300ms
  width: 100%

.container
  overflow: hidden

.opened
  grid-template-rows: 1fr
