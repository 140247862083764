@import 'src/theme/colors.scss'

.root
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  gap: 30px
  max-width: 490px
  width: 100%

.form
  display: flex
  flex-direction: column
  gap: 20px
  width: 100%
  margin: 0 auto

.after
  display: flex
  gap: 10px
  margin-right: 10px
