@import 'src/theme/constants.scss'
@import '@root/theme/mixins.sass'

.root
  display: flex
  position: relative
  gap: 4px
  width: 100%
  height: 100%
  background-color: $primaryCardL3BGColor
  padding: 16px 0
  align-items: stretch
  border-radius: $medium-border-radius

.previewList
  display: flex
  height: 100%
  width: auto
  gap: 12px
  overflow: scroll
  padding-inline: 14px 2px
  margin-inline: 2px
  @include thinScroll('&')

.scrollButton
  padding: 12px
  width: auto
  height: auto

.root.vertical
  flex-direction: column
  .previewList
    flex-direction: column

.root.horizontal
  flex-direction: row
  .previewList
    flex-direction: row
