
.root
  max-width: 550px

.alertIcon
  font-size: 24px

.warning
  display: flex
  gap: 8px
  width: 100%

.container
  display: flex
  flex-direction: column
  align-items: center
  gap: 16px
  margin-bottom: 48px
