@import "src/theme/colors.scss";

.root {
  --tooltip-padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: fit-content;
}

.root.justify-start {
  justify-content: flex-start;
}
.root.justify-center {
  justify-content: center;
}
.root.justify-end {
  justify-content: flex-end;
}

.wrapper {
  z-index: 1;
  position: absolute;
  opacity: 1;
  transform: scale(1);
  transition: all 0.4s ease-out;
  animation-name: fadeIn;
  animation-iteration-count: 1;
  animation-duration: 0.4s;
  --bg-color: #{$mainText100Color};
  --border-color: #{$dividerLineLightColor};
  --text-color: #{$mainText100Color};
}

.body {
  white-space: nowrap;
  padding: 6px;
  background: var(--bg-color);
  border-radius: 6px;
  box-shadow: $elevationL2Shadow;
  border: 1px solid var(--border-color);
  color: var(--text-color);
  position: relative;
  font-size: 14px;
  pointer-events: all;
  min-width: 70px;
}

.tip {
  position: absolute;
  overflow: hidden;
  text-align: initial;
  &::after {
    content: "";
    position: absolute;
    height: 15px;
    width: 15px;
    background: var(--bg-color);
    border: 1px solid var(--border-color);
  }
}

.body.color-default {
  --bg-color: #{$mainText100Color};
  --border-color: #{$dividerLineLightColor};
  --text-color: #{$contrastText100Color};
}

.body.color-primary {
  --bg-color: #{$primaryTooltipBgColor};
  --border-color: #{$dividerLineLightColor};
  --text-color: #{$mainText100Color};
}

:is(.position-top, .position-bottom) {
  .tip {
    height: 15px;
    width: 35px;
    transform: translateX(-50%);
  }
  .top::after {
    left: 50%;
  }
  .alignment-start .tip {
    left: 20px;
  }
  .alignment-center .tip {
    left: 50%;
  }
  .alignment-end .tip {
    left: calc(100% - 20px);
  }
}

:is(.position-left, .position-right) {
  .tip {
    height: 35px;
    width: 15px;
    transform: translateY(-50%);
  }
  .tip::after {
    top: 50%;
  }
  .alignment-start .tip {
    top: 20px;
  }
  .alignment-center .tip {
    top: 50%;
  }
  .alignment-end .tip {
    top: calc(100% - 20px);
  }
}

$tooltipTotalIndent: calc(12px + var(--tooltip-padding));

.position-top {
  padding-bottom: $tooltipTotalIndent;
  bottom: calc(100%);
  .tip {
    top: 100%;
    &::after {
      top: 0;
      transform: translateX(50%) translateY(-50%) rotate(45deg);
    }
  }
}

.position-right {
  padding-left: $tooltipTotalIndent;
  left: calc(100%);
  .tip {
    right: 100%;
    &::after {
      right: 0;
      transform: translateX(50%) translateY(-50%) rotate(45deg);
    }
  }
}

.position-bottom {
  padding-top: $tooltipTotalIndent;
  top: calc(100%);
  .tip {
    bottom: 100%;
    &::after {
      bottom: 0;
      transform: translateX(50%) translateY(50%) rotate(45deg);
    }
  }
}

.position-left {
  padding-right: $tooltipTotalIndent;
  right: calc(100%);
  .tip {
    left: 100%;
    &::after {
      left: 0;
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
    }
  }
}
