@import 'src/theme/colors.scss'
.root
    position: relative
    display: flex
    justify-content: center
    margin-inline: auto
    margin-block: 10px

    .icon
        height: 130px
        width: 130px
        font-size: 28px

    .plus
        position: absolute
        right: 0
        top: 0
        display: flex
        justify-content: center
        align-items: center
        background-color: $primaryContrast100Color
        box-shadow: $elevationL1Shadow
        border-radius: 50%
        height: 40px
        width: 40px
        cursor: pointer
        border: 1px solid transparent

        &:hover
            border-color: $primary100Color
