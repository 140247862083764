
@import 'src/theme/constants.scss'

.root
	width: 100%
	.error
		border: 1px solid $red100Color
	ul
		list-style-type: none
		padding-left: 20px
		margin-block: 4px
		li
			position: relative
			&::before
				position: absolute
				content: '•'
				left: -15px
			&.done
				color: $green100Color
				&::before
					content: '✓'
