@import 'src/theme/constants.scss'

.footer
  display: flex
  justify-content: right
  width: 100%
  gap: 8px
  button
    width: 130px
    font-size: 12px

@media (max-width: $tablet-max-height)
  .footer
    justify-content: space-between
    button
      flex: 1 1 auto
