@import './Button-root.module'

.primary:hover
  --main-color: #{$primary80Color}
  --hover-bg-color: #{$primary80_10Color}
  --text-color: #{$primaryContrast100Color}

.primary, .primary:disabled
  --main-color: #{$primary100Color}
  --text-color: #{$primaryContrast100Color}

.secondary:hover
  --main-color: #{$secondaryButtonBgFocusedColor}
  --hover-bg-color: #{$primary80_10Color}
  --text-color: #{$mainText50Color}

.secondary, .secondary:disabled
  --main-color: #{$secondaryButtonBgColor}
  --text-color: #{$mainText100Color}

.default:hover
  --main-color: #{$defaultButtonBgFocusedColor}
  --hover-bg-color: #ffffff0f
  --text-color: #{$contrastText100Color}

.default, .default:disabled
  --main-color: #{$defaultButtonBgColor}
  --text-color: #{$contrastText100Color}

.red:hover
  --main-color: #{$red80Color}
  --hover-bg-color: $red05Color

.red
  --main-color: #{$red100Color}
  --text-color: #{$primaryContrast100Color}

.gold:hover
  --main-color: #{$gold80Color}
  --hover-bg-color: $gold05Color

.gold
  --main-color: #{$gold100Color}
  --text-color: #{$primaryContrast100Color}

.green:hover
  --main-color: #{$green80Color}
  --hover-bg-color: $green05Color

.green
  --main-color: #{$green100Color}
  --text-color: #{$primaryContrast100Color}
