@import 'src/theme/colors.scss'

.root
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  min-height: 100vh
  height: auto
  gap: 30px
  margin: 0 auto

  h1
    font-size: 32px
    text-align: center
    margin-bottom: 20px
  h4
    color: $mainText50Color
    text-align: center
    margin-bottom: 0

.container
  display: grid
  grid-template-columns: 1fr
  gap: 30px
  padding: 20px

.form
  display: grid
  grid-template-columns: 1fr
  gap: 20px
  max-width: 490px
  width: 100%
  margin: 0 auto
