@import 'src/theme/colors.scss'

$animationDuration: .3s

.root
  position: fixed
  overflow: hidden
  width: 100vw
  height: 100vh
  top: 0
  bottom: 0
  left: 0
  right: 0
  animation: appearContainer $animationDuration ease-out

.sidebar
  position: absolute
  background: $primaryCardBGColor
  transition-property: top, right, left, bottom
  transition-duration: .3s

.position-right,
.position-left
  width: fit-content
  height: 100vh
  max-height: 100vh
  top: 0
  bottom: 0

.position-top,
.position-bottom
  height: fit-content
  width: 100vw
  max-width: 100vw
  left: 0
  right: 0

.position-right
  border-radius: 20px 0 0 20px
  right: 0

.position-left
  border-radius: 0 20px 20px 0
  left: 0

.position-top
  border-radius: 0 0 20px 20px
  top: 0

.position-bottom
  border-radius: 20px 20px 0 0
  bottom: 0

.visible .sidebar
  transition-timing-function: ease-out

.hidden .sidebar
  transition-timing-function: ease-in
  &.position-top
    top: -100%
  &.position-right
    right: -100%
  &.position-bottom
    bottom: -100%
  &.position-left
    left: -100%

@keyframes appearContainer
  0%
    opacity: 0
    transform: translateX(30%)

  70%
    opacity: 1

  100%
    opacity: 1
    transform: translateX(0)
