@import 'src/theme/constants.scss'

.root
  display: flex
  flex-direction: column
  align-items: stretch
  padding: 30px 40px
  border-radius: $medium-border-radius
  min-width: 300px
  max-width: 668px
  height: fit-content
  margin: auto
  position: relative
  background: $primaryModalBGColor
  border: 1px solid transparent
  border-color: $modalBorderColor

.header
  margin-bottom: 25px

.body
  flex: 1 1 auto
  align-items: stretch
  overflow: auto

.closeButton
  position: absolute
  top: -54px
  right: 0
