@import 'src/theme/colors.scss'
@import 'src/theme/constants.scss'
@import 'src/theme/mixins.sass'

.sidebar
  color: $mainText100Color
  width: 100%
  height: 100%
  display: flex
  flex-direction: column
  gap: 20px
  padding-inline: 20px
  padding-block: 20px 30px
  @include thinScroll()

.section
  display: flex
  flex-direction: column
  gap: 10px
  h3
    color: $mainText70Color

.sectionList
  display: flex
  flex-direction: column
  background: $primaryContrast100_03Color
  border-radius: 8px
  max-height: 300px
  overflow: hidden

.primaryIcon
  color: $primary100Color

.listWrapper
  overflow: hidden auto

.infoSection
  background: linear-gradient(180deg, $primary70_20Color 0%, $primary70_20Color 100%)
  padding: 16px
  gap: 16px
  border-radius: 16px
  display: flex
  flex-direction: column
  p
    font-family: Inter
    font-size: 11px
    font-weight: 400
    line-height: 15.4px
    text-align: left
    color: $mainText100Color

.heading
  font-family: Inter
  font-size: 16px
  font-weight: 700
  line-height: 19.36px
  text-align: left
  color: $mainText100Color

.tag
  background-color: $primary50Color
  color: $primaryContrast100Color
  padding: 5px 10px
  border-radius: 5px
  width: fit-content
  padding: 4px 8px 4px 8px
  gap: 8px
  border-radius: 100px
  width: Hug (74px)
  font-weight: 500
  font-size: 10px

.product
  display: flex
  align-items: center
  justify-content: space-between
  padding: 6px
  border-radius: 5px
  cursor: pointer
  span
    padding-right: 8px
  div
    display: flex
    align-items: center
  span, div
    font-size: 15px
  i
    font-size: 24px
  img
    height: 24px
    width: 24px

.product, .dataType, .dataSource
  span
    margin-left: 10px

.container
  display: flex

.content
  flex: 1
  padding: 20px

.productIcon
  height: 36px
  width: 36px
  justify-content: center
  font-size: 20px
  border-radius: 50px
  background: $primaryCardBGColor

.iconWrapper img
  height: 36px
  width: 36px
  padding: 6px

.iconWrapper
  color: $mainText100Color

.link
  text-decoration: none
  color: $mainText100Color
  &:visited
    color: $mainText100Color

.expertsIcon
  padding-right: 0px
  background: $primaryCardBGColor
  height: 36px
  width: 36px
  padding: 0px
  text-align: center
  display: flex
  justify-content: center
  align-items: center

@media (max-width: $tablet-min-width)
  .sidebar
    padding: 8px
  .heading
    font-size: 12px
  .infoSection
    gap: 8px
    p
      font-size: 10px
  .productIcon
    height: 24px
    width: 24px
  .expertsIcon
    padding-left: 4px
    padding-right: 2px
    height: 24px
    width: 24px
  .product
    i
      font-size: 16px
    img
      height: 24px
      width: 24px
  .product span, .dataType span, .dataSource span
    font-size: 10px

@media (max-width: $mobile-min-width)
  .product span, .dataType span, .dataSource span
    font-size: 12px

@media (max-width: $tablet-min-height)
  .sidebar
    padding: 20px
