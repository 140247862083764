@import "./colors.scss";

$main-font-family: var(--main-font-family, "Inter");

$container-max-width: 1700px;
$desktop-min-height: 1400px;
$tablet-min-width: 1280px;
$tablet-max-width: 1100px;
$tablet-min-height: 768px;
$tablet-max-height: 900px;
$mobile-min-width: 590px;
$mobile-min-height: 400px;

$small-border-radius: 6px;
$medium-border-radius: 12px;
$big-border-radius: 24px;
