@import 'src/theme/colors.scss'

@mixin centerFlex()
    display: flex
    justify-content: center
    align-items: center

.root
    display: grid
    grid-template-columns: repeat(10, 1fr)

    .option
        cursor: pointer

.preview
    flex: 1 1 auto
    justify-self: center
    align-self: center
    @include centerFlex
    &>div
        @include centerFlex
        width: 240px
        height: 240px
        font-size: 100px
        background-color: $primary70_20Color
        border-radius: 50%
