@import 'src/theme/colors.scss'

.controlButton
  text-decoration: none
  font-size: 18px

.recording
  color: $red100Color
  transition: color 0.3

  animation: icon-pulse 1.5s linear infinite

  &:after
    content: ''
    position: absolute
    background: $red10Color
    inset: -0.25em
    border-radius: 50%
    animation: halo-pulse 1.5s linear infinite

@keyframes icon-pulse
  0%, 100%
    color: $red100Color
  50%
    color: $red70Color

@keyframes halo-pulse
  0%, 100%
    inset: -0.3em
  50%
    inset: -0.17em
