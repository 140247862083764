@import 'src/theme/colors.scss'

.root
  display: grid
  grid-template-columns: auto 1fr
  padding: 16px
  position: relative
  user-select: none
  cursor: pointer
  background: transparent
  border: none
  outline: none
  gap: 16px

.root:hover, .root:focus-visible
  background-color: $itemPrimaryHoverBgColor

.root:active
  background: $itemPrimaryActiveBgColor

.root[data-checked=true]
  filter: grayscale(0)
  // border-color: $primary100Color
  background: $text10Color

.header
  display: grid
  grid-template-columns: 1fr auto
  align-items: flex-start
  margin-bottom: 8px
  gap: 12px

.meta
  margin-bottom: 12px

.footer
  display: grid
  grid-template-columns: 1fr auto
  gap: 24px
