@import 'src/theme/colors.scss'
@import 'src/theme/constants.scss'

.root
  width: 850px
  height: min(900px, calc(100vh - 150px))

.container
  display: flex

.body
  display: flex
  flex-direction: column
  overflow: auto
  flex: 1 1 100%
  gap: 34px
  margin-bottom: 24px

.header
  display: flex
  flex-direction: column
  border-bottom: 1px solid $mainText50Color
  width: 100%

.titleContainer
  display: flex
  justify-content: center
  flex-direction: column
  align-items: center
  gap: 8px

.footer
  padding-top: 24px
  display: flex
  flex-direction: row
  width: 100%
  gap: 16px
  justify-content: center
  &>*
    min-width: 150px

.divider
  width: auto
  margin-block: 0
  border-top: 1px solid $mainText50Color

.heading
  margin-top: 16px

@media (max-width: $tablet-min-height)
  .footer
    flex-wrap: wrap
    gap: 8px
    button
      flex: 1
      font-size: 12px

  .container
    padding: 0px

  .list
    padding: 8px

  .divider
    margin: 0px 8px
