@import 'src/theme/colors.scss'

$containerPadding: var(--container-padding, 3px)
$height: var(--container-height, 42px)
$itemsPadding: var(--button-padding, 20px)

$main-bg: $inputMainBgColor
$border-color: transparent
$border-width: 1px
$checked-text-color: $primary10Color
$checked-item-color: $inputMainColor

.root
  --container-padding: 3px
  --container-height: 42px
  --button-padding: 20px
  display: flex
  position: relative
  background-color: $main-bg
  border: $border-width solid $border-color
  border-radius: calc(var(--container-height, 42px) / 2)
  padding: $containerPadding
  height: fit-content
  overflow: hidden
  min-height: $height
  font-size: 14px
  margin: 0

.root:disabled
  opacity: 0.2
  cursor: default
  pointer-events: none

.container
  display: flex
  overflow-x: auto
  position: relative
  border-radius: inherit
  height: fit-content
  max-width: 100%
  width: 100%
  &::-webkit-scrollbar
    display: none

.highlight
  box-shadow: 0 0 10px $primary100Color
  transition: box-shadow 0.5s ease-in-out

.item
  display: flex
  align-items: center
  justify-content: center
  text-align: center
  position: relative
  min-height: calc(#{$height} - #{$containerPadding} * 2 - #{$border-width} * 2)
  background-color: transparent
  color: $checked-item-color
  flex: 1 1 50%
  padding: 0 $itemsPadding
  transition: color var(--transition-speed, 0s), background-color 0s
  min-width: fit-content
  border: none
  outline: none
  user-select: none
  cursor: pointer

.item:disabled
  cursor: default

.checked
  transition: left var(--transition-speed, 0s) , width .1s , background-color 0s .5s
  color: $checked-text-color
  background-color: $primaryContrast100Color
  border-radius: inherit

.container:before
  content: ''
  padding: 0
  transition: left var(--transition-speed, 0s) , width var(--transition-speed, 0s)
  display: block
  position: absolute
  left: var(--switch-checkmark-left)
  height: var(--switch-checkmark-height)
  width: var(--switch-checkmark-width)
  background-color: $primaryContrast100Color
  border-radius: inherit
  color: transparent

.root.head
  border: 0
  padding: 0
  margin: 0
  border-bottom-left-radius: 0
  border-bottom-right-radius: 0
  max-height: 100%

  .item
    min-height: $height
    padding-block: $containerPadding
    min-width: auto

  .container::before
    height: 100%
    max-height: 100%
    left: var(--switch-checkmark-left)

.size-small
  --container-height: 30px
  --button-padding: 12px
  --container-padding: 2px
  font-size: 12px

.size-medium
  --container-height: 36px
  --button-padding: 16px
  --container-padding: 2px
  font-size: 14px

.size-big
  --container-height: 42px
  --button-padding: 20px
  --container-padding: 3px
  font-size: 14px
