@import "@root/theme/colors.scss";
@import "@root/theme/constants.scss";

.root {
  font-family: $main-font-family;
  font-style: normal;
  white-space: normal;
  overflow-wrap: break-word;
  display: block;
  margin: 0;
  cursor: inherit;
}

// variants

.root.title {
  font-size: 32px;
  line-height: 39px;
}

.root.h1 {
  font-size: 26px;
  line-height: 140%;
}

.root.h2 {
  font-size: 22px;
  line-height: 120%;
}

.root.h3 {
  font-size: 18px;
  line-height: 27px;
}

.root.h4 {
  font-size: 16px;
  line-height: 24px;
}

.root.h5 {
  font-size: 14px;
  line-height: 21px;
}

.root.h6 {
  font-size: 12px;
  line-height: 15px;
}

.root.body1 {
  font-size: 16px;
  line-height: 140%;
}

.root.body2 {
  font-size: 14px;
  line-height: 130%;
}

.root.body3 {
  font-size: 12px;
  line-height: 140%;
}

.root.caption1 {
  font-size: 14px;
  line-height: 21px;
}

.root.caption2 {
  font-size: 12px;
  line-height: 15px;
}

.root.caption3 {
  font-size: 10px;
  line-height: 12px;
}

.root.inherit {
  font-size: inherit;
  line-height: inherit;
}

// utils

.root {
  marker {
    color: $mainText100Color
  }
}

.root.start {
  text-align: start;
}

.root.center {
  text-align: center;
}

.root.end {
  text-align: end;
}

.root.upperCase {
  text-transform: uppercase;
}


.root.normal {
  font-weight: 400;
}

.root.inherit {
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
}

.root.semibold {
  font-weight: 500;
}

.root.bold {
  font-weight: 600;
}


.root.inline {
  display: inline;
}