@import 'src/theme/constants.scss'

$default-bg-color: $primaryCardL3BGColor
$highlight-bg-color: $primaryCardL3BorderColor
$default-border-color: $primaryCardL3BorderColor

.root
  display: flex
  gap: 12px
  width: 100%

.appear
  animation: appearAnimation .7s ease-in-out

@keyframes appearAnimation
  0%
    background: $default-bg-color
  25%
    background: $highlight-bg-color// rgb(145 97 255 / 29%)
  100%
    background: $default-bg-color
