
@import 'src/theme/colors.scss'
@import '@root/theme/mixins.sass'
@import 'src/theme/constants.scss'

.root
  width: 900px
  max-width: 100%
  padding-block: 28px
  padding-inline: 20px
  height: 100%
  @include secondaryScroll

.box
  display: flex
  flex-direction: column
  background: $primaryContrast10OpacityColor
  height: 100%
  border-radius: 24px
  overflow: auto
  border: 1px solid $dividerLineLightColor

.header
  display: flex
  align-items: flex-start
  margin-inline: 20px
  padding-block: 12px
  border-bottom: 1px solid $dividerLineLightColor
  width: auto
  gap: 20px

  .meta
    flex: 1 1 100%
    overflow: hidden

  .title
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    margin-block: 4px

.body
  display: flex
  flex: 1 1 100%
  overflow: auto

.footer
  padding: 16px

.placeholder
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  min-height: 300px
  max-width: 400px
  gap: 10px
  margin: auto
  font-size: 32px
