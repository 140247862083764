.postedExperts
  display: flex
  flex-direction: column
  gap: 16px

  .heading
    display: flex
    justify-content: space-between

  .actions
    display: flex
    gap: 8px
    margin-bottom: 16px
    button
      font-size: 12px

.postedExpertsList
  max-height: 400px
