@import 'src/theme/constants.scss'

.root
  background: $primaryCardBGColor
  border-radius: $small-border-radius
  padding: 16px

.header
  width: 100%
  padding-bottom: 16px
  border-bottom: 1px solid $text15Color

.body
  padding-top: 16px

.section
  display: flex
  flex-direction: column
  gap: 7px
  padding: 16px
  background: $text05Color
  border-radius: $small-border-radius

  ul
    margin: 0
    padding-left: 2.5ch

  li
    padding-bottom: 7px

.infoItem
  display: flex
  gap: 6px
  i
    display: inline
    color: $primary100Color
