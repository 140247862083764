.root
  display: block
  margin-bottom: 16px

.question
  margin-bottom: 24px

.expertsContainer
  display: flex
  flex-direction: column
  gap: 8px

.experts
  display: flex
  align-items: center
  overflow: auto
  flex-wrap: nowrap
  gap: 16px

.expertDetails
  display: flex
  align-items: center
  gap: 8px
  flex: 1 0 auto
