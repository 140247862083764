@import 'src/theme/constants.scss'
@import 'src/theme/mixins.sass'

.root
  display: flex
  flex-direction: column
  align-items: stretch
  width: 100%
  color: $mainText70Color
  overflow: auto
  @include secondaryScroll

  h3
    margin-bottom: 8px

  h4
    margin-bottom: 8px

  h5
    margin-bottom: 4px

  a:hover
    text-decoration: underline

  a, a:visited
    color: inherit
    text-decoration: none

.headerContainer
  padding-inline: 16px
  margin-block: 12px 24px

.header
  .title
    margin-bottom: 8px

.body
  display: grid
  grid-template-columns: 300px 1fr
  border-top: 1px solid $dividerLineLightColor
  flex: 1 1 100%

.menuContainer
  border-right: 1px solid $dividerLineLightColor
  background: $primaryCardL2BGColor
  flex: 1 1 30%
  width: 100%

.menu
  padding: 16px 20px
  position: sticky
  top: 0

  .title
    margin-bottom: 12px

  .menuItem
    padding-block: 8px

.menu.slim
  .title
    margin-bottom: 4px
  .menuItem
    padding-block: 2px

.contentContainer
  flex: 1 1 auto
  padding-inline: 32px

.content

.box
  padding-inline: 12px
  margin-inline: 20px
  border-radius: $medium-border-radius
  background: $secondary30_75Color
  overflow: auto
  scroll-behavior: smooth
  scrollbar-gutter: stable both-edges

.section
  padding-block: 20px

.section:not(:first-child)
  border-top: 1px solid $dividerLineLightColor

.block:not(:last-child)
  margin-bottom: 20px

.textBlock
  margin-block: 4px 8px
  h4, h3
    margin-top: 8px
    margin-bottom: 4px
    font-weight: bold
    color: $mainText100Color
  p
    margin-bottom: 8px
  ol
    margin-bottom: 16px
    li
      margin-bottom: 6px
  ul
    list-style-type: disc
    width: 100%
    margin-bottom: 16px
    li
      width: 100%
      align-items: center
      flex-wrap: wrap
      justify-content: left
      margin-bottom: 6px

.table
  padding-inline: 20px
  margin-bottom: 16px

.list
  margin-left: 20px

.subsection:not(:last-child)
  margin-bottom: 24px

@media (max-width: $tablet-max-height)
  .body
    grid-template-columns: 1fr
    overflow: initial

  .content
    padding-inline: 16px

  .menu
    position: static
