@import 'src/theme/constants.scss'

.root
  display: flex
  width: 100%
  padding-inline: 30px
  & > *
    padding: 30px

@media (max-width: $tablet-min-width)
  .root
    padding-inline: 0
    & > *
      padding-block: 20px
      padding-inline: 20px
