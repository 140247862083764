@import "@root/theme/colors.scss"

.root
  overflow: hidden
  text-overflow: ellipsis
  border-radius: .7ch
  background: $inputMainBgColor
  text-wrap: nowrap
  padding: 1px

.icon
  position: relative
  color: transparent
  display: inline-block
  width: 2ch
  line-height: initial
  &>*
    position: absolute
    display: inline-block
    top: 0
    right: 0
    width: 2ch
    height: 100%
    padding: 0.2ch
