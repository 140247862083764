@import 'src/theme/constants.scss'

.root
  display: flex
  flex-direction: column
  gap: 16px

.message
  color: $mainText50Color
  text-transform: uppercase
  margin: 30px auto
