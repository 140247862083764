@import 'src/theme/colors.scss'

.root
  width: 100%
  overflow: auto
  flex: 1 1
  p
    font-weight: 400
    font-style: normal
    font-size: 14px
    line-height: 24px
    margin: 0

.text
  position: relative
  color: $mainText70Color
  flex: 1 1 auto

.highlight
  background: transparent
  color: $mainText100Color
