@import 'src/theme/constants.scss'

.root
  display: flex
  flex: 1 1 auto
  border-radius: $medium-border-radius
  background: $text10Color
  width: 100%
  padding: 8px 10px

.icon
  font-size: 24px
  margin-right: 8px
  align-self: flex-start

.message
  font-weight: 500
  font-size: 16px
  line-height: 24px
  overflow: hidden

.root.colorInfo
  color: $gold100Color
  background: $gold10Color

.root.colorSuccess
  color: $green100Color
  background: $green10Color

.root.colorError
  color: $red100Color
  background: $red10Color
