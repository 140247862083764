@import 'src/theme/colors.scss'

.root
  max-width: 480px

.container
  display: flex
  flex-direction: column
  align-items: stretch
  gap: 45px
