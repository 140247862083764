@import 'src/theme/constants.scss'

.root
  width: 100%
  max-width: 1700px
  align-items: flex-start
  margin-inline: auto

.header
  margin-bottom: 30px
  align-items: center
  padding-bottom: 30px

.filterContainer
  display: flex
  justify-content: space-between
  margin-bottom: 16px

.selectedFilterDelete
  display: flex
  gap: 8px
  margin-left: 8px
  border-left: 1px solid $mainText10Color
  padding-left: 8px

.deleteChip
  span
    display: flex

.footer
  margin-top: 16px

.body
  min-height: 620px
  width: 100%
  max-width: 100%

.syncSkeleton
  width: 100%
  border-radius: 16px
  overflow: hidden
  height: 100px
  margin-bottom: 16px
  p
    width: 100% !important
    height: 100% !important

@media (max-width: $tablet-max-height)
  .header
    margin-bottom: 15px
