@import 'src/theme/constants.scss'

.row
  height: 56px

// icon
.row *:global(.col):nth-child(1)
  width: 44px
  text-overflow: unset

// label
.row *:global(.col):nth-child(2)
  width: 500px

// status 
.row *:global(.col):nth-child(3)
  width: 100px

// data-type
.row *:global(.col):nth-child(4)
  width: 250px

// avatars
.row *:global(.col):nth-child(5)
  width: 250px

// created at
.row *:global(.col):nth-child(6)
  width: 170px

// created by user
.row *:global(.col):nth-child(7)
  width: 200px

// menu
.row *:global(.col):nth-child(8)
  width: 50px
  text-overflow: unset

.text
  display: -webkit-box
  -webkit-box-orient: vertical
  -webkit-line-clamp: 2
  word-break: break-all
