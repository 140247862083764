@import 'src/theme/constants.scss'

// glass colors

.color-secondary
  background: $secondaryButtonBgColor
  color: $mainText100Color
  border-color: $mainText100Color

.color-primary
  background: $primary80_10Color
  color: $primary100Color
  border-color: $primary100Color

.color-default
  background: $defaultButtonBgColor
  color: $mainText100Color
  border-color: $mainText100Color

.color-red
  background: $red10Color
  color: $red100Color
  border-color: $red100Color

.color-gold
  background: $gold10Color
  color: $gold100Color
  border-color: $gold100Color

.color-green
  background: $green10Color
  color: $green100Color
  border-color: $green100Color

.color-blue
  background: $blue10Color
  color: $blue100Color
  border-color: $blue100Color

.color-yellow
  background: $yellow10Color
  color: $yellow100Color
  border-color: $yellow100Color

// solid colors

.color-secondary-solid
  background: $secondaryButtonBgColor
  border-color: $secondaryButtonBgColor
  color: $mainText100Color

.color-primary-solid
  background: $primary100Color
  border-color: $primary100Color
  color: $primaryContrast100Color

.color-default-solid
  background: $defaultButtonBgColor
  border-color: $defaultButtonBgColor
  color: $primaryContrast100Color

.color-red-solid
  background: $red100Color
  border-color: $red100Color
  color: $primaryContrast100Color

.color-gold-solid
  background: $gold100Color
  border-color: $gold100Color
  color: $contrastText100Color

.color-green-solid
  background: $green100Color
  border-color: $green100Color
  color: $contrastText100Color

.color-blue-solid
  background: $blue100Color
  border-color: $blue100Color
  color: $primaryContrast100Color

.color-yellow-solid
  background: $yellow100Color
  border-color: $yellow100Color
  color: $contrastText100Color

.color-transparent
  background: transparent
  color: $mainText100Color
