@import 'src/theme/colors.scss'

.root
  display: flex
  flex-direction: column

.header
  padding-bottom: 14px
  margin-bottom: 18px
  border-bottom: 1px solid $dividerLineLightColor
  h3
    margin-bottom: 5px

  h5
    color: $mainText50Color

.description
  p
    font-style: normal
    line-height: 24px

.fixes
  display: flex
  flex-direction: column
  gap: 10px
  padding-left: 17px

  li
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 24px

  li::marker
    color: $primary100Color
