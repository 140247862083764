@import './Button-size.module'
@import './Button-color.module'

.contained, .outlined, .text
  padding-inline: var(--btn-padding, 32px)
  height: var(--btn-min-height, 32px)
  min-height: var(--btn-min-height, 32px)
  min-width: var(--btn-min-height, 32px)
  border-radius: calc(var(--btn-min-height, 32px) / 2)

.contained
  color: var(--text-color)
  background: var(--main-color)
  border: 1px solid transparent

.outlined
  color: var(--main-color)
  background: var(--hover-bg-color)
  border: 1px solid var(--main-color)

.slim,
.text
  color: var(--main-color)
  background: transparent
  border: none
  width: auto
  padding: 0

.slim
  text-decoration: underline
  line-height: inherit
  height: fit-content
  min-height: auto

.text
  font-size: var(--btn-text-font-size)
