@import 'src/theme/constants.scss'

$anumdationDuration: var(--animation-duration, .3s)

.root
  display: flex
  flex-direction: column
  position: absolute
  left: 0
  width: 100%
  max-height: 85vh
  padding: 0 10px 20px 10px
  border-radius: 20px 20px 0px 0px
  background: $primaryModalBGColor
  bottom: 100%
  z-index: 1

@keyframes hide-filter
  0%
    visibility: visible
    bottom: calc(0px - var(--swipe-shift, 0px))
  100%
    visibility: hidden
    bottom: -100%

@keyframes show-filter
  0%
    visibility: hidden
    bottom: -100%
  100%
    visibility: visible
    bottom: 0px

.root.shown
  visibility: visible
  transition: bottom 0s
  animation: show-filter $anumdationDuration ease-out
  bottom: calc(0px - var(--swipe-shift, 0px))

.root.hidden
  visibility: hidden
  animation: hide-filter $anumdationDuration ease-in
  bottom: -100%

.header
  padding: 25px 20px 20px
  user-select: none

.handle
  position: absolute
  top: 3px
  left: 50%
  transform: translateX(-50%)
  height: 3px
  width: 70px
  border-radius: 5px
  margin: 5px auto
  background: $text15Color

.body
  display: flex
  flex-direction: column
  padding: 0 15px
  width: 100%
  max-width: 590px
  margin: 0 auto
  overflow: auto

@media (max-width: $tablet-min-height)
  .body
    margin: 0 auto
    max-width: 100%

@media (max-width: $mobile-min-height)
  .body
    padding: 0 10px
