@import 'src/theme/colors.scss'
  
.root
  display: flex
  flex-direction: column
  padding: 50px
  width: 700px
  max-width: 700px

.content
  margin-bottom: 40px
  border-radius: 16px
  padding: 8px 0px
  overflow: hidden
  background: $primaryContrast10OpacityColor
.skeleton,
.list
  display: flex
  flex-direction: column
  overflow-y: auto
  padding: 0px 8px
  max-height: 100%
  gap: 8px
  max-height: 400px

.footer
  display: flex
  justify-content: center
  gap: 8px
  button
    width: 150px

.company
  display: flex
  gap: 8px
  padding: 8px
  border-radius: 8px
  cursor: pointer
  border: 1px solid transparent
  align-items: center
  i
    font-size: 15px
    background: $secondary50Color
    height: 32px
    width: 32px
    border-radius: 21px
  div
    display: flex
    flex-direction: column
    p
      font-weight: 600 !important

.selected
  border: 1px solid $primary100Color
  
.selectedManufacturersContainer
  display: flex
  flex-direction: column
  gap: 16px
  margin-bottom: 16px

.selectedManufacturers
  max-width: 100%
  width: 100%
  background: $primaryContrast10OpacityColor
  min-height: 42px
  max-height: 42px
  padding: 8px 16px
  border-radius: 32px
  overflow-x: auto
  overflow-y: hidden
  div
    display: flex
    width: max-content
    gap: 8px
    button
      background: $primaryContrast10OpacityColor
      padding: 4px 8px
      width: max-content
      height: max-content
      font-size: 12px
      i
        font-size: 10px
      > span:first-of-type > i
        height: 20px
        width: 20px
        border-radius: 12px

.header
  font-weight: 700 !important

.list
  .company:nth-of-type(3n + 1)
    i
      background-color: $green100Color
      color: $mainText100Color
  .company:nth-of-type(3n + 2)
    i
      background-color: $gold100Color
      color: $mainText100Color
  .company:nth-of-type(3n + 3)
    i
      background-color: $primary100Color
      color: $mainText100Color

.greenIcon
  background-color: $green100Color
  color: $mainText100Color
.goldIcon
  background-color: $gold100Color
  color: $mainText100Color
.primaryIcon
  background-color: $primary100Color
  color: $mainText100Color