@import 'src/theme/colors.scss'

.root
  display: inline-flex
  user-select: none
  cursor: default
  padding: 10px
  width: 100%
  margin: 0
  border-radius: 6px
  text-align: initial
  background: transparent
  border-color: transparent
  color: inherit
  align-items: center
  gap: 16px

.root.clickable
  cursor: pointer

.root.clickable:hover
  background: $primaryContrast100_03Color

.content
  flex: 1 1 100%
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap
  overflow-wrap: break-word
