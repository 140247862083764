@import 'src/theme/constants.scss'
@import '@root/theme/mixins.sass'

.root
  display: flex
  min-width: min(900px, 100vw)
  max-width: 1800px

  &.leftAligned
    min-width: 100%

.body
  height: 100%
  width: 100%
  overflow: hidden
  max-width: 900px
  flex: 1 1 900px
  position: relative
  @include thinScroll('&', 0px)

.sidePanel
  min-width: 400px
  max-width: 900px
  flex: 0 1 auto
  height: 100%
  padding: 0
  overflow: auto

.container
  display: flex
  flex-direction: column
  align-items: center
  overflow: auto
  width: 900px
  max-width: 100%
  height: 100%
  scrollbar-width: thin
  scrollbar-color: transparent transparent
  scrollbar-gutter: stable both-edges

.list
  width: 100%
  padding-inline: 20px
  padding-bottom: max(25vh, 200px)

@media(max-width: $tablet-max-width)
  .body
    flex: 1 1 100%
