@import "@root/theme/colors.scss"
@import "@root/theme/mixins.sass"

.root
  max-height: 100%
  overflow: auto

.container
  display: flex
  width: fit-content
  height: fit-content
  min-width: 100%
  position: relative
  overflow: hidden

.textarea
  background: transparent
  border: none
  resize: none
  outline: none
  padding: 0
  margin: 0
  white-space: pre-wrap
  position: absolute
  top: 0
  left: 0
  overflow: hidden
  display: inline-block
  word-break: break-all
  caret-color: $mainText100Color
  color: transparent

.textContainer
  user-select: none
  pointer-events: none
  display: inline-block
  white-space: pre-wrap
  word-break: break-all
  min-height: 2ch
  min-width: 100%
