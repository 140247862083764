@import 'src/theme/colors.scss'
@import 'src/theme/constants.scss'

.root
  max-width: 100%
  padding-left: 20px
  padding-right: 20px
  padding-bottom: 40px

.container
  display: flex
  flex-direction: column
  align-items: stretch
  gap: 24px

.body
  display: flex
  flex-direction: column
  width: 100%
  min-width: 100%
  min-height: 200px
  max-width: 100%
  max-height: 400px
  overflow-y: auto
  overflow-x: hidden

.footer
  display: flex
  justify-content: center
  gap: 16px
  margin-top: 12px
  button
    width: 160px

.loader
  display: flex
  width: 100%
  justify-content: center

.lastElem
  width: 100%
  height: 32px
  border: 1px solid transparent

@media (min-width: $tablet-min-height)
  .root
    min-width: 700px
    max-width: 850px
