@import 'src/theme/constants.scss'

.root
  min-width: 600px

  .container
    margin-bottom: 48px

  .detailsContainer
    display: flex
    align-items: center
    flex-direction: column
    gap: 16px

  .warningText
    max-width: 400px
    text-align: center

  .success
    display: flex
    gap: 8px
    align-items: center

    .icon
      font-size: 24px
  
  .apiKeyContainer
    display: flex
    flex-direction: column 
    gap: 8px
    align-items: center
    margin-top: 24px

  .apiKey
    padding: 4px 8px
    border-radius: 4px
    border: 1px solid $mainText50Color
