@import 'src/theme/colors.scss'

$gutterSizes: 0 , 4 , 8 , 16 , 24 , 32
$flexDirection: 'row' , 'column'
$flexAlign: 'initial' , 'flex-start' , 'center' , 'flex-end' , 'stretch'
$flexJustify: 'initial' , 'flex-start' , 'center' , 'flex-end' , 'space-between'
$overflow: 'initial' , 'hidden' , 'auto'
$wrap: 'wrap' , 'nowrap'

.root
  display: flex
  overflow: auto
  flex-direction: column
  width: 100%
  flex: 0 1 100%

@each $gutter in $gutterSizes
  .gutter-#{$gutter}
    gap: #{$gutter}px

@each $direction in $flexDirection
  .direction-#{$direction}
    flex-direction: #{$direction}

@each $align in $flexAlign
  .align-#{$align}
    align-items: #{$align}

@each $justify in $flexJustify
  .justify-#{$justify}
    justify-content: #{$justify}

@each $item in $overflow
  .overflow-#{$item}
    overflow: #{$item}

@each $item in $wrap
  .wrap-#{$item}
    flex-wrap: #{$item}
