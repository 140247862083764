@import 'src/theme/constants.scss'

.root
  display: flex
  flex-direction: column
  border-radius: $medium-border-radius
  background: $itemPrimaryBgColor
  text-align: center
  text-decoration: none
  padding: 16px
  border: 2px solid $itemPrimaryHoverBgColor
  align-items: stretch
  width: auto
  gap: 16px
  margin: 3px

.clickable
  cursor: pointer

.header
  display: grid
  grid-template-columns: auto 1fr auto
  width: 100%
  gap: 12px

.meta
  display: flex
  flex-direction: column

  .title
    margin-bottom: 8px

.description
  color: $mainText50Color
  font-size: 12px
  line-height: 25px
  padding-block: 8px

.root:hover
  background: $itemPrimaryHoverBgColor

.root:focus-visible, .root:active
  box-shadow: 0px 0px 2px 3px $primary70_20Color
  border-color: $itemPrimaryActiveColor
  outline: none

.root.active
  background: $itemPrimaryActiveBgColor
  border-color: $itemPrimaryActiveColor

.root[aria-disabled="true"]:hover, .root:disabled:hover
  border-color: transparent
  box-shadow: none
  background: $secondaryCardBGColor
  cursor: not-allowed

.root[aria-disabled="true"], .root:disabled
  pointer-events: none
  opacity: .6

.content
  padding-top: 12px
  border-top: 1px solid $primaryContrast80Color

.label
  padding-block: 5px
