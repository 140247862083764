@import 'src/theme/constants.scss'

.root
  position: fixed
  display: flex
  align-items: center
  justify-content: center
  width: 100vw
  z-index: 100
  pointer-events: none
  animation: appearAnimation .7s ease-in

  &>*
    pointer-events: all

.banner
  max-width: 650px
  margin: 20px

@keyframes appearAnimation
  0%
    transform: translateY(-10px)
    opacity: 0
  100%
    transform: translateY(0)
    opacity: 1
