@import 'src/theme/colors.scss'

.errorMessage
  font-size: 12px
  padding: 7px 10px
  width: max-content
  max-width: 250px
  white-space: pre-wrap

.errorIcon
  margin-right: 10px !important
  margin-left: 0 !important
