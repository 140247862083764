@import 'src/theme/colors.scss'
@import '@root/theme/mixins.sass'
    
.scrollableTable
  thead
    position: sticky
    z-index: 999
    top: 0
    background: $inputMainBgColor
  width: 100%
  max-height: 400px
  overflow-y: auto
  @include thinScroll('&', 2px)


.root
  margin-top: 16px
  h2
    display: flex
    align-items: center
    gap: 8px
    margin-bottom: 16px

.footerButtonWrapper
  display: flex
  justify-content: center
  padding: 16px

.actionColumn
  justify-content: right
  text-align: right !important
