@import "@root/theme/constants.scss"

.root
  display: flex
  align-items: center
  gap: 16px
  width: 100%
  max-width: 100%
  box-sizing: border-box

.left,
.right
  display: flex
  flex-wrap: wrap
  flex: 1 1 auto
  align-items: center
  gap: 10px

.left
  justify-content: flex-start

.right
  justify-content: flex-end

.root.align-center
  align-items: center
.root.align-start
  align-items: flex-start
.root.align-end
  align-items: flex-end

.root.offset-bottom-auto
  margin-bottom: none
.root.offset-bottom-small
  margin-bottom: 4px
.root.offset-bottom-medium
  margin-bottom: 8px
.root.offset-bottom-middle
  margin-bottom: 16px
.root.offset-bottom-big
  margin-bottom: 24px

.root.offset-top-auto
  margin-top: none
.root.offset-top-small
  margin-top: 4px
.root.offset-top-medium
  margin-top: 8px
.root.offset-top-middle
  margin-top: 16px
.root.offset-top-big
  margin-top: 24px
