$text100Color: var(--text-100-color, hsl(0, 0%, 100%, 1));
$text80Color: var(--text-80-color, hsla(0, 0%, 100%, 0.8));
$text70Color: var(--text-70-color, hsla(0, 0%, 100%, 0.7));
$text60Color: var(--text-60-color, hsla(0, 0%, 100%, 0.6)); // deprecated 
$text40Color: var(--text-40-color, hsla(0, 0%, 100%, 0.4));
$text20Color: var(--text-20-color, hsla(0, 0%, 100%, 0.2));
$text15Color: var(--text-15-color, hsla(0, 0%, 100%, 0.15));
$text10Color: var(--text-10-color, hsla(0, 0%, 100%, 0.102));
$text08Color: var(--text-08-color, hsla(0, 0%, 100%, 0.08));
$text05Color: var(--text-05-color, hsla(0, 0%, 100%, 0.05));
$transparent: var(--transparent, hsl(0, 0%, 0, 0));

// typography

$mainText100Color: var(--main-text-100-color, #ffffff);
$mainText70Color: var(--main-text-70-color, #d0cfd9);
$mainText50Color: var(--main-text-50-color, #8d8c99); 
$mainText40Color: var(--main-text-40-color, #7a788c);
$mainText20Color: var(--main-text-20-color, #6e6e73);
$mainText10Color: var(--main-text-10-color, #474747);

$contrastText100Color: var(--contrast-text-100-color, hsl(259, 84%, 10%));

// primary

$primary110Color: var(--primary-110-color, #CA8EFE);

$primary105Color: var(--primary-105-color, #956bf0);

$primary100Color: var(--primary-100-color, #8854f7);

$primary80Color: var(--primary-80-color, #8854f7);

$primary80_15Color: var(--primary-80-15-color, #8854f72b);

$primary80_10Color: var(--primary-80-10-color, #8854f71a);

$primary70_20Color: var(--primary-70-20-color, #956def33); // primary border

$primary50Color: var(--primary-50-color, #502ba0) ;

$primary20Color: var(--primary-20-color, #1f0a4d);

$primary10Color: var(--primary-10-color, #13052e);

$primaryContrast100Color: var(--primary-contrast-100-color, #fff);

$primaryContrast80Color: var(--primary-contrast-80-color, #8d8c99);

$primaryContrast10OpacityColor: var(--primary-contrast-10-opacity-color, #FFFFFF0D);

$primaryContrast100_03Color: var(--primary-contrast-100-03-color, #ffffff15) ;

// secondary

$secondary100Color: var(--secondary-100-color, #3e3b69) ;

$secondary80Color: var(--secondary-80-color, #494573);

$secondary70Color: var(--secondary-70-color, #312E5C) ;

$secondary50Color: var(--secondary-50-color, #28245c);

$secondary30_75Color: var(--secondary-30-75-color, #141138bf);

$secondary25Color: var(--secondary-25-color, #0c0a26);

$secondary20Color: var(--secondary-20-color, #0e0a3d);

$secondary10Color: var(--secondary-10-color, #030024);

// other

$red100Color: var(--red-100-color, #ff3a3a);
$red80Color: var(--red-80-color, #ff3838cc);
$red70Color: var(--red-70-color, #ff2929b3);
$red10Color: var(--red-10-color, #ff3a3a1a);
$red05Color: var(--red-05-color, #ff3a3a0d);


$gold100Color: var(--gold-100-color, #ffa200);
$gold80Color: var(--gold-80-color, #ffa200cc);
$gold10Color: var(--gold-10-color, #ffa2001a);
$gold05Color: var(--gold-05-color, #ffa2000d);


$green100Color: var(--green-100-color, #03d980);
$green80Color: var(--green-80-color, #03d980cc);
$green10Color: var(--green-10-color, #03d9801a);
$green05Color: var(--green-05-color, #03d9800d);


$blue100Color: var(--blue-100-color, #5c97fc);
$blue80Color: var(--blue-80-color, #5995fccc);
$blue10Color: var(--blue-10-color, #5c97fc1a);
$blue05Color: var(--blue-05-color, #5c97fc0d);


$yellow100Color: var(--yellow-100-color, #f0ff47);
$yellow80Color: var(--yellow-80-color, #f0ff47cc);
$yellow10Color: var(--yellow-10-color, #f0ff471a);
$yellow05Color: var(--yellow-05-color, #f0ff470d);


$primaryMainBGColor: var(--primary-main-bg-color, linear-gradient(180deg, var(--secondary-20-color, hsl(245, 75%, 14%)) 0%, var(--primary-10-color, hsl(245, 62%, 9%)) 100%));

$elevationL1Shadow: var(--elevation-l1-shadow, 0px 4px 4px 0px hsl(259, 84%, 10%));
$elevationL2Shadow: var(--elevation-l2-shadow, 0px 5px 10px hsl(259, 84%, 10%));

$highlightL2Shadow: var(--highlight-l2-shadow, 0px 0px 1px 1px hsl(259, 91%, 65%));
$highlightL10Shadow: var(--highlight-l10-shadow, 0px 0px 300px hsla(245, 53%, 14%, 0.749), -2px -2px 20px -5px #13052e, 0px 20px 60px 0px #956def33, 0px 5px 10px 0px #956def33);

$skeletonLoaderColor: var(--skeleton-loader-color, linear-gradient(90deg,rgba(0, 0, 0, 0),#ffffff24 50%,rgba(0, 0, 0, 0) 60%,rgba(0, 0, 0, 0) 100%));

$modalBorderColor: var(--modal-border-color, transparent);


// overrides
$primaryCardBGColor: var(--primary-card-bg-color);
$primaryCardBGHoverColor: var(--primary-card-bg-hover-color);

$primaryCardL2BGColor: var(--primary-card-l2-bg-color);

$primaryCardL3BGColor: var(--primary-card-l3-bg-color);
$primaryCardL3BorderColor: var(--primary-card-l3-border-color);

$secondaryCardBGColor: var(--secondary-card-bg-color);

$primaryModalBGColor: var(--primary-modal-bg-color);
$primaryDrawerBGColor: var(--primary-drawer-bg-color);

$primaryDarkBGColor: var(--primary-dark-bg-color, #040123);

// dividers

$dividerLineLightColor: var(--divider-line-light-color);
$dividerLineDarkColor: var(--divider-line-dark-color);

// scroll

$mainScrollColor: var(--main-scroll-color);
$secondaryScrollColor: var(--secondary-scroll-color);

// components

$tableHeaderColor: var(--table-header-color);

$inputMainColor: var(--input-main-color);
$inputMainBgColor: var(--input-main-bg-color);
$inputMainBgFocusedColor: var(--input-main-bg-focused-color);

$defaultButtonBgColor: var(--default-button-bg-color);
$defaultButtonBgFocusedColor: var(--default-button-bg-focused-color); 

$secondaryButtonBgColor: var(--secondary-button-bg-color);
$secondaryButtonBgFocusedColor: var(--secondary-button-bg-focused-color); 

$itemPrimaryBgColor: var(--item-primary-bg-color);
$itemSecondaryBgColor: var(--item-secondary-bg-color);

$itemPrimaryHoverBgColor: var(--item-primary-hover-bg-color);
$itemSecondaryHoverBgColor: var(--item-secondary-hover-bg-color);

$navItemActiveBgColor: var(--nav-item-active-bg-color);
$navItemColor: var(--nav-item-color);
$navItemActiveColor: var(--nav-item-active-color);

$itemPrimaryActiveColor: var(--item-primary-active-color);
$itemPrimaryActiveBgColor: var(--item-primary-active-bg-color);

$itemSecondaryActiveColor: var(--item-secondary-active-color);
$itemSecondaryActiveBgColor: var(--item-secondary-active-bg-color);

$primaryToolbarBgColor: var(--primary-toolbar-bg-color);

$widgetBgColor: var(--widget-bg-color);

$primaryTooltipBgColor: var(--primary-tooltip-bg-color);

// elevation

$elevationL1Shadow: var(--elevation-l1-shadow);
$elevationL2Shadow: var(--elevation-l2-shadow);

$highlightL2Shadow: var(--highlight-l2-shadow);
$highlightL10Shadow: var(--highlight-l10-shadow);

$skeletonLoaderColor: var(--skeleton-loader-color);