@import 'src/theme/constants.scss'
.root
  display: flex
  flex-wrap: wrap
  gap: 8px

.filterItem
  user-select: none

@media (max-width: $mobile-min-width)
  .root
    min-height: 40px
    width: max-content
    flex-wrap: nowrap

  .filterItem
    padding: 8px
    height: 32px
