@import 'src/theme/colors.scss'
@import '@root/theme/mixins.sass'

.root
  display: flex
  flex-direction: column
  flex: 1 1 auto
  padding-right: 5px
  overflow-y: auto
  @include thinScroll('&')
