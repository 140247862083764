@import 'src/theme/constants.scss'
@import 'src/theme/colors.scss'
@import 'src/theme/mixins.sass'

.root
  display: flex
  flex-direction: column
  align-items: flex-start
  gap: 20px

.container
  @include thinScroll('&')
  flex: 1 1 auto
  width: 100%

.row
  color: $mainText100Color

.borderTop
  border-top: 1.5px solid $primary70_20Color

.textInputCustom
  background: none
  border-radius: 0
  display: flex
  align-items: center
  /* Hide the arrows for number input */
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button
    -webkit-appearance: none
    margin: 0
    padding-left: 10px

  input[type="number"]
    -moz-appearance: textfield
    padding-left: 10px

.inputEnd
  white-space: nowrap
  color: $text40Color

.textInputWrapper
  width: 65%

.purchasedPadding
  padding-left: 20px

.switch
  max-width: 150px

.editSelectRow
  padding: 8px 0px
  display: flex
  > div
    flex: 1

.editSelectRow:not(:last-child)
  border-bottom: 1px solid $dividerLineLightColor

.statusWrapper
  display: flex
  align-items: center
  margin-left: 17px

.noPadding
  padding: 0px !important// added !important because its getting overriden by table cell style

@media (max-width: $mobile-min-width)
  .control > *
    flex: 1 1 100%
