.root
    min-width: 50%
    min-height: 50%
    @media (max-width: 1300px)
        min-width: 80%
        min-height: 80%

.formInputPaddingRight
    width: 50%
    margin-bottom: 2rem
    padding-right: 1rem

.formInputPaddingLeft
    width: 50%
    margin-bottom: 2rem
    padding-left: 1rem

.form 
    display: flex
    flex-wrap: wrap
    justify-content: space-between

.formInputHeading
        padding-bottom: 0.5rem

.fullWidthInput
    width: 100%
    margin-bottom: 2rem

.footer
  display: flex
  justify-content: center
  gap: 16px
  width: 100%
  button
    min-width: 120px