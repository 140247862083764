@import 'src/theme/constants.scss'

.root
  display: flex
  flex-direction: column
  gap: 24px
  background: $primaryCardL3BGColor
  border-radius: $small-border-radius
  padding: 24px
  width: 350px
  min-height: 505px

  @media screen and (max-width: $tablet-max-height)
    width: 100%

.body
  display: flex
  flex: 1

.viewAll
  width: fit-content
