@import 'src/theme/colors.scss'

// primary color scheme
$button-bg-color: $primary70_20Color
$button-color: $mainText50Color
$active-button-bg-color: $primary100Color
$active-button-color: $primaryContrast100Color
$hovered-button-color: $primaryContrast80Color

// secondary color scheme
// $button-bg-color: $text15Color
// $button-color: $mainText50Color
// $active-button-bg-color: $mainText100Color
// $active-button-color: $primary10Color
// $hovered-button-color: $mainText100Color

.root
  display: flex
  align-items: stretch
  padding: 0
  margin: 0
  gap: 8px
  height: 30px
  li::marker
    content: none

:is(.previousBtn, .nextBtn, .page, .break)
  display: flex
  align-items: center
  cursor: pointer
  padding-inline: 12px
  border-radius: 6px
  height: 100%
  user-select: none
  background: $button-bg-color
  color: $button-color
  &:hover
    color: $hovered-button-color

.page
  &:focus
    color: $active-button-color

.previousBtn,
.nextBtn
  padding-inline: 8px

:is(.page, .previousBtn, .nextBtn)
  &:active
    color: $active-button-color
  &:active:hover
    color: $active-button-color

.active
  background: $active-button-bg-color
  color: $active-button-color
