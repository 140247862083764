@import 'src/theme/colors.scss'
@import "src/theme/constants.scss"

.input
  height: 150px
  border-radius: 10px
  min-width: 400px
  flex: 1 1 100%
  grid-column: 1 / 3

.ratings
  display: flex
  gap: 8px
  flex-direction: column
  justify-content: center
  align-items: center
  margin-top: 32px

@media (max-width: $mobile-min-width)
  .input
    min-width: auto
