@import 'src/theme/colors.scss'

.root
  display: flex
  text-align: center
  align-items: stretch
  flex-direction: column
  gap: 20px

.formContainer
  display: flex
  flex-direction: column
  align-items: stretch
  flex: 1 1 auto
  width: 100%
  gap: 20px

.after
  display: flex
  margin-right: 10px
  gap: 5px
  align-self: center

.input
  background: $text10Color

.icon
  width: 55px
  height: 55px
  align-self: center
