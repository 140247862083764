@import 'src/theme/constants.scss'
@import 'src/theme/colors.scss'

.root
  min-height: 100%
  width: 100%
  display: flex
  flex-direction: column

.skeleton
  display: grid
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr))
  height: fit-content
  gap: 24px !important

.skeletonLines
  width: 100% !important

.header
  display: flex
  justify-content: space-between
  width: 100%
  gap: 16px
  padding: 12px 24px
  border-bottom: 1px solid $dividerLineLightColor
  align-items: center

.greeting
  display: flex
  flex-direction: column
  gap: 8px
  max-width: 700px

.body
  display: flex
  gap: 32px
  padding-bottom: 160px

.mainSection
  position: relative
  padding: 20px 20px 30px
  gap: 24px
  flex: 1 1 100%
  display: flex
  width: 100%
  flex-direction: column

.sidebarSection
  flex: 0 0 400px
  border-left: 1px solid $dividerLineLightColor
  &::-webkit-scrollbar
    width: 0px
  &::-webkit-scrollbar-thumb
    border-radius: 0px

.content
  display: flex
  width: 100%
  height: 100%

.categories
  width: 100%
  padding: 10px 0px
  display: flex
  justify-content: center
  gap: 10px
  flex-wrap: wrap
  button
    width: 180px

.placeholderWrapper
  display: flex
  height: fit-content
  min-height: max(500px, calc(100vh - 100px))

@media (max-width: $tablet-min-width)
  .sidebarSection
    flex: 0 0 280px

  .mainLayout
    padding: 0px
    &::-webkit-scrollbar
      width: 2px
    &::-webkit-scrollbar-thumb
      border-radius: 2px
  .categories
    button
      width: 150px

@media (max-width: $tablet-max-height)
  .root
    padding: 0

  .skeleton
    grid-template-columns: repeat(1, 1fr)

  .sidebarSection
    flex: 0 0 240px
  .mainLayout
    padding-top: 62px

  .header
    padding: 16px
  .categories
    padding: 0px

@media (max-width: $tablet-min-height)
  .sidebarSection
    flex: 0 0 100%
  .content
    flex-wrap: wrap
  .sidebarSection
    border: 0px
  .mainSection
    gap: 16px

@media (max-width: $mobile-min-width)
  .categories
    display: grid
    grid-template-columns: repeat(2, 1fr)
    button
      width: 100%
