@import 'src/theme/constants.scss'

.root
  display: flex
  flex-direction: column
  width: 100%
  max-width: 1700px
  align-items: stretch
  margin-inline: auto

.header
  margin-bottom: 24px

.body
  flex: 1 1 100%
  padding: 20px

@media (max-width: $tablet-max-height)
  .header
    margin-bottom: 15px
