@import "src/theme/colors.scss"

.target
  width: fit-content

.body
  z-index: 110
  padding: 8px
  background: var(--bg-color)
  border-radius: 6px
  box-shadow: $elevationL2Shadow
  border: 1.4px solid var(--border-color)
  color: var(--text-color)
  position: relative
  font-size: 14px
  pointer-events: none
  min-width: 70px
  animation: appearance .1s ease-in

  &.color-default
    --bg-color: #{$mainText100Color}
    --border-color: #{$dividerLineLightColor}
    --text-color: #{$contrastText100Color}

  &.color-primary
    --bg-color: #{$primaryTooltipBgColor}
    --border-color: #{$dividerLineLightColor}
    --text-color: #{$mainText100Color}

.tip
  position: absolute
  overflow: hidden
  text-align: initial
  pointer-events: none
  &::after
    content: ""
    position: absolute
    height: 12px
    width: 12px
    background: var(--bg-color)
    border: 1.4px solid var(--border-color)

.position-top, .position-bottom
  .tip
    height: 30px
    width: 30px
    &::after
      left: 50%

.position-left, .position-right
  .tip
    height: 30px
    width: 30px
    &::after
      top: 50%

.position-top
  .tip
    top: 100%
    &::after
      top: 0
      transform: translateX(-50%) translateY(-50%) rotate(45deg)

.position-right
  .tip
    right: 100%
    &::after
      right: 0
      transform: translateX(50%) translateY(-50%) rotate(45deg)

.position-bottom
  .tip
    bottom: 100%
    &::after
      bottom: 0
      transform: translateX(-50%) translateY(50%) rotate(45deg)

.position-left
  .tip
    left: 100%
    &::after
      left: 0
      transform: translateX(-50%) translateY(-50%) rotate(45deg)

@keyframes appearance
  0%
    opacity: .3
  100%
    opacity: 1
