@import 'src/theme/colors.scss'

.root
  display: flex
  align-items: center
  flex-direction: column
  width: 100%
  gap: 3px

.retryButton
  width: 20px
  height: 20px
  font-size: 12px
  padding: 5px
  border-radius: 50%
  background: $primary50Color
