@import 'src/theme/colors.scss'
@import 'src/theme/constants.scss'
  
.root
  display: flex
  flex-direction: column
  width: 400px
  height: 100%

.headingWrapper
  button
    display: none

.footer
  flex: 0
  display: flex
  justify-content: center
  
.footer
  visibility: hidden

.searchInput
  min-width: auto
  flex: 1

.searchFilters
  display: flex
  gap: 16px
  align-items: center

@media(max-width: $tablet-max-width)
  .footer
    visibility: visible

@media (max-width: $mobile-min-width)
  .root
    width: 100%

@media (max-width: $tablet-max-height)
  .root
    padding: 40px 16px 16px
  .headingWrapper
    display: flex
    justify-content: space-between
    button
      display: inline-block
