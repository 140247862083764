@import "@root/theme/colors.scss";
@import "@root/theme/constants.scss";

$font-family: $main-font-family;

.react-calendar {
  font-family: $font-family;
  padding: 0;
  font-size: 14px;
  line-height: 16px;
  width: 300px;
  color: $mainText100Color;
}

.react-calendar__navigation {
  display: flex;
  justify-content: space-between;

  &__label {
    color: $mainText100Color;
    font-family: $font-family;
    flex: initial !important;
    background-color: transparent;
    border: none;
    outline: none;
    padding: 20px 0;
    min-width: 100px;
    cursor: pointer;
  }

  &__arrow {
    color: $mainText100Color;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 18px;
    padding: 20px;
  }

  &__prev-button,
  &__next-button {
    
  }

  &__next2-button,
  &__prev2-button {
    display: none;
  }
}

.react-calendar__tile {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  outline: none;
  border: none;
  background-color: transparent;
  font-family: $font-family;
  font-weight: 500;
  font-size: 12px;
  color: $mainText100Color;
  padding: 0;
  position: relative;

  & > * {
    line-height: 1.2em;
  }

  &::before {
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    width: 40px;
    height: 40px;
    transform: translate(-50%, -50%);
  }

  abbr {
    border: 1px solid transparent;
  }
}

.react-calendar__month-view__weekdays {
  border-bottom: 2px solid $mainText40Color;
  margin-bottom: 10px;
  padding: 6px 0;
  & * {
    text-decoration: none;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
  }
}

.react-calendar__month-view__weekdays__weekday {
  text-align: center;
  & > * {
    text-decoration: none !important;
  }
}

.react-calendar__month-view__days {
  z-index: 0;
  row-gap: 5px;
}

.react-calendar__month-view__days__day {
  outline: none;

  &--weekend {
    &.react-calendar__tile abbr {
      color: $mainText50Color;
      font-weight: bold;
    }
  }
  
  &--neighboringMonth {
    &.react-calendar__tile {
      margin: auto;
      color: $mainText40Color;
    }
  }

  &:hover > abbr, &:focus > abbr {
    color: $mainText100Color;
    border-color: $primary100Color;  
  }

  &.react-calendar__tile {
    & > abbr {
      padding: 10px;
      line-height: 20px;
      width: 40px;
      height: 40px;
      vertical-align: middle;
      border-radius: 20px;
      z-index: 0;
    }

    &:disabled {
      cursor: not-allowed;
    }

    &--hover,
    &--range {
      position: relative;
      border-radius: 0;
      color: $mainText100Color;
      outline: none;
      background-color: transparent;
      
      &::before {
        top: 0;
        left: 0;
        right: 0;
        border-radius: 0;
        width: auto;
        transform: none;
        background-color: $primary70_20Color;
      }
    }

    &--rangeStart {
      &:before{
        border-radius: 20px 0 0 20px;
      }
    }
    
    &--rangeEnd {
      &:before{
        border-radius: 0 20px 20px 0;
      }
    }
    
    &--rangeBothEnds {
      &:before{
        border-radius: 20px;
      }
    }
    
    &--rangeStart, &--rangeEnd, &--rangeBothEnds {
      & > abbr {
        color: $primaryContrast100Color;
        background: $itemPrimaryActiveColor;
      }
    }

    &--hoverStart {
      &:before{
        border-radius: 20px 0 0 20px;
      }
      abbr {
        border-color: $itemPrimaryActiveColor;
      }
    }
    
    &--hoverEnd {
      &:before{
        border-radius: 0 20px 20px 0;
      }
      abbr {
        border-color: $itemPrimaryActiveColor;
      }
    }
    
    &--hoverBothEnds:before {
      border-radius: 20px;
    }

    &:nth-child(7n) {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }

    &:nth-child(7n + 1) {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }
  }

  .react-calendar__tile:disabled {
    opacity: .3;
    & > abbr, &:hover, &:focus {
      background: transparent;
      border-color: transparent;
      cursor: not-allowed;
    }
    &::before {
      content: none;
    }
  }
}

// months, years, decades
.react-calendar__year-view__months, 
.react-calendar__decade-view__years, 
.react-calendar__century-view__decades {
  // margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid $mainText40Color;

  .react-calendar__tile {
    width: 100%;
    height: 40px;
    padding: 10px;
    border: 1px solid transparent;
    border-radius: 8px;
    margin-block: 3px;
    cursor: pointer;

    &--range, &--hover  {
      background: $primary70_20Color;
      border-radius: 0;
    }

    &--hasActive {
      border-radius: 8px;
    }
    &--rangeStart, &--hoverStart  {
      border-radius: 8px 0 0 8px;
    }
    &--rangeEnd, &--hoverEnd  {
      border-radius: 0 8px 8px 0;
    }
    &--rangeBothEnds, &--hoverBothEnds  {
      border-radius: 8px;
    }
    &--active, &--hasActive {
      background: $primary70_20Color;
    }
    &:hover, &:focus {
      color: $mainText100Color;
    }
  }
}

