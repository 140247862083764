@import 'src/theme/constants.scss'

.root
  backdrop-filter: blur(10px)
  background: $widgetBgColor
  box-shadow: $highlightL10Shadow
  border: 1px solid $primary80Color
  border-radius: $big-border-radius
  width: 100%
  padding: 16px
  pointer-events: auto
  margin: 16px 0px

  &[aria-disabled="true"]
    cursor: not-allowed
    pointer-events: none
    user-select: none

.container
  display: flex
  align-items: center
  transition: height .35s ease-out
  overflow: hidden

.withAnimation
  width: 100%
  animation: appearance .5s ease-in-out

.searchContainer
  display: flex
  flex-wrap: nowrap
  gap: 12px
  align-items: center

@media (max-width: $mobile-min-width)
  .root
    padding: 8px
    border-radius: 8px

@media (max-height: $mobile-min-width)
  .root
    margin: 0px

@keyframes appearance
  0%
    opacity: .35
  100%
    opacity: 1
