
@import 'src/theme/colors.scss'
@import '@root/theme/mixins.sass'
@import 'src/theme/constants.scss'

.root
  display: flex
  align-items: center
  border-radius: $medium-border-radius
  background: $itemPrimaryBgColor
  color: $mainText100Color
  border: 1px solid $dividerLineLightColor
  width: min(450px, 100%)
  overflow: hidden
  text-align: start
  padding: 12px 16px
  gap: 16px
  margin: 0

.root.active
  background: $primary70_20Color
  border-color: $primary100Color

.root:hover
  background: $itemPrimaryHoverBgColor
  border-color: $primary100Color

.root.clickable
  cursor: pointer

.body
  flex: 1 1 100%
  overflow: hidden

.oneliner
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap
  margin-block: 4px

.status
  display: flex
  align-items: center
  justify-content: center
  border-radius: 16px
  flex: 1 0 auto
  height: 32px
  width: 32px
  font-size: 32px
  padding: 3px
