.identifierList
  min-height: max(70vh, 500px)
  position: relative
  margin-bottom: 16px

.identifierListContainer
  padding-top: 1rem
  padding-bottom: 2.8125rem
  height: 100%
  min-height: 88vh

.alertText,
.notFoundBox
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)

.emptyContainer
  height: 100%
  justify-content: center
  align-items: center
  div
    text-align: center
    p
      text-align: center !important
    img
      max-width: 50%
      margin-bottom: 0.5rem
      min-width: 30vw
.catName
  display: inline

.catContainer
  margin-bottom: 0.5rem

.identifierName
  cursor: pointer

.contextMenu
  display: flex
  width: 100%
  justify-content: flex-end

.actionColumn
  justify-content: right
