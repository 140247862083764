@import 'src/theme/colors.scss'
@import '@root/theme/mixins.sass'
    
.scrollableTable
  thead
    position: sticky
    z-index: 999
    top: 0
    background: $inputMainBgColor
  width: 100%
  max-height: 400px
  overflow-y: auto
  @include thinScroll('&', 2px)

.lastElem
  width: 100%
  height: 2px
  border: 1px solid transparent


.loader
  display: flex
  width: 100%
  justify-content: center

.source,
.connectorName,
.contentType
  display: flex
  align-items: center
  gap: 8px
  img
    height: 24px
    width: 24px
  div
    padding: 0px !important
  i
    font-size: 24px

.linkedContents
  margin-top: 16px
  h2
    display: flex
    align-items: center
    gap: 8px
    margin-bottom: 16px

.footerButtonWrapper
  display: flex
  justify-content: center
  padding: 16px

.actionColumn
  justify-content: right
  text-align: right !important
