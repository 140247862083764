@import 'src/theme/constants.scss'

.root
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  min-height: 100vh
  height: auto

  h1
    font-size: 32px
    text-align: center
    margin-bottom: 20px
  h4
    color: $mainText50Color
    text-align: center
    margin-bottom: 0
  span
    color: $mainText100Color

.container
  display: grid
  grid-template-columns: 1fr
  gap: 30px
  padding: 20px
  width: 100%
  max-width: 490px

.footer
  display: flex
  flex-direction: column
  align-items: center
  h4
    color: $mainText50Color
    margin-bottom: 10px
