@import 'src/theme/colors.scss'

.root
  display: flex
  flex-direction: column
  border-radius: 6px
  background: $inputMainBgColor

  *:global(.public-DraftEditorPlaceholder-root)
    color: $mainText50Color

.textArea
  font-size: 12px
  padding: 15px
  min-height: 100px
  flex: 1 1 100%

  ol, ul
    padding-left: 2em
    margin: .5em 0

  li
    margin: .5em 0

.control
  padding: 10px 16px
  border-top: 1px solid $dividerLineDarkColor

.controlButton
  border-radius: 4px
  height: 22px
  width: 22px
  min-height: auto
  min-width: auto
  text-decoration: none
  font-size: 18px
