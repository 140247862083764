@import 'src/theme/constants.scss'

.root
  --main-background-color: #{$primaryCardBGColor}
  display: flex
  padding: 3px
  border-radius: 50%
  position: relative
  border: 3px solid var(--frame-color)
  height: fit-content
  width: fit-content

.root
  --frame-color: #{$mainText40Color}
.root.gold
  --frame-color: #ef647d
.root.silver
  --frame-color: #69bc8f
.root.bronze
  --frame-color: #75c9f0

.badge
  right: 0
  bottom: 0
  position: absolute
  transform: translate(20%, 20%)
  border: 2px solid var(--frame-color)
  border-radius: 26px
  height: 26px
  min-width: 26px
  padding-inline: 4px
  font-size: 12px
  font-weight: 900
  line-height: 22px
  text-align: center
  color: $mainText100Color
  background: var(--main-background-color)

.medal
  position: absolute
  top: -2px
  left: -6px
  width: 34px

.root:is(.gold, .silver, .bronze)
  animation: shadowAnimation 3s ease-in-out infinite
  box-shadow: 0 0 11px 1px var(--frame-color)
  & .badge
    color: var(--frame-color)
    box-shadow: 0 0 5px 1px var(--main-background-color), 0px 0px 10px 0px var(--frame-color)

@keyframes shadowAnimation
  0%
    box-shadow: 0 0 2px 1px var(--main-background-color), 0px 0px 8px 0px var(--frame-color)
  50%
    box-shadow: 0 0 2px 1px var(--main-background-color), 0px 0px 11px 1px var(--frame-color)
  100%
    box-shadow: 0 0 2px 1px var(--main-background-color), 0px 0px 8px 0px var(--frame-color)
