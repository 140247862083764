@import 'src/theme/constants.scss'

.root
  height: fit-content
  width: fit-content
  padding: 11px
  font-size: 23px
  border-radius: 22.5px

.root.size-small
  font-size: 12px
  padding: 6

.root.size-medium
  font-size: 16px
  padding: 8px

.root.size-big
  font-size: 23px
  padding: 11px
