@import "src/theme/colors.scss"

@keyframes loading
  from
    left: -50%
  to
    left: 150%

.root
  display: flex
  flex-direction: column
  max-width: 100%
  width: 100%
  gap: 5px

.line
  height: 1em
  background: $text10Color
  position: relative
  overflow: hidden
  border-radius: 2px

  &:before
    content: ''
    display: block
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    opacity: .3
    background-image: $skeletonLoaderColor
    animation: loading 1.5s linear infinite
    will-change: left
    filter: blur(5px)

.rounded .line
  border-radius: 6px
