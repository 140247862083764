@import 'src/theme/colors.scss'

.root
  --table-border-color: #{$dividerLineLightColor}
  color: $mainText100Color
  width: 100%
  padding: .215px
  border-collapse: collapse
  display: flex
  flex-direction: column

  .column
    padding: .715em
    font-weight: normal
    font-size: 1em
    line-height: 17px
    white-space: normal

  .variant-body
    display: grid
    .column
      border-block: 1px solid var(--table-border-color, transparent)

  .variant-header
    display: grid
    .column
      text-align: start
      padding-bottom: 7px !important
      border-bottom: 2px solid var(--table-border-color, transparent)
      text-transform: uppercase
      font-weight: 500
      font-size: 0.85em
      line-height: 2em
      color: $tableHeaderColor
      width: 20%

.column
  display: flex
  p
    margin-bottom: 5px

  &.align-start
    align-items: flex-start
  &.align-center
    align-content: center
  &.align-end
    align-content: flex-end

  &.justify-start
    justify-content: flex-start
  &.justify-center
    justify-content: center
  &.justify-end
    justify-content: flex-end

.row.highlightable:hover .column
  background: rgba(255, 255, 255, 0.05)
