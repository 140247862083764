@import 'src/theme/colors.scss'

.root
  --table-border-color: transparent
  color: $mainText100Color
  // border: 1px solid transparent
  // overflow: auto
  width: 100%
  border-spacing: 0

  table
    width: 100%
    padding: .215px
    border-collapse: collapse
    border-radius: 10px
    height: 1px

    td, th
      padding: .715em
      font-weight: normal
      font-size: 1em
      line-height: 17px
      white-space: normal

  tbody
    td, th
      border-block: 1px solid var(--table-border-color, transparent)

  thead
    td, th
      text-align: start
      padding-bottom: 7px !important
      border-bottom: 2px solid var(--table-border-color, transparent)
      text-transform: uppercase
      font-weight: 500
      font-size: 0.85em
      line-height: 2em
      color: $tableHeaderColor
      width: 20%

.withBorder
  --table-border-color: #{$dividerLineLightColor}

.column
  p
    margin-bottom: 5px

  &.align-start
    vertical-align: top
  &.align-center
    vertical-align: middle
  &.align-end
    vertical-align: bottom

  &.justify-start
    text-align: start
  &.justify-center
    text-align: center
  &.justify-end
    text-align: end

.container::-webkit-scrollbar
  width: 12px
  height: 12px

.container::-webkit-scrollbar-thumb
  background: $mainText100Color
  height: 4px
  border: 4px solid transparent
  border-radius: 6px
  background-clip: content-box

.row.highlightable:hover td
  background: rgba(255, 255, 255, 0.05)

.root.disabled
  opacity: .7
  pointer-events: none

.tbody_table
  tbody
    display: table

.tbody_table-row-group
  tbody
    display: table-row-group
