@import 'src/theme/colors.scss'

.root
  width: min(840px, calc(100vw - 144px))
  gap: 24px

.grid
  display: grid
  width: 100%
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr))
  padding: 4px
  gap: 16px

.filter
  display: grid
  grid-template-columns: 1fr auto
  gap: 16px
  padding-inline: 5px
  margin-bottom: 24px

.navigation
  margin-bottom: 24px
