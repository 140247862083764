@import "src/theme/constants.scss"

.root
  display: grid
  grid-template-columns: minmax(275px, 1fr) 300px minmax(275px, 1fr)
  grid-template-areas: "left-section center-section right-section"
  grid-template-rows: auto
  gap: 25px
  align-items: flex-start

.left
  display: flex
  justify-content: flex-start
  grid-area: left-section

.center
  display: flex
  justify-content: center
  grid-area: center-section

.right
  display: flex
  justify-content: flex-end
  grid-area: right-section

@media(max-width: $tablet-max-width)
  .root
    gap: 15px
    grid-template-columns: minmax(300px, 1fr) minmax(auto, 100%) minmax(300px, 1fr)
    grid-template-areas: "left-section left-section right-section" "left-section left-section center-section"
  .center
    justify-content: flex-end

@media (max-width: $tablet-max-height)
  .root
    grid-template-columns: 1fr 1fr
    grid-template-areas: "left-section right-section" "center-section center-section"

@media (max-width: $mobile-min-width)
  .root
    grid-template-columns: 1fr
    grid-template-areas: "left-section" "right-section" "center-section"
  .center
    justify-content: stretch
