.root
  display: flex
  flex-direction: column
  align-items: center
  overflow: hidden

.form
  display: flex
  gap: 12px
  width: 100%
  padding-inline: 8px

.chunk
  display: flex
  flex-wrap: wrap
  gap: 8px
  flex: 1 1 100%
  overflow: hidden

.messageText
  display: flex
  flex-wrap: wrap
  margin-top: 11px

.errorMessage
  margin-top: 4px
  margin-inline: 20px

.placeholder
  max-width: 450px

.inlineFlow
  display: inline-flex
  flex: 1 1 100%
  column-gap: 8px
  row-gap: 8px
  flex-wrap: wrap
  min-height: 80px

.input
  flex: 1 1 220px
  overflow: auto
  &>*
    max-width: 100% !important

.footer
  display: flex
  justify-content: flex-end
  width: 100%
  gap: 12px
  padding: 4px
