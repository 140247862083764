@import 'src/theme/constants.scss'

.root
  display: flex
  flex-direction: row
  width: 100%
  align-items: center
  justify-content: space-between
  gap: 15px

.baseContainer
  display: flex
  justify-content: space-between
  gap: 15px

  &.align-start
    align-items: flex-start
  &.align-center
    align-items: center
  &.align-end
    align-items: flex-end

.addedEarlier
  color: $primary110Color

.avatar
  height: 40px
  width: 40px

.body
  display: flex
  flex-direction: column
  align-items: flex-start
  flex: 1 1 auto

.label
  display: inline-flex
  align-items: center
  gap: 8px
  width: 100%
  &>*:first-child()
    flex: 1 0 auto

.other
  display: inline-flex
  align-items: center
  gap: 8px
  width: 100%
  margin-top: 4px

.root.variant-card
  background: $text10Color
  border-radius: $small-border-radius
  padding: 20px

.clickable
  cursor: pointer

.clickable:hover
  background: $text15Color
