@import 'src/theme/constants.scss'
@import '@root/theme/mixins.sass'

.root
  display: grid
  grid-template-rows: auto
  grid-template-columns: auto 300px
  grid-template-areas: 'header sidebar' 'view sidebar' 'footer footer'
  column-gap: 20px
  width: 100%
  height: fit-content

.header
  grid-area: header
  overflow: auto

.citationHeader
  display: flex
  justify-content: center
  align-items: center
  margin-bottom: 16px
  gap: 8px

.view
  grid-area: view
  overflow: auto
  aspect-ratio: 0.75/1

.sidebar
  grid-area: sidebar
  height: 100%
  min-height: 160px
  overflow: hidden

.activeImage
  border: 2px solid

.textContent
  overflow: auto
  transition: width 0.3s ease-in-out
  width: 100%

.textViewToggleButton
  margin: 20px 0px

.extractedTextHeading
  margin: 10px 0px !important

.footer
  grid-area: footer
  overflow: auto

.root.fullscreen
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  height: 100vh
  grid-template-areas: 'view sidebar'
  padding: 20px 20px 20px 100px
  background: $primaryMainBGColor
  z-index: 99
  .view
    aspect-ratio: auto

@media (max-width: $tablet-max-height)
  .root
    grid-template-columns: 1fr
    grid-template-rows: auto
    grid-template-areas: 'sidebar' 'header' 'view' 'footer'

  .header
    margin-top: 24px

  .view
    aspect-ratio: auto
    min-height: fit-content

  .root.fullscreen
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    height: 100vh
    grid-template-rows: 1fr auto
    grid-template-areas: 'view' 'sidebar'
    padding-block: 72px 20px
    padding-inline: 20px
    background: $primaryMainBGColor
    z-index: 99
    .sidebar
      display: none
