.root
    margin: 0 auto 25px
    display: flex
    flex-direction: column
    align-items: center
    gap: 16px

    .question
        width: 350px
        text-align: center
