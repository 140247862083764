@import 'src/theme/constants.scss'

.root
  position: relative
  width: 100%
  height: 100vh
  background: $primaryMainBGColor
  padding: 30px 60px
  // max-width: $container-max-width
  // margin-inline: auto

.backButton
  position: absolute
  left: 60px
  top: 30px
  text-decoration: none

@media(max-width: $tablet-min-width)
  .root
    padding: 70px 20px 30px

  .backButton
    left: 20px

@media(max-width: $tablet-min-height)
  .root
    padding: 70px 0 0
