@import 'src/theme/colors.scss'
$animationDuration: .3s

@keyframes activate
  0%
    transform: scale(.7)
  70%
    transform: scale(1.1)
  100%
    transform: scale(1)

.root,
.root:visited
  display: grid
  grid-template-columns: auto 1fr
  align-items: center
  cursor: pointer
  text-decoration: none
  color: $navItemColor

.icon
  display: flex
  justify-content: center
  align-items: center
  position: relative
  width: 50px
  min-width: 50px
  height: 50px
  min-height: 50px
  font-size: 24px
  text-decoration: none
  border-radius: 50%

.icon:before
  content: ''
  position: absolute
  inset: 0
  border-radius: inherit
  background: $navItemActiveBgColor
  transform: scale(0)

.root:hover,
.active,
.active .label,
.active .icon
  color: $navItemActiveColor

.active .icon:before
  animation: activate .5s ease-out
  transform: scale(1)

.label
  color: inherit
  text-transform: capitalize
  flex: 1 1 auto

.badge
  position: absolute
  height: auto
  bottom: -1px
  right: -1px

.expandMenuItem
  display: flex
  justify-content: space-between
  align-items: center
  width: 100%
  font-size: 16px
  padding: 9px 0px 9px 16px
  gap: 0px
  min-width: 200px
  border-radius: 100px
  height: 50px
  cursor: pointer
  color: $text60Color
  i
    font-size: 24px
    color: $text60Color
  &.active
    background-color: $navItemActiveBgColor
    color: $navItemActiveColor
    i
      color: $text100Color
  span
    display: flex
    align-items: center
    gap: 8px

.expandMenuItem.withAnimation
  animation: enterAnimation $animationDuration ease-out

.expandIcon
  cursor: pointer

.subOptionWrapper
  display: flex
  flex-direction: column
  font-size: 14px
  color: $text70Color
  div
    display: flex
    cursor: pointer

.subOptionHeader:hover
.subOption:hover
  color: $text100Color
  text-decoration: underline

.subOption
  margin-bottom: 24px
  animation: enterAnimation $animationDuration ease-out

.subOption:last-child
  margin-bottom: 10px

.paddingLeft
  padding-left: 24px

.paddingLeftSmall
  padding-left: 16px

.paddingTop
  padding-top: 8px

.optionIcon
  width: 24px
  color: $text60Color
  max-width: 24px

.optionsGap
  gap: 20px

.childActive
  color: $text100Color

@keyframes enterAnimation
  0%
    opacity: 0
    transform: translateX(-30%)

  70%
    opacity: 1

  100%
    opacity: 1
    transform: translateX(0)
