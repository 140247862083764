@import 'src/theme/constants.scss'

.root
  display: flex
  flex-direction: column
  align-items: stretch
  width: 100%

.mainHeader
  height: 70px
  padding-inline: 30px
  border-bottom: 1px solid $dividerLineLightColor

.header
  margin-top: 40px
  margin-bottom: 24px

.body
  display: flex
  flex-direction: column
  flex: 1
  width: 100%
  padding-inline: 60px
  padding-bottom: 90px

.tableContainer
  display: flex
  flex: 1 1
  overflow: hidden

@media (max-width: $tablet-min-width)
  .mainLayout
    padding: 0

  .body
    padding-inline: 20px
    padding-bottom: 20px

  .mainHeader
    height: 50px
    padding-inline: 16px

  .header
    margin-top: 20px
    margin-bottom: 12px
