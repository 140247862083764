@import 'src/theme/colors.scss'

.root
  padding: 32px
  width: min(600px, 100%)
  max-width: 100%

.container
  display: flex
  flex-direction: column
  align-items: stretch

.body
  display: flex
  flex-direction: column
  width: 100%
  gap: 24px
