@import 'src/theme/constants.scss'

.content
  padding-block: 24px
  background-color: $primaryCardBGColor
  border-radius: $small-border-radius

.header
  display: grid
  grid-template-columns: auto 270px
  padding-inline: 24px
  padding-bottom: 24px
  gap: 16px

.chartTitles
  padding-inline: 24px

.body
  overflow: hidden
  height: 400px
  width: 100%
  padding-inline: 24px

.legend

.legend::before
  content: '• '
  font-size: 2.2em
  vertical-align: sub
  color: var(--marker-color)
