@import 'src/theme/colors.scss'

.root
  display: flex
  padding: 8px
  border-radius: 6px
  gap: 8px
  align-items: center
  background-color: $primaryContrast10OpacityColor
  color: $mainText100Color
  cursor: pointer
  border: 1px solid transparent

.body
  flex: 1

.root:hover
  border-color: $primary80Color
  background: $primary80_10Color
  i
    font-size: 16px

.root.disabled
  opacity: 0.7
  cursor: default
  border-color: transparent
  background: $primaryContrast10OpacityColor
