@import 'src/theme/colors.scss'

.root
    padding: 40px
    padding-top: 30px
    max-width: 570px
    width: 570px
    height: min(770px, calc(100vh - 150px))

.container
    display: flex
    justify-content: center
    align-items: stretch
    text-align: center
    flex-direction: column
    gap: 30px
    flex: 1 1 auto
    overflow-y: auto
