@import 'src/theme/constants.scss'

.root
  display: flex
  flex-direction: column
  justify-content: center
  min-height: 100vh
  height: auto

.container
  display: grid
  justify-content: stretch
  align-items: center
  gap: 30px
  padding: 20px
  width: 100%
  max-width: 490px
  margin: auto

.header
  h1
    font-size: 32px
    text-align: center
    margin-bottom: 20px
  h4
    color: $mainText50Color
    text-align: center
    margin-bottom: 0
