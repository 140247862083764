@import 'src/theme/colors.scss'

.root
  height: 100%

.collection
  padding-right: 5px
  overflow-y: scroll
  flex: 1 1 100%

.block
  width: 100%
  min-width: auto
  margin-inline: 24px 12px
