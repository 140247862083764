@import 'src/theme/constants.scss'

.root
  display: grid
  grid-template-rows: auto auto 1fr
  width: 100%
  max-width: 1700px
  align-items: flex-start
  margin-inline: auto

.header
  margin-bottom: 45px

.grid
  display: grid
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr))
  gap: 10px

@media (max-width: $tablet-max-height)
  .header
    margin-bottom: 15px

@media (max-width: $mobile-min-width)
  .inviteButton
    width: 100%
