@import 'src/theme/constants.scss'
.root
  display: flex
  flex-direction: column
  align-items: stretch
  margin-block: 8px
  gap: 24px

.preHeader
  display: flex
  justify-content: flex-end

.header
  display: flex
  align-items: flex-start
  gap: 16px

  .question
    display: flex
    flex: 1 1 100%
    word-break: break-word
    flex-direction: column
    justify-content: center
    padding-block: 4px

.body
  display: flex
  position: relative
  flex-direction: column
  align-items: stretch
  overflow: auto
  flex: 1 1 auto
