@import "src/theme/colors.scss";

.root {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  z-index: 1000;
}

.container {
  min-width: 250px;
}