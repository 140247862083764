@import 'src/theme/constants.scss'

.root
  display: flex
  align-items: stretch
  min-height: 100vh
  max-width: 100vw
  background: $primaryMainBGColor

.sidebar
  min-width: 70px
  background: $primaryToolbarBgColor
  border-right: 1px solid $dividerLineDarkColor
  overflow: auto

.content
  flex: 1 1 auto
  display: flex
  overflow: overlay
  z-index: 0

@media (max-width: $tablet-min-width)
  .root
    padding: 0

  .content
    padding: 20px

@media (max-width: $tablet-max-height)
  .root
    flex-direction: column

  .content
    padding: 76px 16px 16px

.root.noPadding
  padding: 0
  .content
    padding: 0

@media (max-width: $tablet-max-height)
  .root.noPadding
    .content
      padding: 60px 0 0 0
