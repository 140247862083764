@import 'src/theme/colors.scss'

.root
  padding-inline: 24px
  width: min(640px, calc(100vw - 144px))

.filter
  display: grid
  grid-template-columns: 1fr auto
  gap: 16px

.block
  width: 100%
  margin-inline: 24px
